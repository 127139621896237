import React, { useState, useRef, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Store } from "../redux/Actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux";
import { useSelector } from "react-redux";
import {
  createTextPost,
  getSearchedUserList,
} from "../redux/action/postActions";
import search from "../assets/images/icons/ico_search.svg";
import { getBlockList } from "../redux/action";
import { getToken } from "../utils/utility";

// import { searchFilter } from './Filter';
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Avatar from "react-avatar";
import { S3_BASE_URL } from "../service/aws/config";
import { ReactComponent as VerifyIcon } from "../assets/images/icons/verifyIcon.svg";

export const searchFilter = (
  searchValue: any,
  list: any,
  searchBy = "name"
) => {
  let lowerCaseQuery = searchValue.toLowerCase();
  let filteredList = searchValue
    ? list.filter((x: any) =>
        x[searchBy].toLowerCase().includes(lowerCaseQuery)
      )
    : list;
  return filteredList;
};
// const list = [
//   { id: 1, name: 'Tom', type: 'Cat' },
//   { id: 2, name: 'Zeus', type: 'Labrador' },
//   { id: 3, name: 'Jax', type: 'Malamute' },
//   { id: 4, name: 'Seb', type: 'Developer' },
//   { id: 5, name: 'MacBook', type: 'Notebook' },
// ];

const DropdownItems = ({ handleBlockUnblock,searchValue,setSearchValue }: any) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  // const [searchValue, setSearchValue] = useState("");
  // const [selectedItem, setSelectedItem] = useState(null);
  const dropdownRef: any = useRef(null);
  const [tagList, setTagList] = useState<any>([]);
  const { loading, createTextPostSuccess, searchUserList }: any = useSelector(
    (state: Store) => state.postDataReducer
  );
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const userId = getToken("userId");
  // click away listener
  useEffect(() => {
    document.addEventListener("mousedown", handleClick, false);
    return () => document.removeEventListener("mousedown", handleClick, false);
  }, []);
  useEffect(() => {
    dispatch(getSearchedUserList(searchValue));
  }, [searchValue]);
  const handleClick = (e: any) => {
    if (
      dropdownRef !== null &&
      dropdownRef?.current &&
      dropdownRef?.current.contains(e.target)
    ) {
      return;
    }
    setVisible(false);
  };
  useEffect(() => {
    let data = [];
    data =
      searchUserList &&
      searchUserList.length > 0 &&
      searchUserList.map((data: any) => {
        return {
          value: data.id,
          label: data.created_by,
          username: data.username,
          profile_photo: data.profile_photo,
        };
      });
    if (data && data.length > 0) {
      setTagList(data);
    }
  }, [searchUserList]);
  const handleChange = (e: any) => {
    setVisible(true);
    setSearchValue(e.target.value);
    if (!visible) {
      setVisible(true);
    }
  };

  // const selectItem = (item: any) => {
  //   setSearchValue(item.name);
  //   setSelectedItem(item);
  //   setVisible(false);
  // };

  // const selectChange = (e: any) => {
  // };
  // const GetBlockList = () => {
  //   const userId = getToken("userId");
  //   const data: any = { userId, page: 1 };
  //   dispatch(getBlockList(data));
  // };
  //   const handleBlockUnblock = async(type:any,id: any) => {
  //     if(type=== 'block'){
  //         await dispatch(blockUser(id))
  //     }
  // else{
  //     await dispatch(unblockUser(id));
  // }
  //     GetBlockList();

  // };
  return (
    <div className="search-box">
      <img src={search} alt="search" className="img-fluid" width="18px" />
      <div className="input-box">
        <input
          // className="input"
          type="text"
          placeholder={t("blacklist.search user") || "Search User"}
          value={searchValue}
          onChange={handleChange}
          // onFocus={() => {
          //   setVisible(true);
          // }}
          className="form-control border-start-0"
        />
      </div>
      {visible && (
        <div
          ref={dropdownRef}
          className={`search-list-content ${visible ? "v" : ""}`}
        >
          <ul>
            {/* {!searchUserList && (
              <li key="zxc" className="dropdown_item">
                no result
              </li>
            )} */}
            {/* you can remove the searchFilter if you get results from Filtered API like Google search */}
            {searchUserList &&
              searchUserList
                .filter((elem: any) => elem.id != userId)
                .map((x: any) => {
                  return (
                    // <li className="blacklist-item px-0" key={x.id}>
                    <div className="blacklist-link d-flex align-items-center justify-content-between px-0 m-3">
                      <div className="blacklist-user d-flex align-items-center">
                        <Avatar
                          className="avtar blacklist-setting-image"
                          round={true}
                          src={S3_BASE_URL + x?.profile_photo}
                          alt="profile"
                          size="60"
                          name={x.first_name + " " + x.last_name}
                          maxInitials={2}
                          // style={{ position: "relative" }}
                        />
                        <div className="col">
                          <h5 className="post-user-name justify-content-start">
                            {x?.first_name + " " + x?.last_name}
                            {x?.user_verified && (
                              <VerifyIcon className="mx-1" width="16" height="16"/>
                            )}
                          </h5>
                          <p className="text-msg user-comment">
                            @{x?.username}
                          </p>
                        </div>
                      </div>
                      <div className="blacklist-button mb-4">
                        <Button
                          className="btn btn-unblock"
                          onClick={() =>
                            handleBlockUnblock(
                              x.is_block ? "unblock" : "block",
                              x.id
                            )
                          }
                        >
                          {x.is_block ? "Unblock" : "Block"}
                        </Button>
                      </div>
                    </div>
                    // </li>
                  );
                })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownItems;
