import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Store } from '../../redux/Actions';
import {
  searchUserDetail,
  subscribe,
  userDashboardFeed,
  userFeed,
} from '../../redux/action';
import { AppDispatch } from '../../redux';
import { useDispatch } from 'react-redux';
import { getToken } from '../../utils/utility';
import Loader from '../Loader';

const UserSubscriptionModal = (props: any) => {
  const { openSubscriptionModal, setOpenSubscriptionModal, page, userId } =
    props;
  const dispatch = useDispatch<AppDispatch>();
  const [subUserId, setSubUserId] = useState<any>(null);
  const [userPackageId, setUserPackageId] = useState<any>(null);
  const [confirmSubscription, setConfirmSubscription] = useState(false);

  const { user_packages, userFeedList, loading }: any = useSelector(
    (state: Store) => state.userDataReducer
  );

  const currentUser = getToken('userId');
  useEffect(() => {
    dispatch(userFeed(1, userId));
  }, []);

  const handleCloseSubscribe = () => {
    setConfirmSubscription(false);
  };

  const handleSubscribeUser = async () => {
    await dispatch(subscribe(subUserId, userPackageId));
    // await dispatch(userDashboardFeed(1));
    await dispatch(searchUserDetail(Number(userId)));
    setConfirmSubscription(false);
    setOpenSubscriptionModal(false);
  };

  return (
    <Modal
      show={openSubscriptionModal}
      onHide={() => setOpenSubscriptionModal(false)}
      className="subscription-modal-main-div"
      centered
    >
      {loading && <Loader />}
      {/* <Modal.Body className="p-0"> */}
      <div className="other-profile-container" style={{ minWidth: '500px' }}>
        {user_packages ? (
          <div className="box-container subscription my-profile-user-details-background">
            <h4 className="subscription-title">Subscription</h4>
            <div className="subscription-pricelist">
              {user_packages.Monthly && (
                <div
                  className="price-content btn"
                  onClick={(event) => {
                    event.stopPropagation();
                    // dispatch(subscribe(userId, user_packages.Monthly.id));
                    setSubUserId(userId);
                    setUserPackageId(user_packages?.Monthly?.id);
                    setConfirmSubscription(true);
                  }}
                >
                  <div className="price-label">
                    {user_packages.Monthly.title}
                  </div>
                  <div className="price-number">{`${user_packages.Monthly.price}/month`}</div>
                </div>
              )}
              {user_packages.Annual && (
                <div
                  className="price-content btn"
                  onClick={(event) => {
                    event.stopPropagation();
                    setSubUserId(userId);
                    setUserPackageId(user_packages?.Annual?.id);
                    setConfirmSubscription(true);
                    // dispatch(subscribe(userId, user_packages.Annual.id));
                  }}
                >
                  <div className="price-label">
                    {user_packages.Annual.title}
                  </div>
                  <div className="price-number">{`${user_packages.Annual.price}/year`}</div>
                </div>
              )}
            </div>
            {user_packages.Free &&
              user_packages?.Free?.package_validity !== 0 && (
                <div
                  className="subscrib-button"
                  onClick={(event) => {
                    event.stopPropagation();
                    setSubUserId(userId);
                    setUserPackageId(user_packages?.Free?.id);
                    setConfirmSubscription(true);
                    // dispatch(subscribe(userId, user_packages.Free.id));
                  }}
                >
                  <button className="btn btn-subscribe">{`Subscribe for Free ${
                    user_packages?.Free?.package_validity
                  } Day then ${
                    +user_packages?.Monthly?.price
                      ? user_packages?.Monthly?.price + ' ' + 'per month'
                      : user_packages?.Annual?.price + ' ' + 'per year'
                  }`}</button>
                </div>
              )}
          </div>
        ) : (
          !loading && (
            <p className="no-subscription-message m-5">
              No Subscription Available !
            </p>
          )
        )}
      </div>

      {/* </Modal.Body> */}
      <Modal
        show={confirmSubscription}
        onHide={() => setConfirmSubscription(false)}
        // className="view-follower-modal"
        centered
      >
        <div className="m-3">
          {/* <div className="box-container notification-container"> */}
          <p className="m-3">Are You Sure Subscribe The User?</p>
          <div className="subscribe-confirm-modal mt-2">
            <Button
              className="btn btn-primary"
              type="submit"
              onClick={handleSubscribeUser}
            >
              Subscribe
            </Button>
            <Button
              className="btn btn-secondary"
              onClick={handleCloseSubscribe}
              type="button"
            >
              Cancel
            </Button>
            {/* </div> */}
          </div>
        </div>
      </Modal>
    </Modal>
  );
};

export default UserSubscriptionModal;
