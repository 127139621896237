import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Profile from '../assets/images/imgs/user-round.svg';
import { Store, UserData } from '../redux/Actions';
import { S3_BASE_URL } from '../service/aws/config';
import { getToken } from '../utils/utility';
import { useTranslation } from 'react-i18next';
import { createImageFromInitials, getRandomColor } from '../utils/createImage';
import Followers from './models/Follower';
import Following from './models/Following';
import { ReactComponent as VerifyIcon } from '../assets/images/icons/verifyIcon.svg';
import Loader from './Loader';
import BackImage from '../assets/images/bg/bg-img2.svg';

export default function UserCard() {
  const { t } = useTranslation();
  const [isOpenFollowerModal, setIsOpenFollowerModal] =
    useState<boolean>(false);
  const [isOpenFollowingModal, setIsOpenFollowingModal] =
    useState<boolean>(false);
  const userDetail: any = useSelector(
    (state: Store) => state.userDataReducer.userDetail
  );
  const loading: any = useSelector(
    (state: Store) => state.userDataReducer.loading
  );

  const userId = getToken('userId');
  return (
    <div className="box-container pt-0 px-0">
      {/* {loading && !isOpenFollowingModal && !isOpenFollowerModal && <Loader />} */}
      <div
        className="box-bg-img"
        style={{
          backgroundImage: `url(${userDetail?.cover_photo ? S3_BASE_URL + userDetail?.cover_photo : BackImage
            })`,
        }}
      ></div>
      <div className="user-detail-box">
        <div className="user-img">
          {(userDetail?.profile_photo || userDetail?.first_name) && (
            <img
              alt="user"
              src={
                userDetail?.profile_photo
                  ? S3_BASE_URL + userDetail?.profile_photo
                  : createImageFromInitials(
                    500,
                    userDetail?.first_name + " " + userDetail?.last_name,
                    getRandomColor()
                  )
              }
              // src={
              //   userDetail?.profile_photo
              //     ? S3_BASE_URL + userDetail?.profile_photo
              //     : Profile
              // }
              className="img-fluid"
              style={{ width: '100%', objectFit: 'cover', height: '100%' }}
            />
          )}
        </div>
        <h4 className="user-name text-center">
          {userDetail?.first_name} {userDetail?.last_name}
          {userDetail?.user_verified && (
            <VerifyIcon className="mx-1" height={'13'} width={'13'} />
          )}
          <small>@{userDetail?.username}</small>
        </h4>
        <div className="user-follow-details">
          <div className="follow-number text-center">
            <h5>{userDetail?.posts_count}</h5>
            <span>{t('sidebar.post')}</span>
          </div>
          <div
            className="follow-number text-center"
            onClick={() => setIsOpenFollowerModal(true)}
          >
            <h5>{userDetail?.followers_count}</h5>
            <span>{t('sidebar.followers')}</span>
          </div>
          <div
            className="follow-number text-center"
            onClick={() => setIsOpenFollowingModal(true)}
          >
            <h5>{userDetail?.following_count}</h5>
            <span>{t('sidebar.following')}</span>
          </div>
        </div>
        <p className="user-description text-center">{userDetail?.bio}</p>
        <Link className="btn btn-profile" to="/my-profile">
          {t('sidebar.my profile')}
        </Link>
      </div>
      {isOpenFollowerModal && (
        <Followers
          isOpenFollowerModal={isOpenFollowerModal}
          setIsOpenFollowerModal={setIsOpenFollowerModal}
          userId={userId}
        />
      )}
      {isOpenFollowingModal && (
        <Following
          isOpenFollowingModal={isOpenFollowingModal}
          setIsOpenFollowingModal={setIsOpenFollowingModal}
          userId={userId}
        />
      )}
    </div>
  );
}
