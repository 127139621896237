import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { apiCall, METHOD } from '../../service/baseApiCall';
import { Store } from '../Actions';
import { PLAN_LIST_URL ,EDIT_PLAN_URL} from '../ApiEndPoints';
import { PLAN,EDIT_PLAN } from '../constants/action-types';
import {toast} from 'react-toastify'

export const userPackageList = (user_id:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
  dispatch(userPackageListInit(user_id));
};

export const userPackageListInit = (user_id:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
  dispatch({
    type: PLAN.PACKAGE_LIST_INITLIZATION
  });
  
  apiCall(
    PLAN_LIST_URL,
    {user_id},
    (res: any) => {
      
      dispatch(userPackageListSuccess(res))},
    (err:any) =>{
      dispatch(userPackageListError(err))},
    METHOD.POST,
    {}
  );
  
};

export const userPackageListSuccess = (res:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
  dispatch({
    type:PLAN.PACKAGE_LIST_SUCCESS,
    payload:res.data
  });
   
  };

export const userPackageListError = (err:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
    dispatch({
      type:PLAN.PACKAGE_LIST_ERORR
    });
  };

  export const editPlan = (values:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
    dispatch(editPlanInit(values));
  };
  
  export const editPlanInit = (values:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
    dispatch({
      type: EDIT_PLAN.EDIT_PLAN_INITLIZATION
    });
    apiCall(
      EDIT_PLAN_URL,
      values,
      (res: any) => {
        
        dispatch(editPlanSuccess(res))},
      (err:any) =>{
        
        dispatch(editPlanError(err))},
      METHOD.POST,
      {}
    );
  };
  
  export const editPlanSuccess = (res:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
    
    dispatch({
      type:EDIT_PLAN.EDIT_PLAN_SUCCESS,
      payload:res.data
    });
    // 
    toast.success(res.message)
  };
  
  export const editPlanError = (err:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
    dispatch({
      type:EDIT_PLAN.EDIT_PLAN_INITLIZATION
    });
     toast.error(err.message)
  };