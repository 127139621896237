import React, { useEffect, useState } from "react";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import Suggestions from "../components/Suggestions";
import TrendingFeeds from "../components/TrendingFeeds";
import UserCard from "../components/UserCard";
import { ToastContainer } from 'react-toastify';
import Home from "../pages/Home";
import { FormattedMessage, IntlProvider } from "react-intl";
import messages_es from "../translations/spanish.json";
import messages_en from "../translations/english.json";
import { routes, privateRoutes } from "./routeConstants";
import { useSelector } from "react-redux";
import { Store, UserData } from "../redux/Actions";
import { getToken } from "../utils/utility";
import socket from "../utils/socket";


export default function RoutesElement() {
  const isLoggedIn=getToken("authToken")?true : false
  const isLoggedOut: boolean = useSelector(
    (state: Store) => state.userDataReducer.isLoggedOut
  );
  const userDataReducer: any = useSelector(
    (state: Store) => state.userDataReducer
  );
  const messages = {
    en: messages_en,
    es: messages_es,
  };
  const [message, setMessage] = useState<any>(messages["en"]);
  const language: string = useSelector(
    (state: Store) => state.userDataReducer.local
  );
  useEffect(() => {
    if (language === "en") {
      setMessage(messages["en"]);
    } else {
      setMessage(messages["es"]);
    }
  }, [language]);
  return (
    <IntlProvider messages={message} locale={language} >
    <BrowserRouter >
     <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          closeButton={true}
          pauseOnHover={true}
          closeOnClick
        />
      <Routes>
        
        {/* <Route path="/" element={<Home></Home>}></Route> */}
        {routes.map(({ path, element: Element }, index) => (
          <Route
            key={index}
            path={path}
            element={
              <>
                {/* {sideBar && <Sidebar />}
                  {navBar && <Header />} */}
                <Element />
              </>
            }
          />
        ))}
        {/* <Route element={() => <Redirect to="/login" />} /> */}
        {/* {isLogoutSuccess && <Navigate to="/login" />} */}

        {isLoggedOut ? (
          <Route path="/*" element={<Navigate to="/login" />} />
        ) : (
          <>
            {privateRoutes.map(
              (
                { path, element: Element, sideBar, header, container,userCard },
                index
              ) => (
                <Route
                  key={index}
                  path={path}
                  element={
                    <>
                      {header && <Header />}

                      <main>
                        <div
                          className={container ? "container" : "container-md"}
                        >
                          <div
                            className={
                              container
                                ? "main-box"
                                : "main-box two-columns-grid"
                            }
                          >
                            <div
                              className={
                                container
                                ? 
                                "left-box d-none d-md-block"
                                : "left-box"
                              }
                              >
                              {userCard && <UserCard/>}
                              <SideBar />
                            </div>

                            <Element />
                            {container && (
                              <div className="right-box">
                                {/* <!-- suggestions --> */}
                              {((path === '/') || (path === '/home')) && (
                                 <>
                                  <Suggestions />
                                  <TrendingFeeds/>
                                 </>
                              )}
                              {(path === '/explore-list') && (
                                 <TrendingFeeds/>
                              )}
                              </div>
                            )}
                          </div>
                        </div>
                      </main>
                    </>
                  }
                />
              )
            )}
            <Route path="/*" element={<Navigate to="/login" />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
    </IntlProvider>
    // <Router>
    //   {/* A <Switch> looks through its children <Route>s and
    //         renders the first one that matches the current URL. */}
    //   <Switch>
    //     {routes.map((item, index) => (
    //       <Route key={index} {...item} />
    //     ))}
    //     <Route render={() => <Redirect to="/home" />} />
    //   </Switch>
    // </Router>
  );
}
