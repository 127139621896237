import React, { useEffect, useRef, useState } from 'react';
import PostFeed from './PostFeed';
import Suggestions from './Suggestions';
import TrendingFeeds from './TrendingFeeds';
import UserImg from '../assets/images/imgs/user-round.svg';
import BackImage from '../assets/images/bg/bg-img2.svg';
import FacebookeIcon from '../assets/images/icons/ico_facebook.svg';
import InstagramIcon from '../assets/images/icons/ico_instagram.svg';
import TwitterIcon from '../assets/images/icons/ico_twitter.svg';
import LinkdinIcon from '../assets/images/icons/ico_linkedin.svg';
import LinkIcon from '../assets/images/icons/ico_link.svg';
import WebIcon from '../assets/images/icons/internet-svgrepo-com.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../redux/Actions';
import { AppDispatch } from '../redux';
import {
  followUser,
  searchUserDetail,
  subscribe,
  unfollowUser,
  userDetail,
  userFeed,
} from '../redux/action';
import { useParams } from 'react-router-dom';
import { getToken } from '../utils/utility';
import { S3_BASE_URL } from '../service/aws/config';
import { useNavigate } from 'react-router-dom';
import { createImageFromInitials, getRandomColor } from '../utils/createImage';
import { ReactComponent as VerifyIcon } from '../assets/images/icons/verifyIcon.svg';
import Loader from './Loader';
import Followers from './models/Follower';
import Following from './models/Following';
import { Button, Modal } from 'react-bootstrap';
import { USER_FEEDS } from '../redux/constants/action-types';
import { useTranslation } from 'react-i18next';

export default function SearchProfile() {
  const dispatch = useDispatch<AppDispatch>();
  let { id }: any = useParams();
  const navigate: any = useNavigate();
  const userId: any = getToken('userId');
  const { t } = useTranslation();
  const { user_packages, userFeedList, loading, hasMoreFeed }: any =
    useSelector((state: Store) => state.userDataReducer);

  const searchUserDetails: any = useSelector(
    (state: Store) => state?.userDataReducer?.searchUserDetail
  );
  console.log(
    Number(id) !== Number(userId),
    user_packages,
    !searchUserDetails?.is_subscribe,
    'user_packagesuser_packages'
  );
  // const userFeedList: any = useSelector(
  //   (state: Store) => state.userDataReducer.userFeedList
  // );
  // const loading: any = useSelector(
  //   (state: Store) => state.userDataReducer.loading
  // );
  const [page, setPage] = useState(1);
  const [feedList, setFeedList] = useState<any>([]);
  const [initialLoader, setInitialLoader] = useState(false);
  const [confirmSubscription, setConfirmSubscription] = useState(false);
  const [subUserId, setSubUserId] = useState<any>(null);
  const [userPackageId, setUserPackageId] = useState<any>(null);
  const [isOpenFollowerModal, setIsOpenFollowerModal] =
    useState<boolean>(false);
  const [isOpenFollowingModal, setIsOpenFollowingModal] =
    useState<boolean>(false);

  const topRef = useRef(null);

  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      // behavior: "smooth",
    });
  };

  useEffect(() => {
    if (page == 1) {
      dispatch({ type: USER_FEEDS.USER_FEEDS_INIT_CLEAR });
    }
    if (id) {
      dispatch(userFeed(page, id));
      setInitialLoader(true);
    } else {
      dispatch(userFeed(page, userId));
    }
  }, [page, id]);

  useEffect(() => {
    if (initialLoader) setInitialLoader(false);
  }, [userFeedList]);

  useEffect(() => {
    // if(feedList.length<=0){
    // setFeedList(userFeedListData)
    setFeedList((previous: any, newValue: any) => {
      if (previous !== newValue) {
        return [...feedList, ...userFeedList];
      }
    });

    // }
  }, [userFeedList]);
  useEffect(() => {
    dispatch(searchUserDetail(Number(id)));
    // dispatch(userDetail(userId))
  }, [page, id]);
  const handeleFollowUser = async () => {
    await dispatch(followUser(id));
    await dispatch(searchUserDetail(Number(id)));
    // dispatch(userFeed(1, id));
  };
  const handeleUnFollowUser = async () => {
    await dispatch(unfollowUser(id));
    await dispatch(searchUserDetail(Number(id)));
  };

  const handleCloseSubscribe = () => {
    setConfirmSubscription(false);
  };

  const handleSubscribeUser = async () => {
    await dispatch(subscribe(subUserId, userPackageId));
    await dispatch(searchUserDetail(Number(id)));
    if (page == 1) {
      dispatch({ type: USER_FEEDS.USER_FEEDS_INIT_CLEAR });
    }
    if (id) {
      dispatch(userFeed(page, id));
      setInitialLoader(true);
    } else {
      dispatch(userFeed(page, userId));
    }
    setConfirmSubscription(false);
  };
  return searchUserDetails && Object.keys(searchUserDetails)?.length > 0 ? (
    <>
      <div className="other-profile-container" ref={topRef}>
        {loading && <Loader />}
        <div className="profile-content box-container my-profile-user-details-background">
          <div
            className="profile-bg"
            style={{
              backgroundImage: `url(${searchUserDetails?.cover_photo
                  ? S3_BASE_URL + searchUserDetails?.cover_photo
                  : BackImage
                })`,
            }}
          ></div>
          <div className="profile-box text-center">
            <div className="profile-logo">
              <img
                //  src={searchUserDetails?.profile_photo?S3_BASE_URL+searchUserDetails?.profile_photo:UserImg}
                src={
                  searchUserDetails?.profile_photo
                    ? S3_BASE_URL + searchUserDetails?.profile_photo
                    : createImageFromInitials(
                      500,
                      searchUserDetails?.first_name +
                      ' ' +
                      searchUserDetails?.last_name,
                      getRandomColor()
                    )
                }
                className="img-fluid w-100"
                style={{ height: '126px', borderRadius: '50%' }}
                alt="user"
              />
            </div>
            <h3 className="profile-title">
              {searchUserDetails?.first_name} {searchUserDetails?.last_name}
              {searchUserDetails?.user_verified && (
                <VerifyIcon className="mx-1" height={'25'} width={'25'} />
              )}
              <span>@{searchUserDetails?.username}</span>
            </h3>
            <p className="profile-description">{searchUserDetails?.bio}</p>
            {/* <a
            href={searchUserDetails?.other_profile_link}
            className="profile-link"
          >
            {searchUserDetails?.other_profile_link}
          </a> */}
            {searchUserDetails?.website_link && (
              <a
                className="website-link-user-profile"
                target="_blank"
                href={
                  searchUserDetails?.website_link?.includes('http')
                    ? searchUserDetails?.website_link
                    : `https://${searchUserDetails?.website_link}`
                }
              >
                {searchUserDetails?.website_link}
              </a>
            )}
            <div className="social-link">
              <ul>
                {searchUserDetails?.facebook_link && (
                  <li className="social-item">
                    <a
                      target="_blank"
                      href={
                        searchUserDetails?.facebook_link?.includes('http')
                          ? searchUserDetails?.facebook_link
                          : `https://${searchUserDetails?.facebook_link}`
                      }
                    >
                      <img
                        src={FacebookeIcon}
                        className="img-fluid"
                        alt="icon"
                      />
                    </a>
                  </li>
                )}
                {searchUserDetails?.instagram_link && (
                  <li className="social-item">
                    <a
                      target="_blank"
                      href={
                        searchUserDetails?.instagram_link?.includes('http')
                          ? searchUserDetails?.instagram_link
                          : `https://${searchUserDetails?.instagram_link}`
                      }
                    >
                      <img
                        src={InstagramIcon}
                        className="img-fluid"
                        alt="icon"
                      />
                    </a>
                  </li>
                )}
                {searchUserDetails?.twitter_link && (
                  <li className="social-item">
                    <a
                      target="_blank"
                      href={
                        searchUserDetails?.twitter_link?.includes('http')
                          ? searchUserDetails?.twitter_link
                          : `https://${searchUserDetails?.twitter_link}`
                      }
                    >
                      <img src={TwitterIcon} className="img-fluid" alt="icon" />
                    </a>
                  </li>
                )}
                {/* <li className="social-item">
                <a
                  target="_blank"
                  href={`${searchUserDetails?.facebook_link}`}
                >
                  <img src={LinkdinIcon} className="img-fluid" alt="icon" />
                </a>
              </li> */}
                {searchUserDetails?.other_profile_link && (
                  <li className="social-item">
                    <a
                      target="_blank"
                      href={
                        searchUserDetails?.other_profile_link?.includes('http')
                          ? searchUserDetails?.other_profile_link
                          : `https://${searchUserDetails?.other_profile_link}`
                      }
                    >
                      <img src={LinkIcon} className="img-fluid" alt="icon" />
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <div className="user-follow-details profile-follow d-flex align-items-center justify-content-center">
              <div className="follow-number text-center">
                <h5>
                  {searchUserDetails?.posts_count
                    ? searchUserDetails?.posts_count
                    : 0}
                </h5>
                <span>{t('sidebar.post')}</span>
              </div>
              <div
                className="follow-number text-center"
                onClick={() => setIsOpenFollowerModal(true)}
              >
                <h5>
                  {searchUserDetails?.followers_count
                    ? searchUserDetails?.followers_count
                    : 0}
                </h5>
                <span>{t('sidebar.followers')}</span>
              </div>
              <div
                className="follow-number text-center"
                onClick={() => setIsOpenFollowingModal(true)}
              >
                <h5>
                  {searchUserDetails?.following_count
                    ? searchUserDetails?.following_count
                    : 0}
                </h5>
                <span>{t('sidebar.following')}</span>
              </div>
            </div>
            {Number(id) !== Number(userId) && (
              <div className="follow-button d-flex align-items-center justify-content-center">
                {!searchUserDetails.is_following ? (
                  <button
                    className="btn btn-follow"
                    disabled={loading ? true : false}
                    onClick={handeleFollowUser}
                  >
                    {t('home.follow')}
                  </button>
                ) : (
                  <button
                    className="btn btn-follow"
                    disabled={loading ? true : false}
                    onClick={handeleUnFollowUser}
                  >
                    {t('home.unfollow')}
                  </button>
                )}
                <button
                  className="btn btn-message"
                  onClick={() => {
                    navigate(`/messages`, {
                      state: {
                        ...searchUserDetails,
                        fromPage: 'search-profile',
                      },
                    });
                  }}
                >
                  {t('home.message')}
                </button>
              </div>
            )}
          </div>
        </div>
        {Number(id) !== Number(userId) &&
          user_packages &&
          !searchUserDetails?.is_subscribe && (
            <div className="box-container subscription my-profile-user-details-background">
              <h4 className="subscription-title">{t('home.subscription')}</h4>
              <div className="subscription-pricelist">
                {user_packages.Monthly && (
                  <div
                    className="price-content btn"
                    onClick={(event) => {
                      event.stopPropagation();
                      // dispatch(subscribe(userId, user_packages.Monthly.id));
                      setSubUserId(id);
                      setUserPackageId(user_packages?.Monthly?.id);
                      setConfirmSubscription(true);
                    }}
                  >
                    <div className="price-label">
                      {user_packages.Monthly.title}
                    </div>
                    <div className="price-number">{`${+user_packages?.Monthly
                      ?.price}/${t('home.month')}`}</div>
                  </div>
                )}
                {user_packages.Annual && (
                  <div
                    className="price-content btn"
                    onClick={(event) => {
                      event.stopPropagation();
                      setSubUserId(id);
                      setUserPackageId(user_packages?.Annual?.id);
                      setConfirmSubscription(true);
                      // dispatch(subscribe(userId, user_packages.Annual.id));
                    }}
                  >
                    <div className="price-label">
                      {user_packages.Annual.title}
                    </div>
                    <div className="price-number">{`${+user_packages?.Annual
                      ?.price}/${t('home.year')}`}</div>
                  </div>
                )}
              </div>
              {user_packages.Free &&
                user_packages?.Free?.package_validity > 0 && (
                  <div
                    className="subscrib-button"
                    onClick={(event) => {
                      event.stopPropagation();
                      setSubUserId(id);
                      setUserPackageId(user_packages?.Free?.id);
                      setConfirmSubscription(true);
                      // dispatch(subscribe(userId, user_packages.Free.id));
                    }}
                  >
                    <button className="btn btn-subscribe">{`${t(
                      'home.subscribe for free'
                    )} ${user_packages?.Free?.package_validity} ${t(
                      'home.day then'
                    )} ${+user_packages?.Monthly?.price
                        ? user_packages?.Monthly?.price +
                        '/' +
                        `${t('home.month')}`
                        : user_packages?.Annual?.price +
                        '/' +
                        `${t('home.year')}`
                      }`}</button>
                  </div>
                )}
            </div>
          )}
        <div className="box-container">
          <div className="center-box">
            <PostFeed
              setPage={setPage}
              page={page}
              feedList={feedList}
              userFeedListData={userFeedList}
              fromPage={'my-profile'}
              onClick={() => { }}
              hasMore={hasMoreFeed}
            />
          </div>
        </div>

        <div className="sub-box-grid">
          <div className="center-box">
            {/* <!-- post --> */}
            {/* <PostFeed
            setPage={setPage}
            page={page}
            feedList={feedList}
            userFeedListData={userFeedList}
          /> */}
          </div>
          {/* <!-- right part --> */}
          <div className="right-box">
            {/* <Suggestions />
          <TrendingFeeds /> */}
          </div>
        </div>
      </div>
      <>
        {isOpenFollowerModal && (
          <Followers
            isOpenFollowerModal={isOpenFollowerModal}
            setIsOpenFollowerModal={setIsOpenFollowerModal}
            userId={id}
          />
        )}
        {isOpenFollowingModal && (
          <Following
            isOpenFollowingModal={isOpenFollowingModal}
            setIsOpenFollowingModal={setIsOpenFollowingModal}
            userId={id}
          />
        )}
        <Modal
          show={confirmSubscription}
          onHide={() => setConfirmSubscription(false)}
          // className="view-follower-modal"
          centered
        >
          <div className="m-3">
            {/* <div className="box-container notification-container"> */}
            <p className="m-3">Are You Sure Subscribe The User?</p>
            <div className="subscribe-confirm-modal mt-2">
              <Button
                className="btn btn-primary"
                type="submit"
                onClick={handleSubscribeUser}
              >
                Subscribe
              </Button>
              <Button
                className="btn btn-secondary"
                onClick={handleCloseSubscribe}
                type="button"
              >
                Cancel
              </Button>
              {/* </div> */}
            </div>
          </div>
        </Modal>
      </>
    </>
  ) : (
    <div className="search-profile-loading-spiner">
      <div className="spinner"></div>
    </div>
  );
}
