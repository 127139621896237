import { Action } from "redux";
import { ThunkAction } from "redux-thunk";

import { apiCall, METHOD } from "../../service/baseApiCall";
import { Store } from "../Actions";
import { MY_SUBSCRIBERS_URL } from "../ApiEndPoints";
import { MY_SUBSCRIBER } from "../constants/action-types";

export const mySubScribers =
  (values: Object): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(mySubScribersInit(values));
  };

export const mySubScribersInit =
  (values: Object): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: MY_SUBSCRIBER.MY_SUBSCRIBER_INITLIZATION,
    });
    apiCall(
      MY_SUBSCRIBERS_URL,
      { package_user_id: 1, package_id: 5 },
      (res: any) => {
        dispatch(mySubScribersSuccess(res));
      },
      (err: any) => {
        dispatch(mySubScribersError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const mySubScribersSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: MY_SUBSCRIBER.MY_SUBSCRIBER_SUCCESS,
      payload: res.data,
    });
  };

export const mySubScribersError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: MY_SUBSCRIBER.MY_SUBSCRIBER_ERORR,
    });
  };