export const createImageFromInitials = (size:any, name:any, color="#3662ff") => {
    if (name == "null null" || name == undefined || name == null) return undefined;
    name=getInitials(name)

    const canvas=document.createElement('canvas')
    const context:any=canvas.getContext('2d')
    canvas.width=canvas.height=size
    var centerX = canvas.height / 2;
    var centerY = canvas.height / 2;
    var radius = 200;
    context.beginPath()
    context.arc(centerX, centerY, radius, 0, 2 * Math.PI,false);
    context.fillStyle="#ffffff"
    context.fill()
    context.fillStyle=`#0296dd`
    context.fill()
    context.fillStyle="white";
    context.textBaseline='middle'
    context.textAlign='center'
    context.font =`${size/2}px Roboto`
    context.fillText(name,(size/2),(size/2))
    context.stroke()

    return canvas.toDataURL()
};
const getInitials = (name:any) => {
    let initials;
    const nameSplit = name.split(" ");
    const nameLength = nameSplit.length;
    if (nameLength > 1) {
        initials =
            nameSplit[0].substring(0, 1) +
            nameSplit[nameLength - 1].substring(0, 1);
    } else if (nameLength === 1) {
        initials = nameSplit[0].substring(0, 1);
    } else return;

    return initials.toUpperCase();
};
export const  getRandomColor=()=> {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return "#3662ff";
  }