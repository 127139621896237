/* eslint-disable import/no-anonymous-default-export */
import { PAYOUT } from "../constants/action-types";
import { PayoutData } from "../Actions";
import { ActionTypes } from "../action/userActions";

const initialState = {
  loading: false,
  payoutHistory: [],
  isInAppLoading: false,
  isInAppSuccess: false
};
export default (state: PayoutData = initialState, action: ActionTypes) => {
  switch (action.type) {
    case PAYOUT.ADD_PAYOUT_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case PAYOUT.ADD_PAYOUT_SUCCESS:
      return {
        ...state,
        postData: action.payload,
      };
    case PAYOUT.ADD_PAYOUT_ERORR:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case PAYOUT.PAYOUT_HISTORY_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case PAYOUT.PAYOUT_HISTORY_SUCCESS:
      return {
        ...state,
        payoutHistory: action.payload,
        loading: false,
      };
    case PAYOUT.PAYOUT_HISTORY_ERORR:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case PAYOUT.IN_APP_PAYMENT_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        isInAppLoading: true,
        isInAppSuccess:false
      };
    case PAYOUT.IN_APP_PAYMENT_SUCCESS:
      return {
        ...state,
        isInAppLoading:false,
        isInAppSuccess:true
      };
    case PAYOUT.IN_APP_PAYMENT_ERORR:
      return {
        ...state,
        ...action.payload,
        loading: false,
        isInAppLoading:false,
        isInAppSuccess:false
      };
    default:
      return state;
  }
};
