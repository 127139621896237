import React, { useState, useEffect, useRef, Fragment } from 'react';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { BsPlayFill, BsPauseFill } from 'react-icons/bs';

let timer: any;

const AudioPlayer = ({ track }: any) => {
  const audioRef = useRef<any>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [totalDuration, setTotalDuration] = useState<any>();
  const handleChange = (value: number) => {
    audioRef.current.currentTime = audioRef.current.duration * (value / Math.floor(totalDuration?.duration));
    // setTotalDuration(audioRef.current.duration);
  };
  useEffect(() => {
    if (audioRef?.current?.pause) {
      audioRef.current.pause();
      audioRef.current = null;
      setIsPlaying(false);
    }
    audioRef.current = new Audio(track);
    setTimeout(() => {
      setTotalDuration(audioRef.current);
    }, 400);
    setCurrentTime(0);
    return () => {
      if (audioRef?.current?.pause) {
        audioRef.current.pause();
        audioRef.current = null;
        setIsPlaying(false);
      }
      audioRef.current = new Audio(track);
      setCurrentTime(0);
    };
  }, [track]);

  const startTimer = () => {
    timer = setInterval(() => {
      const duration = audioRef.current.duration;
      const playedTime = audioRef.current.currentTime;
      setCurrentTime(
        Math.ceil((Math.min(playedTime, duration) / duration) * Math.floor(totalDuration?.duration))
      );

      if (playedTime === duration) {
        setIsPlaying(false);
        audioRef.current.currentTime = 0;
      }
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(timer);
  };

  const handlePlayPause = () => {
    if (audioRef.current && !isPlaying) {
      startTimer();
      audioRef.current.play();
      setIsPlaying(true);
    }

    if (audioRef.current && isPlaying) {
      stopTimer();
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };
  function fancyTimeFormat(duration: any) {
    // Hours, minutes and seconds
    const hrs = ~~(duration / 3600);
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = '';

    if (hrs > 0) {
      ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
    }

    ret += '' + mins + ':' + (secs < 10 ? '0' : '');
    ret += '' + secs;

    return ret;
  }
  return (
    <div className="audio-player-container">
      <button onClick={handlePlayPause} className="audio-play-btn">
        {isPlaying ? (
          <BsPauseFill size={20} color="#fff" />
        ) : (
          <BsPlayFill size={20} color="#fff" />
        )}
      </button>
      <div className="audio-slider">
        <span className='audio-duration-count'>
          <span >{fancyTimeFormat(currentTime) || "0:00"}</span>
          <span >{fancyTimeFormat(totalDuration?.duration) }</span>
        </span>
        <InputRange
          step={1}
          maxValue={Math.floor(totalDuration?.duration)}
          minValue={0}
          value={currentTime}
          onChange={(value) => handleChange(value as number)}
        />
      </div>
    </div>
  );
};

export default AudioPlayer;
