import React, { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import PhotoGalary from '../../assets/images/icons/photo_icon.svg';
import TextIcon from '../../assets/images/icons/Layer 2.svg';
import CloseIcon from '../../assets/images/icons/close_icon.svg';
import AddInputIcon from '../../assets/images/icons/Group 45807.svg';
import ImageUploadIcon from '../../assets/images/icons/option_image.svg';
import { useTranslation } from 'react-i18next';
import { createTextPost } from '../../redux/action/postActions';
import { AppDispatch } from '../../redux';
import { useDispatch } from 'react-redux';
import { MEDIA_SIZE } from '../../constants/App-constant';
import { uploadFileAmazonS3 } from '../../service/index.';
import { userDashboardFeed } from '../../redux/action';
const PollPost = ({ showPollPostModal, setShowPollPostModal }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState<boolean>(false);
  const [addInput, setAddInput] = useState<any>([
    { key: ' 1', value: '' },
    { key: ' 2', value: '' },
    { key: ' 3', value: '' },
  ]);
  const [addImagePost, setAddImagePost] = useState<any>([
    { key: '1', value: '', textValue: '' },
    { key: '2', value: '', textValue: '' },
    { key: '3', value: '', textValue: '' },
  ]);
  const [questionData, setQuestionData] = useState<any>('');
  const [optionLimitError, setOptionLimitError] = useState<any>('');
  const [pollPostError, setPollPostError] = useState<any>('');
  const [tab, setTab] = useState<any>(0);
  const handleAddNewOnption = () => {
    setOptionLimitError('');
    if (tab == 0) {
      if (addInput.length < 6) {
        setAddInput([
          ...addInput,
          { key: Number(addInput[addInput.length - 1].key) + 1, value: '' },
        ]);
      } else {
        setOptionLimitError('Max');
      }
    } else if (tab == 1) {
      if (addImagePost.length < 6) {
        setAddImagePost([
          ...addImagePost,
          {
            key: `${Number(addImagePost[addImagePost.length - 1].key) + 1}`,
            value: '',
            textValue: '',
          },
        ]);
      } else {
        setOptionLimitError('Max');
      }
    }
  };
  const handleRemoveInput = (option: any) => {
    setOptionLimitError('');
    if (tab == 0) {
      if (addInput.length > 2) {
        setAddInput(
          addInput.filter((elem: any, index: any) => index !== option)
        );
      } else {
        setOptionLimitError('Min');
      }
    } else if (tab == 1) {
      if (addImagePost.length > 2) {
        setAddImagePost(
          addImagePost.filter((elem: any, index: any) => index !== option)
        );
      } else {
        setOptionLimitError('Min');
      }
    }
  };
  const handleOptionTextChange = (e: any, index: any) => {
    setPollPostError("")
    setAddInput(
      addInput?.map((elem: any, idx: any) => {
        if (idx === index) {
          return { ...elem, value: e.target.value };
        }
        return elem;
      })
    );
  };
  const handleChangeUploadImage = (e: any, idx: any) => {
    const file: any = e.target.files[0];
    setPollPostError('')
    if (file?.size / 1024 < MEDIA_SIZE.IMAGE_SIZE) {
      if (file?.name.match(/\.(jpg|jpeg|png)$/)) {
        setAddImagePost(
          addImagePost?.map((elem: any, index: any) => {
            if (idx === index) {
              return {
                ...elem,
                value: file,
              };
            }
            return elem;
          })
        );
      }
    } else {
      setPollPostError('imageSize');
    }
  };
  const handleSubmitPoll = async () => {
    var submitData: any;
    const page: any = 1;
    setLoading(true);
    if (questionData) {
      if (tab === 0) {
        const optionTextData: any = addInput
          ?.map((elem: any) => {
            if (elem.value) {
              return { text: `${elem.value.trim()}` };
            }
            return undefined;
          })
          .filter((elem: any) => elem !== undefined);
        if (optionTextData.length >= 2) {
          submitData = {
            post_type: 4,
            question: questionData.trim(),
            poll_type: 'text',
            options: optionTextData,
          };
          await dispatch(createTextPost(submitData));
          await dispatch(userDashboardFeed(page));
          setShowPollPostModal(false);
          setPollPostError('');
          setQuestionData('');
          setLoading(false);
          setAddInput([
            { key: ' 1', value: '' },
            { key: ' 2', value: '' },
            { key: ' 3', value: '' },
          ]);
        } else {
          setPollPostError('TextOptions');
        }
      }
      if (tab == 1) {
        const optionImageTextLength = addImagePost.filter(
          (elem: any) => elem.value !== '' && elem.textValue !== ''
        );
        if (optionImageTextLength.length >= 2) {
          const optionImageData: any = await Promise.all(
            addImagePost?.map(async (elem: any) => {
              if (elem.value || elem.textValue) {
                if (elem.value) {
                  if (elem.textValue) {
                    return await uploadFileAmazonS3(
                      elem.value,
                      'post/images/'
                    ).then((data) => {
                      return { text: elem.textValue.trim(), image: data.Key };
                    });
                  } else {
                    return undefined;
                  }
                } else {
                  return undefined;
                }
              }
            })
          );
          submitData = {
            post_type: 4,
            question: questionData.trim(),
            poll_type: 'text',
            options: optionImageData.filter((elem: any) => elem !== undefined),
          };
          if (submitData?.options?.length >= 2) {
            await dispatch(createTextPost(submitData));
            await dispatch(userDashboardFeed(page));
            setShowPollPostModal(false);
            setQuestionData('');
            setAddImagePost([
              { key: '1', value: '', textValue: '' },
              { key: '2', value: '', textValue: '' },
              { key: '3', value: '', textValue: '' },
            ]);
            setPollPostError('');
            setLoading(false);
          } else {
            setPollPostError('options');
            setLoading(false);
          }
        } else {
          setPollPostError('options');
          setLoading(false);
        }
      }
    } else {
      setPollPostError('question');
    }
    setLoading(false);
  };
  const handleQuestionChange = (e: any) => {
    setPollPostError('');
    setQuestionData(e.target.value);
  };
  const handleImageTextChange = (e: any, idx: any) => {
    setAddImagePost(
      addImagePost?.map((elem: any, index: any) => {
        if (idx === index) {
          return { ...elem, textValue: e.target.value};
        }
        return elem;
      })
    );
  };
  return (
    <Modal
      show={showPollPostModal}
      onHide={() => {
        setShowPollPostModal(false);
        setAddInput([
          { key: ' 1', value: '' },
          { key: ' 2', value: '' },
          { key: ' 3', value: '' },
        ]);
        setAddImagePost([
          { key: '1', value: '', textValue: '' },
          { key: '2', value: '', textValue: '' },
          { key: '3', value: '', textValue: '' },
        ]);
        setQuestionData('');
        setPollPostError('');
        setOptionLimitError('');
      }}
      className="poll-post-upload-modal"
      centered
    >
      <Modal.Body>
        <div>
          <p className="option-modal-title">Poll Type</p>
        </div>
        <div className="poll-option">
          <div className="poll-option-child-div">
            <div
              onClick={() => {
                setTab(0);
                setOptionLimitError('');
                setPollPostError('');
                setAddInput([
                  { key: ' 1', value: '' },
                  { key: ' 2', value: '' },
                  { key: ' 3', value: '' },
                ]);
                setAddImagePost([
                  { key: '1', value: '', textValue: '' },
                  { key: '2', value: '', textValue: '' },
                  { key: '3', value: '', textValue: '' },
                ]);
              }}
              className={`poll-option-main-div ${
                tab == 0 && 'active-option-tab'
              }`}
            >
              <img src={TextIcon} alt="text" className="poll-option-image" />
            </div>
            <p className="poll-option-text-property">Text</p>
          </div>
          <div className="poll-option-child-div">
            <div
              onClick={() => {
                setTab(1);
                setOptionLimitError('');
                setPollPostError('');
                setAddImagePost([
                  { key: '1', value: '', textValue: '' },
                  { key: '2', value: '', textValue: '' },
                  { key: '3', value: '', textValue: '' },
                ]);
              }}
              className={`poll-option-main-div ${
                tab == 1 && 'active-option-tab'
              }`}
            >
              <img
                src={PhotoGalary}
                alt="image"
                className="poll-option-image-photo"
              />
            </div>
            <p className="poll-option-text-property">Image</p>
          </div>
        </div>
        <div>
          <p className="option-modal-title">Your Question</p>
          <input
            value={questionData}
            onChange={handleQuestionChange}
            className="poll-question-input"
            placeholder="Ask Question"
          />
        </div>
        <p className="poll-quesion-error mt-2">
          {pollPostError === 'question' && 'Please enter question'}
        </p>
        <div>
          <div>
            <div className="d-flex option-main-div">
              <p className="option-modal-title">Options</p>
              <p className="option-modal-title">
                {tab == 0 ? addInput.length : addImagePost.length}/6
              </p>
            </div>
            {tab == 0 ? (
              <div>
                {addInput.map((option: any, index: any) => {
                  return (
                    <>
                      <input
                        className="poll-question-input"
                        placeholder={`Option ${index + 1}`}
                        value={option.value}
                        onChange={(e) => handleOptionTextChange(e, index)}
                      />
                      <span onClick={() => handleRemoveInput(index)}>
                        <img src={CloseIcon} alt="text" className="" />
                      </span>
                    </>
                  );
                })}
              </div>
            ) : (
              <div>
                {addImagePost.map((option: any, index: any) => {
                  return (
                    <div className="d-flex mb-3">
                      {!option.value ? (
                        <div className="upload-image-icon-box">
                          <label
                            htmlFor="upload"
                            className="lable-for-image-upload"
                          >
                            <img
                              src={ImageUploadIcon}
                              alt="image_icon"
                              className="option-upload-image-icon"
                            />
                            <input
                              type="file"
                              id="upload"
                              accept="image/jpeg,image/jpg,image/png"
                              onChange={(e) =>
                                handleChangeUploadImage(e, index)
                              }
                              hidden
                            />
                          </label>
                        </div>
                      ) : (
                        <div className="upload-image-icon-box-image-preview">
                          <img
                            src={
                              option?.value
                                ? URL?.createObjectURL(option?.value)
                                : ''
                            }
                            alt="image_icon"
                            className="poll-option-image-preview"
                          />
                        </div>
                      )}
                      <input
                        className="poll-question-input"
                        placeholder="Text"
                        value={option.textValue}
                        onChange={(e) => handleImageTextChange(e, index)}
                      />
                      <span onClick={() => handleRemoveInput(index)}>
                        <img
                          src={CloseIcon}
                          alt="text"
                          className="delete-option-element"
                        />
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
            {pollPostError === 'options' && (
              <p className="poll-quesion-error mt-2">
                Please provide at least two options – each option must include
                both an image and accompanying text.
              </p>
            )}
            {pollPostError === 'TextOptions' && (
              <p className="poll-quesion-error mt-2">
                Please provide at least two options.
              </p>
            )}
            {optionLimitError === 'Max' && (
              <p className="mt-3 ml-2 poll-option-error-message ">
                You can add maximum 6 option only
              </p>
            )}
            {optionLimitError === 'Min' && (
              <p className="mt-3 ml-2 poll-option-error-message ">
                Minimum 2 options are compulsory
              </p>
            )}
            {tab === 0 && addInput.length < 6 && (
              <div onClick={handleAddNewOnption} className="mt-4 ml-1">
                {/* <button> */}
                <img src={AddInputIcon} alt="text" className="" />
                {/* </button> */}
              </div>
            )}
            {tab === 1 && addImagePost.length < 6 && (
              <div onClick={handleAddNewOnption} className="mt-4 ml-1">
                {/* <button> */}
                <img src={AddInputIcon} alt="text" className="" />
                {/* </button> */}
              </div>
            )}

            {pollPostError === 'imageSize' && (
              <p className="mt-3 ml-2 poll-option-error-message ">
                Please Select less then {MEDIA_SIZE?.IMAGE_SIZE / 1024} MB Image
                file
              </p>
            )}
          </div>
        </div>
        <div className="poll-modal-footer-button mt-5">
          <button
            type="button"
            className="btn btn-login w-100 py-4 d-flex"
            data-bs-dismiss="modal"
            onClick={handleSubmitPoll}
            disabled={loading}
            // style={{color:"#fff",fontSize:"24px",fontWeight:600}}
          >
            {loading ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <>{t('home.post')}</>
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PollPost;
