import { Form, Modal } from 'react-bootstrap';
import commentImage from '../../assets/images/imgs/comment-img.svg';
import { ReactComponent as ViewIcon } from '../../assets/images/icons/eyeIcon.svg';
import { ReactComponent as CloseChat } from '../../assets/images/icons/close-chat.svg';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { METHOD, apiCall } from '../../service/baseApiCall';
import { CREATE_LIVE_STREMING_URL, CREATE_TOKEN_URL, DELETE_LIVE_STREAM } from '../../redux/ApiEndPoints';
import { Store } from '../../redux/Actions';
// import AgoraRTC from "agora-rtc-sdk";
import AgoraRTC from 'agora-rtc-sdk-ng';
import Chat from './Chat';
import { exitLiveStreaming, setHostToken } from '../../redux/action';
import { AppDispatch } from '../../redux';
import { S3_BASE_URL } from '../../service/aws/config';
import Loader from '../Loader';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const getrandomno = (len: Number) => {
  var p = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let random = [...Array(len)].reduce((a) => a + p[~~(Math.random() * p.length)], '');
  return random;
};

const option = {
  appID: 'c9b1eca1d6694b92933d98bcea05140f',
  key: 'e128ae63b6ac4e5b9c51887eafbf89b2',
  secret: '68f6b879ae8145f3ba01f3e11e287b67',
};

export const StartLiveStreaming = (props: { show: boolean; onHide: () => void }) => {
  // const [remoteUsers, setRemoteUsers] = useState({});
  const [options, setOptions] = useState({
    appid: 'c9b1eca1d6694b92933d98bcea05140f',
    channel: '',
    uid: null,
    token: '',
    role: 'host',
    audienceLatency: 1,
  });
  const [ChannelParameters, setChannelParameters] = useState<any>({
    audioTrack: '',
    videoTrack: '',
  });

  const [freeCheck, setFreeCheck] = useState(false);
  const handleClose = () => props.onHide();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [streamID, setStreamId] = useState('');
  const [validated, setValidated] = useState(false);
  const [streamOn, setStreamOn] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState<boolean>(true);
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const userDetail: any = useSelector((state: Store) => state.userDataReducer.userDetail);
  const token = useSelector((state: Store) => state.goLiveReducer.hostToken);
  const profilePhoto: string = useSelector((state: Store) => state.userDataReducer.profilePhoto);
  const [randomid] = useState(getrandomno(10));
  const [client, setClient] = useState(null as any);
  const [member, setMember] = useState<number>(0);
  const [screenToggle, setScreenToggle] = useState<boolean>(false);
  const [endStreamMessage, setEndStreamMessage] = useState<any>({ messageType: 'LIVE_MESSAGE_TYPE_END_STREAM', isEnd: false });
  const [price, setPrice] = useState<string>('')

  const fullScreenHandler = useFullScreenHandle();

  const createClient = async () => {
    const agoraClient = AgoraRTC.createClient({ mode: 'live', codec: 'h264' });
    setClient(agoraClient);
  };

  const getAgoraToken = async (id: any) => {
    let data = { channelName: id };

    await apiCall(
      CREATE_TOKEN_URL,
      data,
      (res: any) => {
        dispatch(
          setHostToken({
            rtmToken: res.data.rtmtoken,
            rtcToken: res.data.rtctoken,
          }),
        );
      },
      (msg: string) => {
        console.error('Error in getting Agora token : %s', msg);
      },
      METHOD.POST,
    );
  };

  const deleteStream = async () => {
    let data = { stream_id: streamID };
    await apiCall(
      DELETE_LIVE_STREAM,
      data,
      (res: any) => {

      },
      (msg: string) => {
        console.error('Error in getting Agora token : %s', msg);
      },
      METHOD.POST,
    );
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    event.stopPropagation();
    try {
      const mediaDevices = navigator.mediaDevices;
      const devices = await mediaDevices.enumerateDevices();

      const hasWebcam = devices.some((device) => device.kind === 'videoinput');

      if (!hasWebcam) {
        alert('No webcam found!');
      } else {
        createClient();
        // const form = event.currentTarget;
        // if (form.price == 0) { toast.error('enter a valid price'); return }
        if (form && form.checkValidity() === false) {
          setValidated(true);
        } else {
          setIsVideoReady(false)
          setValidated(true);
          var temp_id = randomid;
          setStreamId(temp_id);
          let data = {
            stream_id: temp_id,
            broadcaster_id: userDetail?.id,
            title: form.title.value,
            description: form.description.value,
            amount: form.price ? form.price.value : 0,
            type: !freeCheck ? 0 : 1,
          };

          await apiCall(
            CREATE_LIVE_STREMING_URL,
            data,
            (res: any) => {
              getAgoraToken(temp_id);
            },
            (msg: string) => {
              console.error('Error in creating live stream: %s', msg);
            },
            METHOD.POST,
          );
        }
      }
    } catch (error) {
      console.error('Error accessing media devices:', error);
    }
  };

  const endStream = async (reason: string = 'user') => {
    try {
      setPrice('')
      setEndStreamMessage({ ...endStreamMessage, isEnd: true })
      deleteStream()
      if (reason != 'permission') {
        await ChannelParameters.audioTrack.close();
        await ChannelParameters.videoTrack.close();
      }
      // setRemoteUsers({});
      await client.leave(() => { });
      dispatch(exitLiveStreaming());
      handleClose();
    } finally {
      // channelParameters.audioTrack = null;
      // channelParameters.videoTrack = null;
      // setClient(null);
      setStreamOn(false);
    }
  };

  const join = async () => {
    try {
      setStreamOn(true);
      client.setClientRole(options.role);
      options.channel = streamID;
      options.token = token.rtcToken;
      options.uid = userDetail?.id;
      await client.join(options.appid, options.channel, token.rtcToken, parseInt(userDetail?.id));

      if (options.role === 'host') {
        const [audioTrack, videoTrack] = await AgoraRTC.createMicrophoneAndCameraTracks()
        ChannelParameters.audioTrack = audioTrack;
        ChannelParameters.videoTrack = videoTrack;
        ChannelParameters.videoTrack.play('local-player');
        document.getElementById('joined-setup')!.style.display = 'flex';
        await client.publish(Object.values(ChannelParameters));
        setIsVideoReady(true);
      }
    } catch (error: any) {
      toast.error("Please allow the camera and microphone access to Go Live")
      setStreamOn(false);
      setClient(null)
      handleClose();
      setIsVideoReady(!isVideoReady)
      endStream('permission')
    }
  };

  const joinChannel = () => {
    if (token.rtcToken === '') {
      return;
    }
    join();
  };

  useEffect(() => {
    if (token) {
      joinChannel();
    }
  }, [token]);

  function toggleFullScreen() {
    if (screenToggle) {
      setScreenToggle(false);
      fullScreenHandler.exit();
    } else {
      setScreenToggle(true);
      fullScreenHandler.enter();
    }
  }

  return (
    <>
      <Modal className={`${!streamOn ? 'upload-image-popup' : ''} golive-modal`} fullscreen={streamOn ? true : ''} centered onHide={handleClose} show={props.show} keyboard={false} backdrop='static'>
        <Modal.Body className='p-0'>
          <FullScreen handle={fullScreenHandler}>
            <div className='row m-0'>
              <div className={`col-12 ${screenToggle ? 'col-md-12 col-xl-12 p-0' : streamOn ? 'col-md-9 col-xl-9 pl-0' : 'col-md-6 col-xl-6 d-flex align-items-center'} cmt-img-wrapper position-relative`}>
                {streamOn && (
                  <div style={{ height: '100%', position: 'relative' }}>
                    <div>
                      <div id='joined-setup' style={{ display: 'none' }}>
                        <div id='local-player' style={{ width: '100%', height: '100vh' }} className={`${!streamOn ? 'player' : screenToggle ? 'player-full-screen-contain' : 'player-full-screen-cover'}`}></div>
                      </div>
                    </div>
                    {isVideoReady && (
                      <div className={'live-title-overlay'}>
                        <div>
                          <h3>{title}</h3>
                          <p className='text-justify'>{description}</p>
                        </div>
                        <button type='button' className='exit-btn full-screen' onClick={toggleFullScreen}>
                          {/* <img alt='' src='assets/images/icons/logout.svg' className='me-1' /> */}
                          {screenToggle ? 'Exit Full Screen' : 'Full Screen'}
                        </button>
                      </div>
                    )}
                    {/* <div className='display-flex justify-content-space-between mt-2'>
                  <button className='mr-2' onClick={toggleAudio}>
                    {liveButton.audio ? 'mic-on' : 'mic-off'}
                  </button>
                  <button className='mr-2' onClick={toggleVideo}>
                    {liveButton.video ? 'camera-on' : 'camera-off'}
                  </button>
                  <button onClick={endStream}>Dis-connect</button>
                </div> */}
                  </div>
                )}

                {!streamOn && <img src={commentImage} alt='free' className='img-fluid d-block h-auto' style={{ borderRadius: '30px', width: '100%', height: '100%' }} />}
              </div>
              <div className={`${streamOn ? 'col-12 col-md-3 col-xl-3 d-flex flex-column my-4 pt-3' : 'col-12 col-md-6 col-xl-6 d-flex flex-column'} ${screenToggle ? 'd-none' : ''}`}>
                <button
                  type='button'
                  className={streamOn ? 'exit-live-stream' : 'exit-btn'}
                  data-bs-dismiss='modal'
                  onClick={(event) => {
                    event.stopPropagation();
                    if (streamOn) endStream();
                    else { handleClose(); setPrice('') }
                  }}
                >
                  {/* <img alt='' src='assets/images/icons/logout.svg' className='me-1' /> */}
                  {streamOn ? 'Exit Live Stream' : 'Exit'}
                </button>
                {streamOn && <CloseChat className='close-chat' onClick={() => setScreenToggle(!screenToggle)} />}
                <div className={`${streamOn ? 'p-0 h-100 ' : ''} modal-body d-flex flex-column`}>
                  {!streamOn ? (
                    <Form className='h-100 d-flex flex-column justify-content-space-between' noValidate validated={validated} onSubmit={handleSubmit}>
                      <div className='w-100 scroll-box'>
                        <Form.Control
                          required
                          name='title'
                          type='text'
                          placeholder={`${t("home.title")}`}
                          // defaultValue='Mark'
                          onChange={(e) => setTitle(e.target.value)}
                          className='border-0 text-center amount-label w-100 mw-100 bg-opacity-100 font-weight-bold'
                        />

                        <Form.Control
                          // required
                          onChange={(e) => { setDescription(e.target.value) }}
                          name='description'
                          placeholder={`${t("home.description")}`}
                          as='textarea'
                          rows={3}
                          className='bg-opacity-100 font-weight-bold border-0 text-center amount-label w-100 mw-100 bg-opacity-100'
                        />

                        <div className='my-2'>
                          <div className='switch-div text-right'>
                            <label className='switch'>
                              <Form.Check className='pointer-cursor' type='switch' id='custom-switch' label={freeCheck ? t("home.paid") : t("home.free")} title='freeCheck' onChange={() => setFreeCheck(!freeCheck)} />
                            </label>
                          </div>
                          {freeCheck && (
                            <div className='form-check form-switch free-label pl-0'>
                              <div className='amount-label p-3 p-lg-4'>
                                <p className='paid-title'>{t("home.paid live stream")}</p>
                                <Form.Control required={freeCheck} min='1' type='number' name='price' placeholder={`${t("home.enter price")}`} className='border-0 text-center amount-label mt-0 bg-white' onKeyDown={e => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()} onChange={(event: any) => { if (event.target.value === "0") return; setPrice(event.target.value) }} value={price} />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='mt-auto'>
                        <button type='submit' className='btn live-btn mb-3'>
                          <span></span>
                          {t("header.go live")}
                        </button>
                      </div>
                    </Form>
                  ) : (
                    <>
                      <div className='host-live-view'>
                        <div className='d-flex align-items-center'>
                          <ViewIcon className='mr-1' />
                          {member}
                        </div>
                      </div>
                      <Chat
                        appId={option.appID}
                        channelId={streamID}
                        token={token.rtmToken}
                        userId={userDetail?.id?.toString()}
                        profilePic={S3_BASE_URL + profilePhoto}
                        userName={userDetail.first_name + '' + userDetail.last_name}
                        messageType={endStreamMessage.isEnd ? endStreamMessage.messageType : 'LIVE_MESSAGE_TYPE_TEXT'}
                        message={''}
                        setForceToLeaveStreaming={() => { }}
                        setMember={setMember}
                        endStreamMessage={endStreamMessage}
                        setEndStreamMessage={setEndStreamMessage}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            {!isVideoReady && <Loader />}
          </FullScreen>
        </Modal.Body>
      </Modal>
    </>
  );
};
