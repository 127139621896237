import { apiCall, METHOD } from "../../service/baseApiCall";
import {
  GET_COMMENTS,
  POST_BY_ID,
  CREATE_TEXT_POST,
  GET_TAG_LIST,
  CREATE_COMMENT,
  ADD_LIKE,
  ADD_TO_FAVOURITE,
  REMOVE_FROM_FAVOURITE,
  FAVOURITE_LIST,
  USER_FEEDS,
  USER_DASHBOARD_FEEDS,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import {
  ADD_LIKE_URL,
  ADD_TO_FAVOURITE_URL,
  CREATE_COMMENT_URL,
  CREATE_POST_URL,
  FAVOURITE_LIST_URL,
  GET_COMMENTS_URL,
  GET_TAG_LIST_URL,
  HIDE_POST_URL,
  POST_BY_ID_URL,
  REMOVE_FAVOURITE_FAVOURITE_URL,
  REPORT_POST_URL,
  UNHIDE_POST_URL,
} from "../ApiEndPoints";
import { toast } from "react-toastify";
import { getToken } from "../../utils/utility";
import { addPostSuccess } from ".";

export const getPostById =
  (postId: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(getPostByIdInit(postId));
  };

export const clearPostData =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
    dispatch({ type: POST_BY_ID.CLEAR_POST_DATA });
  };

export const getPostByIdInit =
  (postId: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: POST_BY_ID.POST_BY_ID_INITLIZATION,
    });
    apiCall(
      POST_BY_ID_URL + postId,
      {},
      (res: any) => {
        dispatch(getPostByIdSuccess(res));
      },
      (err: any) => {
        dispatch(getPostByIdError(err));
      },
      METHOD.GET,
      {}
    );
  };

export const getPostByIdSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: POST_BY_ID.POST_BY_ID_SUCCESS,
      payload: res.data,
    });
  };

export const getPostByIdError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: POST_BY_ID.POST_BY_ID_ERORR,
    });
    // toast.error(err.message)
  };

export const getCommentByPostId =
  (postId: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(getCommentByPostIdInit(postId));
  };

export const getCommentByPostIdInit =
  (postId: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_COMMENTS.GET_COMMENTS_INITLIZATION,
    });
    apiCall(
      GET_COMMENTS_URL,
      { post_id: postId, page: 1 },
      (res: any) => {
        dispatch(getCommentByPostIdSuccess(res));
      },
      (err: any) => {
        dispatch(getCommentByPostIdError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const getCommentByPostIdSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_COMMENTS.GET_COMMENTS_SUCCESS,
      payload: res.data,
    });
  };

export const getCommentByPostIdError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_COMMENTS.GET_COMMENTS_ERORR,
    });
    // toast.error(err.message)
  };

export const createTextPost =
  (
    data: any,
    onSuccess?: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(createTextPostInit(data, onSuccess));
  };

export const createTextPostInit =
  (
    data: any,
    onSuccess?: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CREATE_TEXT_POST.CREATE_TEXT_POST_INITLIZATION,
    });
    apiCall(
      CREATE_POST_URL,
      data,
      (res: any) => {
        if (onSuccess) onSuccess();
        dispatch(createTextPostSuccess(res));
        toast.success("Post created successfully");
      },
      (err: any) => {
        dispatch(createTextPostError(err));
        toast.error("Post is not created");
      },
      METHOD.POST,
      {}
    );
  };

export const createTextPostSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(addPostSuccess(res.data));

    dispatch({
      type: CREATE_TEXT_POST.CREATE_TEXT_POST_SUCCESS,
      payload: res.data,
    });
  };

export const createTextPostError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CREATE_TEXT_POST.CREATE_TEXT_POST_ERORR,
    });
    // toast.error(err.message)
  };

export const getSearchedUserList =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(getSearchedUserListInit(data));
  };

export const getSearchedUserListInit =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_TAG_LIST.GET_TAG_LIST_INITLIZATION,
    });
    apiCall(
      GET_TAG_LIST_URL,
      { search: data },
      (res: any) => {
        dispatch(getSearchedUserListSuccess(res));
      },
      (err: any) => {
        dispatch(getSearchedUserListError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const getSearchedUserListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_TAG_LIST.GET_TAG_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getSearchedUserListError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GET_TAG_LIST.GET_TAG_LIST_ERORR,
    });
    // toast.error(err.message)
  };

//========================================================Create comment===============================================
export const ceateComment =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(ceateCommentInit(data));
  };

export const ceateCommentInit =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CREATE_COMMENT.CREATE_COMMENT_INITLIZATION,
    });
    apiCall(
      CREATE_COMMENT_URL,
      data?.data,
      (res: any) => {
        if (data?.onSuccess) data?.onSuccess();
        dispatch(ceateCommentSuccess(res));
      },
      (err: any) => {
        dispatch(ceateCommentError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const ceateCommentSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CREATE_COMMENT.CREATE_COMMENT_SUCCESS,
      payload: res.data,
    });
    dispatch({
      type: CREATE_COMMENT.INCREASE_FAVOURITE_COMMENT_COUNT,
      payload: { id: res.data.post_id, count: res.data.comment_counter },
    });
    dispatch({
      type: USER_DASHBOARD_FEEDS.INCREASE_COMMENT_COUNT,
      payload: { id: res.data.post_id, count: res.data.comment_counter },
    });
  };

export const ceateCommentError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: CREATE_COMMENT.CREATE_COMMENT_ERORR,
    });
    // toast.error(err.message)
  };

//===========================================ADD LIKE=========================================
export const addLike =
  (
    feedId: any,
    fromPage: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(addLikeInit(feedId, fromPage));
  };

export const addLikeInit =
  (
    feedId: any,
    fromPage: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: ADD_LIKE.ADD_LIKE_INITLIZATION,
    });
    apiCall(
      ADD_LIKE_URL,
      { post_id: feedId },
      (res: any) => {
        dispatch(addLikeSuccess(feedId, fromPage));
      },
      (err: any) => {
        dispatch(addLikeError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const addLikeSuccess =
  (
    post_id: any,
    fromPage: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    switch (fromPage) {
      case "my-profile":
        dispatch({
          type: USER_FEEDS.FEED_LIKE_MY_PROFILE,
          payload: post_id,
        });
        break;
      // case "favourite":
      //   dispatch({
      //     type: USER_FEEDS.FEED_LIKE_FAVOURITE,
      //     payload: post_id,
      //   });
      //   dispatch({
      //     type: USER_FEEDS.FEED_LIKE,
      //     payload: post_id,
      //   });
      //   break;
      default:
        dispatch({
          type: USER_FEEDS.FEED_LIKE_FAVOURITE,
          payload: post_id,
        });
        dispatch({
          type: USER_FEEDS.FEED_LIKE,
          payload: post_id,
        });
    }
  };

export const addLikeError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_FEEDS.FEED_LIKE_ERROR,
    });
    // toast.error(err.message)
  };
// =====================================================Favourite===================================================
export const addToFavourite =
  (postId: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(addToFavouriteInit(postId));
  };

export const addToFavouriteInit =
  (postId: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: ADD_TO_FAVOURITE.ADD_TO_FAVOURITE_INITLIZATION,
    });
    apiCall(
      ADD_TO_FAVOURITE_URL,
      { post_id: postId },
      (res: any) => {
        dispatch(addToFavouriteSuccess(res, postId));
      },
      (err: any) => {
        dispatch(addToFavouriteError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const addToFavouriteSuccess =
  (
    res: any,
    postId: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: ADD_TO_FAVOURITE.ADD_TO_FAVOURITE_SUCCESS,
      payload: res.data,
    });

    dispatch({
      type: USER_DASHBOARD_FEEDS.FAVORITE_POST_ADD,
      payload: postId,
    });
  };

export const addToFavouriteError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: ADD_TO_FAVOURITE.ADD_TO_FAVOURITE_ERORR,
    });
    // toast.error(err.message)
  };
export const removeFromFavourite =
  (postId: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(removeFromFavouriteInit(postId));
  };

export const removeFromFavouriteInit =
  (postId: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: REMOVE_FROM_FAVOURITE.REMOVE_FROM_FAVOURITE_INITLIZATION,
    });
    apiCall(
      REMOVE_FAVOURITE_FAVOURITE_URL,
      { post_id: postId },
      (res: any) => {
        dispatch(removeFromFavouriteSuccess(res, postId));
      },
      (err: any) => {
        dispatch(removeFromFavouriteError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const removeFromFavouriteSuccess =
  (
    res: any,
    postid: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: REMOVE_FROM_FAVOURITE.REMOVE_FROM_FAVOURITE_SUCCESS,
      payload: res.data,
    });

    dispatch({
      type: USER_DASHBOARD_FEEDS.FAVORITE_POST_REMOVE,
      payload: postid,
    });
  };

export const removeFromFavouriteError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: REMOVE_FROM_FAVOURITE.REMOVE_FROM_FAVOURITE_ERORR,
    });
    // toast.error(err.message)
  };
export const favouriteList =
  (page = 1): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(favouriteListInit(page));
  };

export const favouriteListInit =
  (page: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: FAVOURITE_LIST.FAVOURITE_LIST_INITLIZATION,
    });
    apiCall(
      FAVOURITE_LIST_URL,
      { page, sort: "DESC" },
      (res: any) => {
        dispatch(favouriteListSuccess(res));
      },
      (err: any) => {
        dispatch(favouriteListError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const favouriteListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: FAVOURITE_LIST.FAVOURITE_LIST_SUCCESS,
      payload: res.data,
    });
  };
export const favouriteListError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: FAVOURITE_LIST.FAVOURITE_LIST_ERORR,
    });
    // toast.error(err.message)
  };

// HIDE LIST
export const addToHide =
  (
    postId: any,
    user_id: any
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(addToHideInit(postId, user_id));
  };

export const addToHideInit =
  (
    postId: any,
    user_id: any
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    apiCall(
      HIDE_POST_URL,
      { post_id: postId, to_user_id: user_id },
      (res: any) => {
        dispatch(addToHideSuccess(res, postId));
      },
      (err: any) => {
        dispatch(addToHideError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const addToHideSuccess =
  (
    res: any,
    postId: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_DASHBOARD_FEEDS.HIDE_POST_ADD,
    });
  };

export const addToHideError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    // toast.error(err.message)
  };
export const removeFromHide =
  (
    postId: any,
    userId: any
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(removeFromHideInit(postId, userId));
  };

export const removeFromHideInit =
  (
    postId: any,
    userId: any
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_DASHBOARD_FEEDS.HIDE_POST_REMOVE,
    });
    apiCall(
      UNHIDE_POST_URL,
      { post_id: postId },
      (res: any) => {
        dispatch(removeFromHideSuccess(res, postId));
      },
      (err: any) => {
        dispatch(removeFromHideError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const removeFromHideSuccess =
  (
    res: any,
    postid: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_DASHBOARD_FEEDS.HIDE_POST_REMOVE,
      payload: postid,
    });
  };

export const removeFromHideError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    // toast.error(err.message)
  };

// Report Post

export const reportPost =
  (
    postId: any,
    user_id: any
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch(reportPostInit(postId, user_id));
  };

export const reportPostInit =
  (
    postId: any,
    user_id: any
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    apiCall(
      REPORT_POST_URL,
      { post_id: postId, to_user_id: user_id },
      (res: any) => {
        dispatch(reportPostSuccess(res, postId));
      },
      (err: any) => {
        dispatch(reportPostError(err));
      },
      METHOD.POST,
      {}
    );
  };

export const reportPostSuccess =
  (
    res: any,
    postId: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: USER_DASHBOARD_FEEDS.HIDE_POST_ADD,
    });
  };

export const reportPostError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    // toast.error(err.message)
  };
