import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeletePopup = ({show, handleClose, text, handleSubscription,renew}: any) => {
    return (
        <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className='delete-popup'
      >
        <Modal.Body>
         <div className='delete-popup-content'>
         <h6>{text ? text : 'Are you sure you want to delete this?'}</h6>
         <div className='delete-btn'>
            <Button onClick={handleSubscription}>{renew?"Renew":"Unsubscribe"}</Button>
            <Button onClick={handleClose}>Cancel</Button>
         </div>
         </div>
        </Modal.Body>
      </Modal>
    )
}
export default DeletePopup