import { useEffect, useRef, useState } from 'react';
import { METHOD, apiCall } from '../service/baseApiCall';
// import { CREATE_TOKEN_URL, NODE_CREATE_TOKEN } from '../redux/ApiEndPoints';
// import AgoraRTC from "agora-rtc-sdk";
// import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../redux/Actions';
// import axios from 'axios';
// import goLiveReducer from '../redux/reducers/goLiveReducer';
// import { exitLiveStreaming, joinLiveStreaming, setSubscriberToken } from '../redux/action';
import Chat from './models/Chat';
import { Modal } from 'react-bootstrap';
import { AppDispatch, dispatch } from '../redux';
// import { ComputeOptimizer } from 'aws-sdk';
// import ChatAudience from './models/ChatAudience';
import StartStreaming from './StartStreaming';
import { ReactComponent as ViewIcon } from '../assets/images/icons/eyeIcon.svg';
import { ReactComponent as GiftIcon } from '../assets/images/icons/giftIcon.svg';
import { ReactComponent as Dollar } from '../assets/images/icons/dollarIcon.svg';
import { ReactComponent as CloseChat } from '../assets/images/icons/close-chat.svg';
import { ReactComponent as VerticleLine } from '../assets/images/icons/verticalLine.svg';
import { S3_BASE_URL } from '../service/aws/config';
import Avatar from 'react-avatar';
import { ReactComponent as VerifyIcon } from '../assets/images/icons/verifyIcon.svg';
import { ReactComponent as Coin } from '../assets/images/icons/coin.svg';
import { toast, ToastContainer } from 'react-toastify';
import { LIVE_USER_DONATE } from '../redux/ApiEndPoints';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { getStickers, sendStickers } from '../redux/action';

const getrandomno = (len: Number) => {
  var p = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let random = [...Array(len)].reduce(
    (a) => a + p[~~(Math.random() * p.length)],
    ''
  );
  return random;
};

export const JoinStreaming = (props: any) => {
  const [channelId, setChannelId] = useState('');
  const [showStreaming, setShowStreaming] = useState(true);
  // const [chatToken, setChatToken] = useState('');
  const [randomid] = useState(getrandomno(10));
  // const [streamOn, setStreamOn] = useState(false);
  const [forceToLeaveStreaming, setForceToLeaveStreaming] = useState<number>(0);
  const [screenToggle, setScreenToggle] = useState<boolean>(false);
  const [donetAmountStore, setDonetAmountStore] = useState<any>('');
  const [isTipPopOver, setIsTipPopOver] = useState<boolean>(false);
  const [isGiftPopOver, setIsGiftPopOver] = useState<boolean>(false);
  const [tipValue, setTipValue] = useState<any>(100);
  const [emptyErr, setEmptyErr] = useState<boolean>(false);
  const [loadingDonate, setLoadingDonate] = useState<boolean>(false);
  const [donetTipMessage, setDonetTipMessage] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<string>(
    'LIVE_MESSAGE_TYPE_TEXT'
  );
  const [member, setMember] = useState<number>(0)
  const [balanceError, setBalanceError] = useState<string>('')
  const [currentSticker, setCurrentSticker] = useState({})

  // const dispatch = useDispatch<AppDispatch>();
  const userDetail: any = useSelector(
    (state: Store) => state.userDataReducer.userDetail
  );

  const joinedLiveStreaming = useSelector(
    (state: Store) => state.goLiveReducer.liveStreamJoined
  );
  const [videocall, setVideocall] = useState(false);
  const profilePhoto: string = useSelector(
    (state: Store) => state.userDataReducer.profilePhoto
  );

  const fullScreenHandler = useFullScreenHandle();

  const token = useSelector(
    (state: Store) => state.goLiveReducer.subscriberToken
  );
  const { stickers, isStickerSent }: any = useSelector((state: Store) => state.chatDataReducer);

  const option = {
    appID: '94e719e0d94c4fe3a6abc05646d3bff3',
    channel: channelId,
    uid: null,
    token: null,
    key: 'e128ae63b6ac4e5b9c51887eafbf89b2',
    secret: '68f6b879ae8145f3ba01f3e11e287b67',
  };

  const handleMessageSubmitRef = useRef(null);

  useEffect(() => {
    if (joinedLiveStreaming.stream_id === undefined) return;

    setChannelId(joinedLiveStreaming.stream_id);
    setVideocall(true);
    if (
      joinedLiveStreaming.stream_id &&
      (channelId !== undefined || channelId !== '')
    ) {
      // getToken();
    }
    dispatch(getStickers())
  }, [joinedLiveStreaming, channelId]);

  const sendTip = async () => {
    if (!tipValue) {
      setEmptyErr(true);
    } else if (userDetail?.wallet_balance < tipValue) {
      toast.error('insufficient balance');
      // setBalanceError("insufficient balance") // pending [have to manage using toastr]
    } else {
      setLoadingDonate(true);
      try {
        apiCall(
          LIVE_USER_DONATE,
          { amount: Number(tipValue), user_id: joinedLiveStreaming?.user?.id },
          () => {
            setLoadingDonate(false);
            setIsTipPopOver(false);
            setMessageType('LIVE_MESSAGE_TYPE_TEXT');
            toast.success('Donated Successfullly');
            setDonetTipMessage(true);
            // @ts-ignore
            // handleMessageSubmitRef && handleMessageSubmitRef.current.props.click(); pending
          },
          () => {
            setLoadingDonate(false);
            toast.error('Donation Failed');
            setLoadingDonate(false);
          },
          METHOD.POST
        );
      } catch (error) {
        toast.error('Something went wrong');
        setLoadingDonate(false);
      }
    }
  };

  const sentSticker = async (sticker: any) => {
    setIsGiftPopOver(false)
    if (userDetail?.wallet_balance < sticker.price) { toast.error('insufficient balance'); return }
    setCurrentSticker(sticker)
    dispatch(sendStickers({ sticker_id: sticker.id, to_user_id: userDetail?.id }))
  }

  const tipOnChangerHandler = (event: any) => {
    event.preventDefault();
    let tipValue = event.target.value;
    if (tipValue.length > 5) return;
    if (tipValue === '') {
      setEmptyErr(true);
      setTipValue(+tipValue);
    } else setTipValue(+tipValue);
    setEmptyErr(false);
    setBalanceError('');
  };

  const getTipPopOver = () => {
    return (
      <div className="popover-container">
        <div className="d-flex justify-content-center py-2">
          <h5 className="font-weight-bold mb-0" style={{ color: 'black' }}>
            Donate
          </h5>
        </div>
        <hr className="m-0 p-0 weigth-bold" style={{ color: 'black' }} />
        <div className="px-4 py-2 d-flex justify-content-center flex-column">
          <Avatar
            className="m-auto"
            round={true}
            src={''}
            alt="profile"
            size="45"
            name={
              joinedLiveStreaming?.user?.first_name +
              ' ' +
              joinedLiveStreaming?.user?.last_name
            }
            maxInitials={2}
          />
          <h5 className="tip-profile-title weigth-bold">
            {joinedLiveStreaming?.user?.first_name}{' '}
            {joinedLiveStreaming?.user?.last_name}
            {joinedLiveStreaming?.user?.user_verified && (
              <VerifyIcon className="mx-1" height={'18'} width={'18'} />
            )}
          </h5>
          <h5 className="tip-profile-userd-name">
            <span>@{joinedLiveStreaming?.user?.username}</span>
          </h5>
          <div className="d-flex justify-content-end flex-wrap mt-2 m-auto">
            <div className="tip-display">
              <Coin className="mr-1" />
              {tipValue}
            </div>
          </div>
          <div
            className="tip-container mt-2"
            onClick={(event: any) => {
              let tipValue = event.target.innerHTML;
              setTipValue(+tipValue);
              setEmptyErr(false);
            }}
          >
            {[100, 200, 500, 1000].map((tip: number) => {
              return (
                <div
                  className={tip === tipValue ? 'tip-box-active' : 'tip-box'}
                // onClick={()=>setDonetAmountStore(tip)}
                >
                  {tip}
                </div>
              );
            })}
          </div>
          <div className="mt-2" onChange={tipOnChangerHandler}>
            <input
              type="number"
              value={tipValue == 0 ? '' : tipValue}
              className="tip-custom-input"
              placeholder="Custom amount"
              onKeyDown={e => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
            />
            {emptyErr && (
              <div className="tip-error">Please enter a valid tip</div>
            )}
            {balanceError.length && (
              <div className="tip-error">{balanceError}</div>
            )}
          </div>
          <div className="m-auto mt-2" onClick={sendTip}>
            <button className="custom-tip-btn btn" disabled={loadingDonate}>
              Tip
            </button>
          </div>
        </div>
      </div>
    );
  };

  const getSendStickerPopOver = () => {
    return (
      <div className="gift-popover-container">
        <div className="container" style={{ marginTop: "50px", paddingBottom: "0px" }}>
          <div className="row">
            {!!stickers?.data && stickers?.data.map((sticker: any) => (
              <div key={sticker.id} className="col-sm-4 col-md-6 col-lg-4" onClick={() => sentSticker(sticker)}>
                <div className="card mb-3 border-0">
                  <div className="card-body p-0">
                    {/* <h5 className="card-title">{sticker.title}</h5> */}
                    <img className="card-img-top" src={S3_BASE_URL + sticker.sticker_url} alt="Sticker Image" height={"53px"} />
                    <div className='d-flex'>
                      <Coin height={'16px'} />
                      <p className=" ml-.5 card-text" style={{ color: 'black', fontSize: "11px" }}>{sticker.price}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  function toggleFullScreen() {
    if (screenToggle) {
      setScreenToggle(false);
      fullScreenHandler.exit();
    } else {
      setScreenToggle(true);
      fullScreenHandler.enter();
    }
  }

  return (
    <>
      {joinedLiveStreaming.stream_id && (
        <Modal
          className={`join-modal ${!screenToggle ? 'upload-image-popup' : ''
            } golive-modal p-0`}
          fullscreen={true}
          centered
          show={showStreaming}
          keyboard={false}
          backdrop="static"
          style={{ maxWidth: '100%' }}
          dimmer={'blurring'}
        >
          <Modal.Body className="p-0 position-relative">
            <FullScreen handle={fullScreenHandler}>
              <div className="row m-0 d-flex h-100">
                <div
                  className={`col-12 pl-0 ${screenToggle
                    ? 'col-md-12 col-xl-12 p-0'
                    : 'col-md-9 col-xl-9'
                    } cmt-img-wrapper`}
                >
                  {videocall ? (
                    <StartStreaming
                      channelId={channelId}
                      videocall={videocall}
                      setStreamCheck={props.setStreamCheck}
                      id={userDetail?.id?.toString()}
                      role="audience"
                      forceToLeaveStreaming={forceToLeaveStreaming}
                      setScreenToggle={setScreenToggle}
                      screenToggle={screenToggle}
                      toggleFullScreen={toggleFullScreen}
                    />
                  ) : (
                    <p>Please Wait</p>
                  )}
                </div>
                <div
                  className={`col-12 col-md-3 col-xl-3 px-0 d-flex flex-column ${screenToggle ? 'd-none' : ''
                    }`}
                >
                  {/* <button type='button' className='exit-btn' data-bs-dismiss='modal' onClick={leaveChannel}>
                  <img alt='' src='assets/images/icons/logout.svg' className='me-1' />
                  Exit Live Stream
                </button> */}
                  <div className="h-100 d-flex flex-column pr-3">
                    <button
                      type="button"
                      className="exit-btn mt-1"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setForceToLeaveStreaming(1);
                      }}
                    >
                      <img
                        alt=""
                        src="assets/images/icons/logout.svg"
                        className="me-1"
                      />
                      Exit Live Stream
                    </button>
                    {!screenToggle && <CloseChat className='close-chat' style={{ top: "8px" }} onClick={() => setScreenToggle(!screenToggle)} />}
                    {/* <p>channelId : {channelId}</p>   */}
                    {/* <div className='modal-body d-flex flex-column'>
                    {token.rtmToken && <ChatAudience appId={option.appID} channelId={channelId} token={token.rtmToken} userId={userDetail?.id.toString()} />}
                  </div> */}
                    <div className="audience-btn-container">
                      <div className="d-flex align-items-center">
                        <ViewIcon className="mr-1" />
                        {member}
                      </div>
                      <VerticleLine />
                      <div className="d-flex align-items-center position-relative">
                        <div
                          onClick={() => {
                            setIsTipPopOver(!isTipPopOver);
                            setTipValue(100);
                            setMessageType('LIVE_MESSAGE_TYPE_TIP');
                          }}
                        >
                          <Dollar className="mr-1" /> Send Tip
                        </div>
                        {isTipPopOver && getTipPopOver()}
                      </div>
                      <VerticleLine />
                      <div className="d-flex align-items-center position-relative">
                        <div
                          onClick={() => {
                            setIsGiftPopOver(!isGiftPopOver);
                            setMessageType('LIVE_MESSAGE_TYPE_STICKER');
                          }}
                        >
                          <GiftIcon className="mr-1" />
                          {'Gifts'}
                        </div>
                      </div>
                      {isGiftPopOver && getSendStickerPopOver()}
                    </div>

                    {/* <div className='live-chat-container h-100'>
                    <div className='d-flex justify-content-center py-2'>
                      <div>Live Comments</div>
                    </div>
                    <hr />
                  </div> */}
                    <Chat
                      appId={option.appID}
                      channelId={channelId}
                      token={token.rtmToken}
                      userId={userDetail?.id?.toString()}
                      profilePic={S3_BASE_URL + profilePhoto}
                      userName={
                        userDetail.first_name + ' ' + userDetail.last_name
                      }
                      handleMessageSubmitRef={handleMessageSubmitRef}
                      messageType={messageType}
                      message={`Donated ${tipValue} coins!`}
                      setTipValue={setTipValue}
                      tipValue={tipValue}
                      setDonetTipMessage={setDonetTipMessage}
                      donetTipMessage={donetTipMessage}
                      setForceToLeaveStreaming={setForceToLeaveStreaming}
                      setMember={setMember}
                      currentSticker={currentSticker}
                      setCurrentSticker={setCurrentSticker}
                    />
                  </div>
                </div>
              </div>
            </FullScreen>
          </Modal.Body>
          {(isTipPopOver || isGiftPopOver) && (
            <div className="popover-background position-absolute">
              <div
                onClick={() => {
                  setIsTipPopOver(false);
                  setIsGiftPopOver(false)
                  setMessageType('LIVE_MESSAGE_TYPE_TEXT');
                }}
                style={{
                  width: '100vw',
                  height: '100vh',
                  position: 'absolute',
                }}
              />
            </div>
          )}
          <ToastContainer />
        </Modal>
      )}
    </>
  );
};
