import React, { useEffect, useState } from 'react';
import PostFeed from './PostFeed';
import Suggestions from './Suggestions';
import TrendingFeeds from './TrendingFeeds';
import UserImg from '../assets/images/imgs/user-round.svg';
import BackImage from '../assets/images/bg/bg-img2.svg';
import FacebookeIcon from '../assets/images/icons/ico_facebook.svg';
import InstagramIcon from '../assets/images/icons/ico_instagram.svg';
import TwitterIcon from '../assets/images/icons/ico_twitter.svg';
import LinkdinIcon from '../assets/images/icons/ico_linkedin.svg';
import LinkIcon from '../assets/images/icons/ico_link.svg';
import WebIcon from '../assets/images/icons/internet-svgrepo-com.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../redux/Actions';
import { AppDispatch } from '../redux';
import {
  followUser,
  userDetail,
  userFeed,
  userProfileData,
} from '../redux/action';
import { useParams } from 'react-router-dom';
import { getToken } from '../utils/utility';
import { S3_BASE_URL } from '../service/aws/config';
import { useNavigate } from 'react-router-dom';
import { createImageFromInitials, getRandomColor } from '../utils/createImage';
import { ReactComponent as VerifyIcon } from '../assets/images/icons/verifyIcon.svg';
import Followers from './models/Follower';
import Following from './models/Following';
import { USER_FEEDS } from '../redux/constants/action-types';
import { useTranslation } from 'react-i18next';

export default function MyProfile() {
  const dispatch = useDispatch<AppDispatch>();
  let { id }: any = useParams();
  const { t } = useTranslation();
  const navigate: any = useNavigate();
  const { hasMoreFeed, user_packages, userFeedList, loading }: any =
    useSelector((state: Store) => state?.userDataReducer);
  const userDetails: any = useSelector(
    (state: Store) => state?.userDataReducer?.userDetail
  );
  // const userFeedList: any = useSelector(
  //   (state: Store) => state.userDataReducer.userFeedList
  // );
  // const loading: any = useSelector(
  //   (state: Store) => state.userDataReducer.loading
  // );
  const [page, setPage] = useState(1);
  const [feedList, setFeedList] = useState<any>([]);
  const [isOpenFollowerModal, setIsOpenFollowerModal] =
    useState<boolean>(false);
  const [isOpenFollowingModal, setIsOpenFollowingModal] =
    useState<boolean>(false);
  const userId = getToken('userId');

  useEffect(() => {
    // if(feedList.length<=0){
    // setFeedList(userFeedListData)
    setFeedList((previous: any, newValue: any) => {
      if (previous !== newValue) {
        return [...feedList, ...userFeedList];
      }
    });

    // }
  }, [userFeedList]);
  useEffect(() => {
    dispatch(userDetail(Number(userId)));
    dispatch(userProfileData(Number(userId)));
    // dispatch(userFeed(page, userId));
  }, [id]);

  useEffect(() => {
    if (page == 1) {
      dispatch({ type: USER_FEEDS.USER_FEEDS_INIT_CLEAR });
    }
    dispatch(userFeed(page, userId));
  }, [page]);

  return (
    <div className="other-profile-container">
      <div className="profile-content box-container my-profile-user-details-background">
        <div
          className="profile-bg"
          style={{
            backgroundImage: `url(${userDetails?.cover_photo
                ? S3_BASE_URL + userDetails?.cover_photo
                : BackImage
              })`,
          }}
        ></div>
        <div className="profile-box text-center">
          <div className="profile-logo">
            <img
              //  src={userDetails?.profile_photo?S3_BASE_URL+userDetails?.profile_photo:UserImg}
              src={
                userDetails?.profile_photo
                  ? S3_BASE_URL + userDetails?.profile_photo
                  : createImageFromInitials(
                    500,
                    userDetails?.first_name + ' ' + userDetails?.last_name,
                    getRandomColor()
                  )
              }
              className="img-fluid w-100"
              style={{ height: '126px', borderRadius: '50%' }}
              alt="user"
            />
          </div>
          <h3 className="profile-title">
            {userDetails?.first_name} {userDetails?.last_name}
            {userDetails?.user_verified && (
              <VerifyIcon className="mx-1" height={'25'} width={'25'} />
            )}{' '}
            <span>@{userDetails?.username}</span>
          </h3>
          <p className="profile-description">{userDetails?.bio}</p>
          {/* <a href={userDetails?.other_profile_link} className="profile-link">
            {userDetails?.other_profile_link}
          </a> */}
          {userDetails?.website_link && (
            <a
              className="website-link-user-profile"
              target="_blank"
              href={
                userDetails?.website_link?.includes('http')
                  ? userDetails?.website_link
                  : `https://${userDetails?.website_link}`
              }
            >
              {userDetails?.website_link}
            </a>
          )}
          <div className="social-link">
            <ul>
              {userDetails?.facebook_link && (
                <li className="social-item">
                  <a
                    target="_blank"
                    href={
                      userDetails?.facebook_link?.includes('http')
                        ? userDetails?.facebook_link
                        : `https://${userDetails?.facebook_link}`
                    }
                  >
                    <img src={FacebookeIcon} className="img-fluid" alt="icon" />
                  </a>
                </li>
              )}
              {userDetails?.instagram_link && (
                <li className="social-item">
                  <a
                    target="_blank"
                    href={
                      userDetails?.instagram_link?.includes('http')
                        ? userDetails?.instagram_link
                        : `https://${userDetails?.instagram_link}`
                    }
                  >
                    <img src={InstagramIcon} className="img-fluid" alt="icon" />
                  </a>
                </li>
              )}
              {userDetails?.twitter_link && (
                <li className="social-item">
                  <a
                    target="_blank"
                    href={
                      userDetails?.twitter_link?.includes('http')
                        ? userDetails?.twitter_link
                        : `https://${userDetails?.twitter_link}`
                    }
                  >
                    <img src={TwitterIcon} className="img-fluid" alt="icon" />
                  </a>
                </li>
              )}

              {userDetails?.other_profile_link && (
                <li className="social-item">
                  <a
                    target="_blank"
                    href={
                      userDetails?.other_profile_link?.includes('http')
                        ? userDetails?.other_profile_link
                        : `https://${userDetails?.other_profile_link}`
                    }
                  >
                    <img src={LinkIcon} className="img-fluid" alt="icon" />
                  </a>
                </li>
              )}
            </ul>
          </div>

          <div className="user-follow-details profile-follow d-flex align-items-center justify-content-center">
            <div className="follow-number text-center">
              <h5>{userDetails?.posts_count ? userDetails?.posts_count : 0}</h5>
              <span>{t('sidebar.post')}</span>
            </div>
            <div
              className="follow-number text-center"
              onClick={() => setIsOpenFollowerModal(true)}
            >
              <h5>
                {userDetails?.followers_count
                  ? userDetails?.followers_count
                  : 0}
              </h5>
              {/* <button  className="nav-link active"
                id="followers"
                data-bs-toggle="tab"
                data-bs-target="#followers"
                type="button"
                role="tab"
                aria-controls="followers"
                aria-selected="true"
>Followers</button> */}

              <span>{t('sidebar.followers')}</span>
            </div>
            <div
              className="follow-number text-center"
              onClick={() => setIsOpenFollowingModal(true)}
            >
              <h5>
                {userDetails?.following_count
                  ? userDetails?.following_count
                  : 0}
              </h5>
              <span>{t('sidebar.following')}</span>
            </div>
          </div>
          {/* {Number(id) !== Number(userId) && (
            <div className='follow-button d-flex align-items-center justify-content-center'>
              <button
                className='btn btn-follow'
                disabled={loading ? true : false}
                onClick={() => {
                  dispatch(followUser(id));
                }}
              >
                Follow
              </button>
              <button
                className='btn btn-message'
                onClick={() => {
                  navigate(`/messages`, { state: userDetails });
                }}
              >
                Message
              </button>
            </div>
          )} */}
        </div>
      </div>
      {/* {Number(id) !== Number(userId) && user_packages && (
        <div className='box-container subscription my-profile-user-details-background'>
          <h4 className='subscription-title'>Subscription</h4>
          <div className='subscription-pricelist'>
            <div className='price-content'>
              <div className='price-label'>{user_packages?.Monthly?.title}</div>
              <div className='price-number'>{`${"$" + user_packages?.Monthly?.price}/month`}</div>
            </div>
            <div className='price-content'>
              <div className='price-label'>{user_packages?.Annual?.title}</div>
              <div className='price-number'>{`${"$" + user_packages?.Annual?.price}/year`}</div>
            </div>
          </div>
          <div className='subscrib-button'>
            <button className='btn btn-subscribe'>{`Subscribe for Free 1 Day then ${"$" + user_packages?.Monthly?.price} per month`}</button>
          </div>
        </div>
      )} */}
      <div className="box-container">
        <div className="center-box">
          <PostFeed
            setPage={setPage}
            page={page}
            feedList={feedList}
            userFeedListData={userFeedList}
            fromPage={'my-profile'}
            hasMore={hasMoreFeed}
            onClick={() => {}}
          />
        </div>
      </div>
      <div className="sub-box-grid">
        <div className="center-box">
          {/* <!-- post --> */}
          {/* <PostFeed setPage={setPage} page={page} feedList={feedList} userFeedListData={userFeedList} /> */}
        </div>
        {/* <!-- right part --> */}
        <div className="right-box">
          {/* <Suggestions />
          <TrendingFeeds /> */}
        </div>
      </div>
      {isOpenFollowerModal && (
        <Followers
          isOpenFollowerModal={isOpenFollowerModal}
          setIsOpenFollowerModal={setIsOpenFollowerModal}
          userId={userId}
        />
      )}
      {isOpenFollowingModal && (
        <Following
          isOpenFollowingModal={isOpenFollowingModal}
          setIsOpenFollowingModal={setIsOpenFollowingModal}
          userId={userId}
        />
      )}
    </div>
  );
}
