import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Image1 from '../assets/images/imgs/img1.svg';
import { AppDispatch } from '../redux';
import { userTranding, userTrandingFeed } from '../redux/action';
import { Store } from '../redux/Actions';
import CommentModel from './models/CommentModel';
import { Link } from 'react-router-dom';
import { POST_TYPE } from '../constants/App-constant';
import { useTranslation } from 'react-i18next';
import { S3_BASE_URL } from '../service/aws/config';

export default function TrendingFeeds() {
  const [showPostComment, setShowPostComment] = useState(false);
  const [postId, setPostId] = useState();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userTrandingFeedList: any = useSelector(
    (state: Store) => state.userDataReducer.userTrandingFeedList
  );
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(userTrandingFeed());
  }, []);

  return (
    <div className="box-container">
      <h5 className="title text-center">{t('home.trending feeds')}</h5>
      <div className="trend-grid">
        {userTrandingFeedList &&
          userTrandingFeedList.length > 0 &&
          userTrandingFeedList.slice(0, 10).map((data: any) => {
            if (!data.post_type_link?.includes('https://')) {
              if (data.post_type_link)
                data.post_type_link = S3_BASE_URL + data.post_type_link;
            }
            return (
              <div className="trend-grid__item">
                {data?.post_type_link && data?.post_type_link.includes(',') ? (
                  data.post_type_link
                    .split(',')
                    .map((imageUrl: any, index: any) => (
                      <img
                        alt="thumb"
                        onClick={() => {
                          setShowPostComment(true);
                          setPostId(data?.id);
                        }}
                        src={
                          imageUrl.includes('https://')
                            ? imageUrl
                            : S3_BASE_URL + imageUrl
                        }
                        className="grid-img"
                      />
                    ))
                ) : (
                  <img
                    onClick={() => {
                      setShowPostComment(true);
                      setPostId(data?.id);
                    }}
                    src={
                      data?.post_type === POST_TYPE.POST_TYPE_IMAGE
                        ? data?.post_type_link
                        : S3_BASE_URL + data?.thumb_image || Image1
                    }
                    alt="thumb"
                  />
                  // src={
                  //   data?.post_type_link
                  //     ? baseS3 + data?.post_type_link
                  //     : Image1
                  // }
                )}
              </div>
            );
          })}
        {/* <div className="trend-grid__item"><img src={"https://songzter.s3.us-east-1.amazonaws.com/post/images/SNG20221207_115357_669_966.jpg"}/></div>
    <div className="trend-grid__item"><img src={Image1}/></div>
    <div className="trend-grid__item"><img src={Image1}/></div>
    <div className="trend-grid__item"><img src={Image1}/></div>
    <div className="trend-grid__item"><img src={Image1}/></div>
    <div className="trend-grid__item trend-grid__item--vert"><img src={Image1}/></div>
    <div className="trend-grid__item"><img src={Image1}/></div> */}
      </div>

      {/* <div className="trend-description">
        <span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam.
        </span>
      </div> */}
      <div className="follow-button text-center">
        <Link to={'/explore'}>
          <button type="submit" className="btn mt-3 btn-primary">
            {t('home.explore more')}
          </button>
        </Link>
      </div>
      <CommentModel
        showPostComment={showPostComment}
        setShowPostComment={setShowPostComment}
        postId={postId}
      ></CommentModel>
    </div>
  );
  {
  }
}
