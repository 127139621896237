import PostFeed from "../components/PostFeed";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { AppDispatch } from "../redux";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getStories,
  joinLiveStreaming,
  userDashboardFeed,
  userFeed,
  userProfileData,
  userDetail,
} from "../redux/action";
import { getToken } from "../utils/utility";
import { useNavigate } from "react-router-dom";
import CreatePost from "../components/CreatePost";
import { Store } from "../redux/Actions";
import add from "../assets/images/icons/ico_add.svg";
import arrow from "../assets/images/icons/right-arrow.svg";
import CreateStory from "../components/models/CreateStory";
import ViewStoryModal from "../components/models/ViewStoryModal";
import Loader from "../components/Loader";
import { boolean } from "yup";
import { JoinStreaming } from "../components/JoinStreaming";
import { USER_DASHBOARD_FEEDS } from "../redux/constants/action-types";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import Avatar from "react-avatar";
import { ReactComponent as VerifyIcon } from '../assets/images/icons/verifyIcon.svg';
import { ReactComponent as Coin } from '../assets/images/icons/coin.svg';
import { JOIN_LIVE_STREAM, LIVE_USER_DONATE } from "../redux/ApiEndPoints";
import { METHOD, apiCall } from "../service/baseApiCall";
import { toast } from "react-toastify";

export type Story = {
  user: { last_name: string; first_name: string; profile_photo: string };
  story_image: Array<{ story_image: string; created_at: string }>;
};

export default function Home() {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [streamCheck, setStreamCheck] = useState(false);
  const [page, setPage] = useState(1);
  const [feedList, setFeedList] = useState<any>([]);
  const [deleteFeedDependenncy, setdeleteFeedDependenncy] = useState<any>([]);
  const isLoggedIn = getToken('authToken') ? true : false;
  const [createStory, setCreatestory] = useState(false);
  const [createStoryDep, setCreateStoryDep] = useState<boolean>(false);
  const [storyImages, setStoryImages] = useState<Story>();
  const [showStory, setShowStory] = useState<boolean>(false);
  const [selectedStory, setSelectedStory] = useState<number>(-1);
  const [paidLiveModal, setPaidLiveModal] = useState<any>({ streamData: {}, isModal: false, loading: false });

  const loggedInUser: any = useSelector(
    (state: Store) => state.userDataReducer.userDetail
  );
  const storiesList: any = useSelector(
    (state: Store) => state.userDataReducer.stories
  );

  if (isLoggedIn === false) {
    navigate('/login');
  }
  useEffect(() => {
    dispatch(getStories());
  }, [createStoryDep, createStory, showStory]);
  useEffect(() => {
    const userId = getToken('userId');
    dispatch(userDetail(Number(userId)));
    dispatch(userProfileData(Number(userId)));
    dispatch(getStories());
  }, []);
  const userFeedListData: any = useSelector(
    (state: Store) => state.userDataReducer.userDashboardFeedList
  );
  const hasMoreFeed: any = useSelector(
    (state: Store) => state.userDataReducer.hasMoreFeed
  );
  const { isLiked, isCommentCreated }: any = useSelector(
    (state: Store) => state.postDataReducer
  );
  const loading: any = useSelector(
    (state: Store) => state.userDataReducer.loading
  );
  const [play, setPlay] = useState(false);

  useEffect(() => {
    // if (page === 1) {
    //   dispatch({
    //     type: USER_DASHBOARD_FEEDS.CLEAR_USER_DASHBOARD_FEED,
    //     payload: [],
    //   });
    dispatch(userDashboardFeed(page));
    // }
    // feedUpdate()
  }, [page]);

  // useEffect(() => {
  //   dispatch(userDashboardFeed(page));
  //   // feedUpdate()
  // }, [deleteFeedDependenncy]);

  useEffect(() => {
    // if(feedList.length<=0){
    // setFeedList(userFeedListData)
    setFeedList((previous: any, newValue: any) => {
      if (previous !== newValue && userFeedListData) {
        return [...feedList, ...userFeedListData];
      }
    });

    // }
  }, [userFeedListData]);
  function SampleNextArrow(props: any) {
    const { style, onClick } = props;
    return (
      <div className={'next-arrow'} style={{ ...style, display: 'block', background: 'red' }} onClick={onClick}>
        <img src={arrow} alt='arrow' className='img-fluid' width='50px' />
      </div>
    );
  }

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
  };

  const handleStory = () => {
    setCreatestory(!createStory);
    dispatch(getStories());
  };

  const handleStoryClick = (story: Story, index: number) => {
    setStoryImages(story);
    setShowStory(true);
    setSelectedStory(index);
  };

  const handleLiveStreamClick = (channel: any, index: number) => {
    if (!channel.type) {
      setStreamCheck(true);
      dispatch(joinLiveStreaming(channel));
    } else {
      setPaidLiveModal({ ...paidLiveModal, streamData: channel, isModal: true })
    }
  };

  const sendStreamAmount = () => {
    setPaidLiveModal({ ...paidLiveModal, loading: true })
    try {
      apiCall(
        JOIN_LIVE_STREAM,
        { stream_id: Number(paidLiveModal.streamData?.stream_id), },
        () => {
          setPaidLiveModal({ ...paidLiveModal, loading: false, isModal: false })
          toast.success('Joined Successfullly');
          setStreamCheck(true)
          dispatch(joinLiveStreaming(paidLiveModal.streamData));
        },
        () => {
          setPaidLiveModal({ ...paidLiveModal, loading: false })
          toast.error('Some Error Occured');
        },
        METHOD.POST
      );
    } catch (error) {
      toast.error('Something went wrong');
      setPaidLiveModal({ ...paidLiveModal, loading: false })
    }
  }

  const getPaidLiveModalConfirmation = () => {
    const { streamData } = paidLiveModal
    return <Modal
      show={paidLiveModal.isModal}
      onHide={() => { setPaidLiveModal({ ...paidLiveModal, isModal: !paidLiveModal.isModal }) }}
      // backdrop="static"
      // keyboard={false}
      className={"live-stream-container"}
      centered
    >
      <Modal.Body>
        <div className="live-stream-pay-container">
          <div className="d-flex justify-content-center py-2 position-relative">
            <h5 className="font-weight-bold mb-0 p-2" style={{ color: 'black' }}>
              {t("home.paid live stream")}
            </h5>
            <button
              type="button"
              className="btn-close position-absolute"
              data-bs-dismiss="modal"
              style={{ right: "12px", top: "16px" }}
              onClick={() => { setPaidLiveModal({ ...paidLiveModal, isModal: false }) }}
            ></button>
          </div>
          <hr className="m-0 p-0 weigth-bold" style={{ color: 'black' }} />
          <div className="px-4 py-2 d-flex justify-content-center flex-column">
            <Avatar
              className="m-auto mb-2"
              round={true}
              src={''}
              alt="profile"
              size="45"
              name={
                streamData?.user?.first_name +
                ' ' +
                streamData?.user?.last_name
              }
              maxInitials={2}
            />
            <h5 className="pay-stream-profile-title weigth-bold">
              {streamData?.user?.first_name}{' '}
              {streamData?.user?.last_name}
              {streamData?.user?.user_verified && (
                <VerifyIcon className="mx-1" height={'18'} width={'18'} />
              )}
            </h5>
            <h5 className="pay-stream-profile-userd-name">
              <span>@{streamData?.user?.username}</span>
            </h5>
            <div className="d-flex justify-content-end flex-wrap my-3 m-auto">
              <div className="stream-amount-display">
                <Coin className="mr-1" />
                {streamData?.amount}
              </div>
            </div>
            <h5 className="pay-stream-profile-title">
              {streamData?.title}
            </h5>
            <div className="m-auto mt-4 mb-2" onClick={sendStreamAmount}>
              <button className="btn pay-stream-amonut-btn btn" disabled={paidLiveModal.loading}>
                Join
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  }

  return (
    <>
      <div className='center-box'>
        {/* <JoinStreaming /> */}
        {loading && page == 1 && <Loader/>}
        {streamCheck ? <JoinStreaming setStreamCheck={setStreamCheck} /> : ''}
        <div className='story-slider'>
          <div className='right-side-blur'></div>
          <div className='swiper swiper-slider'>
            <div className='swiper-wrapper'>
              <div className='swiper-slide'>
                <div className='story' onClick={handleStory}>
                  <div className='profile add-story'>
                    <div className='profile-add'>
                      <img src={add} alt='add' className='img-fluid' />
                    </div>
                  </div>
                  <div className='title'>{t("home.add story")}</div>
                </div>
                {(storiesList?.stories?.length > 0 || storiesList?.channels?.length > 0) && (
                  <Slider {...settings} slidesToShow={Math.min(storiesList?.stories?.length || 1, 6)}>
                    {storiesList?.stories?.map((story: Story, index: number) => (
                      <div key={`USER__STORY__${index}`} onClick={() => handleStoryClick(story, index)}>
                        <div className='swiper-slide'>
                          <Link to='/' className='story'>
                            <div className='profile add-story'>
                              {story?.user?.profile_photo ? (
                                <img src={`${process.env.REACT_APP_S3_BASE_URL}/${story?.user?.profile_photo}`} alt='' />
                              ) : (
                                <p style={{ margin: 0, fontWeight: 600 }}>
                                  {story?.user?.first_name?.[0]?.toUpperCase()}
                                  {story?.user?.last_name?.[0]?.toUpperCase()}
                                </p>
                              )}
                            </div>
                            <div className='title'>{story.user.first_name}</div>
                          </Link>
                        </div>
                      </div>
                    ))}
                    {Object.values(storiesList.channels).map((channel: any, index: number) => (
                      <div key={`USER__STORY__${index}`} onClick={() => handleLiveStreamClick(channel, index)}>
                        <div className='swiper-slide'>
                          <div className='story'>
                            <div className='profile new-live-user' style={channel.type ? { border: ' 0.725806px solid green', display: 'grid' } : { display: 'grid' }}>
                              {channel?.user?.profile_photo ? ( 
                                <>
                                  <img src={`${process.env.REACT_APP_S3_BASE_URL}/${channel?.user?.profile_photo}`} alt='' className='story-image-for-live' />
                                </>
                              ) : (
                                <p
                                  style={{
                                    margin: 0,
                                    fontWeight: 600,
                                    borderRadius: '50%',
                                    padding: '17px',
                                  }}
                                >
                                  {channel?.user?.first_name?.[0]?.toUpperCase()}
                                  {channel?.user?.last_name?.[0]?.toUpperCase()}
                                </p>
                              )}
                              <p className='story-user-live-logo' style={channel.type ? { backgroundColor: 'green' } : {}}>{channel.type ? '$LIVE' : "LIVE"}</p>
                            </div>
                            <div className='title'>{channel.user.first_name}</div>
                          </div>
                        </div>
                      </div>
                    ))}

                    {/* {storiesList?.channels && Array.isArray(storiesList.channels) && storiesList.channels.map(
                      (channel: any, index: number) => (
                        <div
                          key={`USER__STORY__${index}`}
                          onClick={() => handleLiveStreamClick(channel, index)}
                        >
                          <div className="swiper-slide">
                            <div className="story">
                              <div className="profile">
                                {channel?.user?.profile_photo ? (
                                  <img
                                    src={`${process.env.REACT_APP_S3_BASE_URL}/${channel?.user?.profile_photo}`}
                                    alt=""
                                  />
                                ) : (
                                  <p style={{ margin: 0, fontWeight: 600, border: 'solid 1px #3662ff', borderRadius: "50%", padding: "17px" }}>
                                    {channel?.user?.first_name?.[0]?.toUpperCase()}
                                    {channel?.user?.last_name?.[0]?.toUpperCase()}
                                  </p>
                                )}
                              </div>
                              <div className="title">{channel.user.first_name}</div>
                            </div>
                          </div>
                        </div>
                      )
                    )} */}
                  </Slider>
                )}
              </div>
            </div>
            {/* <div className="swiper-button-next"><img src="assets/images/icons/right-arrow.svg" className="img-fluid" width="50px"/></div> */}
            {/* <!-- <div className="swiper-button-prev"></div> --> */}
          </div>
        </div>
        {/* <!-- add post --> */}
        <CreatePost />
        <PostFeed
          setPage={setPage}
          page={page}
          deleteFeedDependenncy={deleteFeedDependenncy}
          setdeleteFeedDependenncy={setdeleteFeedDependenncy}
          feedList={feedList}
          userFeedListData={userFeedListData}
          hasMore={hasMoreFeed}
          onClick={() => console.log('')}
          fromPage={'home'}
        />
        <CreateStory show={createStory} handleClose={handleStory} createStoryDep={createStoryDep} setCreateStoryDep={setCreateStoryDep} />
        {/* <UploadedStory show={createStory} handleClose={handleStory} /> */}
      </div>
      {showStory && <ViewStoryModal show={showStory} story={storyImages} storiesList={storiesList} selectedStory={selectedStory} onClose={() => setShowStory(false)} />}
      {paidLiveModal && getPaidLiveModalConfirmation()}
    </>
  );
}
