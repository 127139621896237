import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CheckoutForm from "../CheckoutForm";


interface PayoutModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  // amountAddedByUser: number;
  packageId:number;
}

const PayoutModal: React.FC<PayoutModalProps> = ({ show, setShow , packageId }) => {
  const [comment, setComment] = useState("");
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      centered
      className="custom-modal comment-modal teaser-modal"
    >
      <Modal.Body>
        <CheckoutForm
         packageId = {packageId}
        />
      </Modal.Body>
    </Modal>
  );
};

export default PayoutModal;

