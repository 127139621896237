import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import imgIcon from '../assets/images/icons/imgIcon.svg';
import videoIcon from '../assets/images/icons/videoIcon.svg';
import mediaIcon from '../assets/images/icons/mediaIcon.svg';
import uploadimg from '../assets/images/icons/uploadIcon.svg';
import addimg from '../assets/images/icons/add.svg';
import uploadthumbnail from '../assets/images/icons/uploadIcon.svg';
import { MEDIA_SIZE } from '../constants/App-constant';

const UploadMediaPopup = ({
  setUploadPopup,
  uploadPopup,
  uploadFile,
  previewImage,
  preview,
  handlePreview,
  handleThumbnail,
  selectedThumbnail,
  selectedFile,
  mediaType,
  setMediaType,
  setPreview,
  setMediaTypePreview,
  mediaTypePreview,
  setAddMediaError,
  addMediaError,
  setMeadiPackageSizeError,
  meadiPackageSizeError,
  setThumbnailSizeError,
  thumbnailSizeError,
}: any) => {
  const [selectedFileError, setSelectedFileError] = useState<boolean>(false);
  const handleSaveFile = () => {
    if (selectedFile) {
      uploadFile(selectedFile, selectedThumbnail);
      setUploadPopup(false);
      setPreview('');
      setSelectedFileError(false);
    } else {
      setSelectedFileError(true);
    }
  };
  const handleMedia = (value: string) => {
    setMediaType(value);
  };
  const handleClose = () => {
    setUploadPopup(false);
    setPreview('');
    setAddMediaError('');
  };

  return (
    <Modal
      show={uploadPopup}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="upload-media-popup"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="upload-media-content">
          <div className="choose-option-list">
            <div
              className="chhose-type"
              onClick={() => {
                handleMedia('Image');
                setSelectedFileError(false);
              }}
            >
              <div
                className={`choose-box ${
                  mediaType === 'Image' ? 'active' : ''
                }`}
              >
                <img src={imgIcon} alt="imgIcon" />
              </div>
              <p>Image</p>
            </div>
            <div
              className="chhose-type"
              onClick={() => {
                handleMedia('Video');
                setSelectedFileError(false);
              }}
            >
              <div
                className={`choose-box ${
                  mediaType === 'Video' ? 'active' : ''
                }`}
              >
                <img src={videoIcon} alt="imgIcon" />
              </div>
              <p>Video</p>
            </div>
            {/* <div
              className="chhose-type"
              onClick={() => {
                handleMedia('Audio');
                setSelectedFileError(false);
              }}
            >
              <div
                className={`choose-box ${
                  mediaType === 'Audio' ? 'active' : ''
                }`}
              >
                <img src={mediaIcon} alt="imgIcon" />
              </div>
              <p>Audio</p>
            </div> */}
          </div>

          {preview && mediaType === mediaTypePreview ? (
            <>
              <div
                className={`preview-section upload-media-box ${
                  mediaType === 'Video'
                    ? 'video-media-box'
                    : mediaType === 'Audio'
                    ? 'audio-media-box'
                    : ''
                }`}
              >
                {mediaType === 'Image' && mediaTypePreview === 'Image' ? (
                  <img src={preview} alt="preview" />
                ) : mediaType === 'Video' && mediaTypePreview === 'Video' ? (
                  <video controls>
                    <source src={preview} type="video/mp4" />
                  </video>
                ) : mediaType === 'Audio' && mediaTypePreview === 'Audio' ? (
                  <audio controls>
                    <source src={preview} type="audio/mp3" />
                  </audio>
                ) : (
                  ''
                )}
                <div className="add-more">
                  <label htmlFor="uploadnew">
                    <img src={addimg} alt="addimg" />
                  </label>
                  <input
                    type="file"
                    id="uploadnew"
                    onChange={(e: any) => {
                      handlePreview(e.target.files[0], mediaType);
                      setSelectedFileError(false);
                    }}
                  />
                </div>
              </div>
              {(mediaType === 'Video' || mediaType === 'Audio') && (
                <div className="thumbnail">
                  <p>Thumbnail</p>
                  <div className="upload-thumb">
                    <label htmlFor="upload-thumb" className="btn">
                      <img src={uploadthumbnail} alt="img" />
                    </label>
                  </div>
                  <p
                    className={`targeted-thumbnail-file ${
                      thumbnailSizeError && 'thumbnail-image-erorr'
                    }`}
                  >
                    {!thumbnailSizeError
                      ? selectedThumbnail?.name
                      : `Please Select less then ${
                          MEDIA_SIZE.IMAGE_SIZE / 1024
                        } MB Image file`}
                  </p>
                  <input
                    type="file"
                    hidden={true}
                    onChange={handleThumbnail}
                    id="upload-thumb"
                    placeholder=""
                  />
                  {/* <h6>upload thumbnail</h6> */}
                </div>
              )}
            </>
          ) : (
            <>
              <label
                htmlFor="upload-img"
                className={`upload-media-box ${
                  mediaType === 'Video'
                    ? 'video-media-box'
                    : mediaType === 'Audio'
                    ? 'audio-media-box'
                    : ''
                }`}
              >
                <input
                  type="file"
                  onChange={(e: any) => {
                    handlePreview(e.target.files[0], mediaType);
                    setSelectedFileError(false);
                  }}
                  value={[]}
                  id="upload-img"
                />
                <img src={uploadimg} alt="upload" />
                <p>Upload {mediaType}</p>
              </label>
              {meadiPackageSizeError && meadiPackageSizeError === mediaType ? (
                meadiPackageSizeError === 'Image' ? (
                  <p className="paid-packege-upload-file-error">
                    Please upload less then {MEDIA_SIZE.IMAGE_SIZE / 1024} MB
                    Image file
                  </p>
                ) : meadiPackageSizeError === 'Video' ? (
                  <p className="paid-packege-upload-file-error">
                    Please upload less then {MEDIA_SIZE.VIDEO_SIZE / 1024} MB
                    Video file
                  </p>
                ) : meadiPackageSizeError === 'Audio' ? (
                  <p className="paid-packege-upload-file-error">
                    Please upload less then {MEDIA_SIZE.AUDIO_SIZE / 1024} MB
                    Audio file
                  </p>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {addMediaError && addMediaError === mediaType ? (
                addMediaError === 'Image' ? (
                  <p className="paid-packege-upload-file-error">
                    Please Select Image file only
                  </p>
                ) : addMediaError === 'Video' ? (
                  <p className="paid-packege-upload-file-error">
                    Please Select Video file only
                  </p>
                ) : addMediaError === 'Audio' ? (
                  <p className="paid-packege-upload-file-error">
                    Please Select Audio file only
                  </p>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {selectedFileError && (
                <p className="paid-packege-upload-file-error ml-1 mt-3">
                  Please select media file
                </p>
              )}
              {mediaType === 'Audio' ? (
                <div className="thumbnail audio-title">
                  <p>Audio Title</p>
                  <input type="" placeholder="add title" />
                </div>
              ) : (
                ''
              )}
              {(mediaType === 'Video' || mediaType === 'Audio') && (
                <>
                  <div className="thumbnail">
                    <p>Thumbnail</p>
                    <div className="upload-thumb">
                      <label htmlFor="upload-thumb" className="btn">
                        <img src={uploadthumbnail} alt="img" />
                      </label>
                    </div>
                    <p
                      className={`targeted-thumbnail-file ${
                        thumbnailSizeError && 'thumbnail-image-erorr'
                      }`}
                    >
                      {!thumbnailSizeError
                        ? selectedThumbnail?.name
                        : `Please Select less then ${
                            MEDIA_SIZE.IMAGE_SIZE / 1024
                          } MB Image file`}
                    </p>
                    <input
                      type="file"
                      hidden={true}
                      onChange={handleThumbnail}
                      id="upload-thumb"
                      placeholder=""
                    />
                    {/* <h6>upload thumbnail</h6> */}
                  </div>
                  {/* {thumbnailSizeError && (
                    <p className="paid-packege-upload-file-error mt-2 ">
                      Please Select less then {MEDIA_SIZE.IMAGE_SIZE / 1024} MB
                      Image file
                    </p>
                  )} */}
                  {addMediaError === 'Thumbnail' && (
                    <p className="paid-packege-upload-file-error mt-2 ">
                      Please Select Image file only
                    </p>
                  )}
                </>
              )}
            </>
          )}
          <div className="add-btn">
            <Button className="changebtn" onClick={handleSaveFile}>
              Add
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default UploadMediaPopup;
