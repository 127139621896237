/* eslint-disable jsx-a11y/alt-text */
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import { AppDispatch } from '../redux';
import { forgotPassword } from '../redux/action';
import { Store } from '../redux/Actions';
import { setToken } from '../utils/utility';

export default function ForgetPassword() {
  const isForgotpasswordSuccess: boolean = useSelector(
    (state: Store) => state.userDataReducer.isForgotpasswordSuccessRedirect
  );
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    // validationSchema: LoginSchema,
    onSubmit: (values) => {
      setToken('email', values.email);
      dispatch(forgotPassword(values));
    },
  });
  const { errors, touched } = formik;
  useEffect(() => {
    if (isForgotpasswordSuccess) {
      navigate('/otp-verification');
      localStorage.setItem('otpSucessBack', JSON.stringify(false));
    }
  }, [isForgotpasswordSuccess]);
  return (
    <section className="login-section forgot-psw">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="login-box d-flex align-items-center justify-content-center flex-column">
              <div className="d-flex align-items-center justify-content-center">
                <div className="login-right ms-0">
                  <div className="login-form">
                    <h2 className="login-title">Forgot Password?</h2>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="form-group">
                        <input
                          type="email"
                          value={formik.values.email}
                          name="email"
                          className="form-control"
                          placeholder="Registered Email address "
                          onChange={formik.handleChange}
                        />
                        {errors.email && touched.email && (
                          <div className="text-danger mt-2">{errors.email}</div>
                        )}
                      </div>
                      <button type="submit" className="btn btn-login w-100">
                        Send
                      </button>
                    </form>
                    <div className="or-line">OR</div>
                    <p className="mb-0 signup-link text-center">
                      <a href="#" className="forgot-link">
                        <Link to={'/signup'}>Create New Account</Link>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              {/* <Footer/> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}
