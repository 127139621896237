import React from "react";

export default function Footer() {
  return (
      <div className="footer d-flex align-items-center" style = {{position:"absolute"}}>
                <div className="footer-select">
                  {/* <select
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option selected>English</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select> */}
                </div>
                <p className="mb-0 ms-sm-1">
                  © 2023 Fanshub from Cybertek Networks
                </p>
              </div> 
  );
}
