import React, { useEffect, useMemo, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import StripeCheckout from "react-stripe-checkout";
import { log } from "console";
import { InAppPayment } from "../redux/action";
import { dispatch } from "../redux";
import { useSelector } from "react-redux";
import { Store } from "../redux/Actions";

function useResponsiveFontSize() {
  const getFontSize = () => (window.innerWidth < 450 ? "16px" : "18px");
  const [fontSize, setFontSize] = useState(getFontSize);
 
  useEffect(() => {
    const onResize = () => {
      setFontSize(getFontSize());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return fontSize;
}
interface paymentFormDetail {
  // amountAddedByUser: number;
  packageId:number;
}

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const PaymentForm: React.FC<paymentFormDetail> = ({ packageId }) => {
  const stripe = useStripe() as Stripe | null;
  const elements = useElements() as StripeElements | null;
  const options = useOptions();
  const { isInAppLoading }: any = useSelector(
    (state: Store) => state.payoutReducer
  );
  const handleSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }


    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement)!,
    });
    if (paymentMethod) {
      const { id } = paymentMethod;
      const value: any = {
        purchaseData: [
          {
            packageId: packageId,
            trasactionId: id,
          },
        ],
      };
      dispatch(InAppPayment(value));
    }
  };

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <label>
        Card details
        <CardElement
          options={options}
          onReady={() => {
            console.log("CardElement [ready]");
          }}
          onChange={(event) => {
            console.log("CardElement [change]", event);
          }}
          onBlur={() => {
            console.log("CardElement [blur]");
          }}
          onFocus={() => {
            console.log("CardElement [focus]");
          }}
        />
      </label>
      <button type="submit" disabled={!stripe || !elements || isInAppLoading}>
        Pay
      </button>
    </form>

    //     <div>
    //     <StripeCheckout
    //       token={handlePayment}
    //       stripeKey="pk_test_51MwNEJLNYcEIq7mickqYPPpoJODd190w1OK700Emf3ehSEpZSnAZbUgBqujnQ03u8tVlJjPgREDvW9zqVfuoUcT300gsvLDIyO"
    //       amount={amountAddedByUser} // Amount in cents (e.g., $10.00)
    //       currency="USD"
    //     />
    //   </div>
  );
};

export default PaymentForm;
