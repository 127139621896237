/* eslint-disable no-unused-vars */
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
// import {configureStore} from "@red"

import rootReducer from "./reducers";
declare global {
    interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

// Configure store with reducers and create
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store:any = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)));

export const { dispatch } = store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export default store;
