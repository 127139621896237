/* eslint-disable import/no-anonymous-default-export */
import {
  ADD_LIKE,
  ADD_TO_FAVOURITE,
  CREATE_COMMENT,
  CREATE_TEXT_POST,
  FAVOURITE_LIST,
  FOLLOW_USER,
  GET_COMMENTS,
  GET_TAG_LIST,
  POST_BY_ID,
  REMOVE_FROM_FAVOURITE,
  UNFOLLOW_USER,
  USER_FEEDS,
} from '../constants/action-types';
import { PostData } from '../Actions';
import { ActionTypes } from '../action/userActions';

const initialState = {
  loading: false,
  postData: {},
  commentData: [],
  createTextPostSuccess: false,
  searchUserList: [],
  isCommentCreated: false,
  isLiked: false,
  isAddedTofavourite: false,
  isAddedToUnfavourite: false,
  isHide: false,
  isUnhide: false,
  favouriteList: [],
};
export default (state: PostData = initialState, action: ActionTypes) => {
  switch (action.type) {
    case POST_BY_ID.CLEAR_POST_DATA:
      return { ...state, postData: {}, commentData: [] };
    case POST_BY_ID.POST_BY_ID_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case POST_BY_ID.POST_BY_ID_SUCCESS:
      return {
        ...state,
        postData: action.payload,
      };
    case POST_BY_ID.POST_BY_ID_ERORR:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case USER_FEEDS.DELETE_POST:
      const deleteFeedData = state.favouriteList?.filter(
        (elem: any) => action.payload != elem.id
      );
      return {
        ...state,
        favouriteList: deleteFeedData ? deleteFeedData : state.favouriteList,
      };
    case USER_FEEDS.POST_HIDE_FAVOURITE:
      const hiddenFeeds = state.favouriteList.filter(
        (feed: any) => feed.id !== action.payload
      );
      return {
        ...state,
        favouriteList: hiddenFeeds,
        loading: false,
      };
    case GET_COMMENTS.GET_COMMENTS_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case GET_COMMENTS.GET_COMMENTS_SUCCESS:
      return {
        ...state,
        commentData: action.payload,
      };
    case GET_COMMENTS.GET_COMMENTS_ERORR:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case CREATE_TEXT_POST.CREATE_TEXT_POST_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case CREATE_TEXT_POST.CREATE_TEXT_POST_SUCCESS:
      return {
        ...state,
        createTextPostSuccess: true,
        loading: false,
      };
    case CREATE_TEXT_POST.CREATE_TEXT_POST_ERORR:
      return {
        ...state,
        ...action.payload,
        loading: false,
        createTextPostSuccess: false,
      };
    case GET_TAG_LIST.GET_TAG_LIST_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case GET_TAG_LIST.GET_TAG_LIST_SUCCESS:
      return {
        ...state,
        searchUserList: action.payload,
        loading: false,
      };
    case GET_TAG_LIST.GET_TAG_LIST_ERORR:
      return {
        ...state,
        ...action.payload,
        loading: false,
        searchUserList: [],
      };
    case CREATE_COMMENT.CREATE_COMMENT_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        isCommentCreated: false,
        loading: true,
      };
    case CREATE_COMMENT.CREATE_COMMENT_SUCCESS:
      return {
        ...state,
        isCommentCreated: true,
        loading: false,
      };
    case CREATE_COMMENT.CREATE_COMMENT_ERORR:
      return {
        ...state,
        ...action.payload,
        isCommentCreated: false,
        loading: false,
        searchUserList: [],
      };
    case CREATE_COMMENT.INCREASE_FAVOURITE_COMMENT_COUNT:
      const updatedPost = state.favouriteList.map((post: any) =>
        post.id !== action.payload.id
          ? post
          : { ...post, post_comment_counter: action.payload.count }
      );
      return {
        ...state,
        favouriteList: [...updatedPost],
      };
    case ADD_LIKE.ADD_LIKE_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        isLiked: false,
        loading: true,
      };
    case ADD_LIKE.ADD_LIKE_SUCCESS:
      return {
        ...state,
        isLiked: true,
        loading: false,
      };
    case ADD_LIKE.ADD_LIKE_ERORR:
      return {
        ...state,
        ...action.payload,
        isLiked: false,
        loading: false,
        searchUserList: [],
      };
    case ADD_TO_FAVOURITE.ADD_TO_FAVOURITE_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        isAddedTofavourite: false,
        loading: true,
      };
    case ADD_TO_FAVOURITE.ADD_TO_FAVOURITE_SUCCESS:
      return {
        ...state,
        isAddedTofavourite: true,
        loading: false,
      };
    case ADD_TO_FAVOURITE.ADD_TO_FAVOURITE_ERORR:
      return {
        ...state,
        ...action.payload,
        isAddedTofavourite: false,
        loading: false,
        // searchUserList:[]
      };
    case REMOVE_FROM_FAVOURITE.REMOVE_FROM_FAVOURITE_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        isAddedToUnfavourite: false,
        loading: true,
      };
    case REMOVE_FROM_FAVOURITE.REMOVE_FROM_FAVOURITE_SUCCESS:
      return {
        ...state,
        isAddedToUnfavourite: true,
        loading: false,
      };
    case REMOVE_FROM_FAVOURITE.REMOVE_FROM_FAVOURITE_ERORR:
      return {
        ...state,
        ...action.payload,
        isAddedToUnfavourite: false,
        loading: false,
        // searchUserList:[]
      };
    case FAVOURITE_LIST.FAVOURITE_LIST_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case FAVOURITE_LIST.FAVOURITE_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        favouriteList: action.payload,
        loading: false,
      };
    case FAVOURITE_LIST.FAVOURITE_LIST_ERORR:
      return {
        ...state,
        ...action.payload,
        favouriteList: [],
        loading: false,
      };
    case USER_FEEDS.FEED_LIKE_FAVOURITE:
      const updatedFeedsAtMyProfile = state.favouriteList.map((feed: any) =>
        feed.id === action.payload
          ? {
              ...feed,
              is_like: !feed.is_like,
              post_like_counter: Math.max(
                0,
                feed.is_like
                  ? feed.post_like_counter - 1
                  : feed.post_like_counter + 1
              ),
            }
          : { ...feed }
      );

      return {
        ...state,
        favouriteList: updatedFeedsAtMyProfile,
        loading: false,
      };
    case FOLLOW_USER.FOLLOW_USER_FAVORITE:
      const newUserFavoriteFollow = state?.favouriteList?.map((elem: any) => {
        if (elem.user_id == action.payload) {
          return { ...elem, user: { ...elem.user, is_following: true } };
        }
        return elem;
      });
      return {
        ...state,
        favouriteList: newUserFavoriteFollow,
        loading: false,
      };
    case UNFOLLOW_USER.UNFOLLOW_USER_FAVORITE:
      const newUserFavoriteUnFollow = state?.favouriteList?.map((elem: any) => {
        if (elem.user_id == action.payload) {
          return { ...elem, user: { ...elem.user, is_following: false } };
        }
        return elem;
      });
      return {
        ...state,
        favouriteList: newUserFavoriteUnFollow,
        loading: false,
      };
    case USER_FEEDS.UNLOCK_COMMENT_POST:
      const unlockedPostData =
        state.postData.id === action.payload
          ? { ...state.postData, is_lock: !state.postData.is_lock }
          : '';
      return {
        ...state,
        postData: unlockedPostData,
      };
    default:
      return state;
  }
};
