/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login, socketConnection, getEnvVariables } from '../redux/action';
import { useFormik } from 'formik';
import { LoginSchema } from '../validationScrema/user';
import { Store } from '../redux/Actions';
import { AppDispatch } from '../redux';
import PhoneScreen from '../assets/images/imgs/phone-screen.svg';
import Logo from '../assets/images/imgs/logo.svg';
import EyeIcon from '../assets/images/icons/ico_eye.svg';
import PlayStore from '../assets/images/imgs/google-play.svg';
import AppStore from '../assets/images/imgs/app-store.svg';
import { Link, useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import { toast } from 'react-toastify';

import { Button } from 'react-bootstrap';
import { OTP_VERIFICATION } from '../redux/constants/action-types';
import { getToken } from '../utils/utility';

export default function Login1() {
  const navigate = useNavigate();
  const isLoggedin: boolean = useSelector(
    (state: Store) => state.userDataReducer.isLoggedin
  );
  const token: String = getToken('jwtToken') || '';

  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      // acceptTerms: false,
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      dispatch(login(values));
    },
  });
  const handlePasswordVisiblity = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (isLoggedin) {
      navigate('/home');
      dispatch(socketConnection(token));
      //  dispatch(getEnvVariables())
    } else {
      navigate('/login');
    }
  }, [isLoggedin]);
  const { errors, touched } = formik;
  return (
    <section className="login-section" style={{ flexDirection: 'column' }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="login-box d-flex align-items-center justify-content-center flex-column">
              <div className="d-flex align-items-center justify-content-center">
                <div className="login-img d-none d-md-flex">
                  <img src={PhoneScreen} className="img-fluid" />
                </div>
                <div className="login-right">
                  <div className="login-form">
                    <Link to="/" className="logo-link">
                      <img src={Logo} className="img-fluid" />
                    </Link>
                    <form onSubmit={formik.handleSubmit}>
                      <div
                        className={`form-group ${
                          errors.email ? 'error-msg' : ''
                        }`}
                      >
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="form-control"
                          placeholder="Email address "
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {errors.email && touched.email && (
                          <div className="text-danger">{errors.email}</div>
                        )}
                      </div>

                      <div
                        className={`form-group ${
                          errors.password ? 'error-msg' : ''
                        }`}
                      >
                        <div className="input-group">
                          <input
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            id="password"
                            className="form-control border-end-0 pe-0"
                            placeholder="Password"
                            aria-label="Password"
                            aria-describedby="basic-addon2"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                          />
                          <img
                            src={EyeIcon}
                            className="img-fluid"
                            alt="icon"
                            style={{ zIndex: 11 }}
                            onClick={() => handlePasswordVisiblity()}
                          />
                        </div>
                        {errors.password && touched.password && (
                          <div className="text-danger">{errors.password}</div>
                        )}
                      </div>
                      <div className="form-group">
                        <Link
                          className="forgot-link d-flex align-items-center justify-content-end"
                          to={'/forget-password'}
                          onClick={() => {
                            dispatch({
                              type: OTP_VERIFICATION.OTP_VARIFICATION_FALSE_FORGATE_PASSWORD,
                            });
                            localStorage.removeItem("'otpSucessBack'");
                          }}
                        >
                          forgot password?
                        </Link>
                      </div>

                      <Button
                        type="submit"
                        // onSubmit={(e) => {
                        //   e.preventDefault();
                        //   formik.handleSubmit();
                        // }}
                        className="btn btn-login w-100"
                      >
                        Login
                      </Button>
                    </form>
                    <p className="mb-0 signup-link text-center">
                      Don’t have an account yet?{' '}
                      {/* <a href="signup.html" className="forgot-link">
                        SignUp  
                      </a> */}
                      <Link to={'/signup'}>SignUp</Link>
                    </p>
                  </div>
                  <div className="app-group text-center">
                    <p className="text-center get-app">Get the app</p>
                    <div className="group-button d-flex align-items-center justify-content-center">
                      <button className="btn btn-app">
                        <a href="https://play.google.com/store/apps/details?id=com.app.fanshub">
                          <img src={PlayStore} className="img-fluid" />
                        </a>
                      </button>
                      <button className="btn btn-app ms-2">
                        <a href="https://apps.apple.com/in/app/fanshub/id1538988586">
                          <img src={AppStore} className="img-fluid" />
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="footer d-flex align-items-center"
        style={{ position: 'relative', paddingTop: '108px' }}
      >
        <div className="footer-select">
          {/* <select className="form-select" aria-label="Default select example">
            <option selected>English</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select> */}
        </div>
        <p className="mb-0 ms-sm-1">© 2023 Fanshub from Cybertek Networks</p>
      </div>
    </section>
  );
}
