import React from "react";
import { Form, Button } from "react-bootstrap";
import leftArrow from "../assets/images/icons/left-arrow.svg";

const EditPaidPackage = ({
  handleEditSection,
  submitEditPackage,
  setPackagePrice,
  setPackageName,
  packageDetail,
  t,
  errors
}: any) => {
  return (
    <div className="create-package">
      <div className="heading-text">
        <img src={leftArrow} alt="leftArrow" onClick={handleEditSection} />
        <h6>{t("paidpackage.edit")}</h6>
      </div>
      <form onSubmit={submitEditPackage}>
        <div className="create-package-box">
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>{t("paidpackage.packagename")}</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="packageName"
              defaultValue={packageDetail?.media_package_name || ""}
              onChange={(e) => setPackageName(e.target.value)}
            />
            {errors.packageName && (
              <div className="text-danger edit-form-error">{errors.packageName}</div>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>{t("paidpackage.packageprice")}</Form.Label>
            <Form.Control
              type="number"
              placeholder=""
              name="media_package_price"
              defaultValue={packageDetail?.media_package_price || 0}
              onChange={(e) => setPackagePrice(e.target.value)}
              onKeyDown={e =>  ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
            />
            {errors.packagePrice && (
              <div className="text-danger edit-form-error">{errors.packagePrice}</div>
            )}
          </Form.Group>
          <Button type="submit" onClick={(e) => submitEditPackage(e)}>{t("paidpackage.update")}</Button>
        </div>
      </form>
    </div>
  );
};
export default EditPaidPackage;
