/* eslint-disable import/no-anonymous-default-export */

import {
  LOGIN,
  REGISTER,
  FORGOT_PASSWORD,
  OTP_VERIFICATION,
  RESEND_OTP,
  USER_DETAIL,
  LOGOUT,
  USER_TRANDING,
  USER_TRANDING_FEEDS,
  GET_COUNTRY,
  GET_STATE,
  EDIT_PROFILE,
  CHANGE_PASSWORD,
  RESET_PASSWORD,
  CHANGE_LOCAL,
  USER_FEEDS,
  USER_DASHBOARD_FEEDS,
  OTP_VERIFICATION_USER,
  FOLLOW_USER,
  UNFOLLOW_USER,
  GET_FOLLOWERS,
  UNBLOCK_USER,
  BLOCK_USER,
  GET_BLOCK,
  EDIT_BANKING,
  STORIES,
  USER_PROFILE,
  SUBSCRIPTION,
  FOLLOWING,
  SEARCH_USER_DETAIL,
  HASH_TAGS,
  POST_VOAT,
} from '../constants/action-types';
import { UserData } from '../Actions';
import { ActionTypes, searchUserDetail } from '../action/userActions';
import { setToken } from '../../utils/utility';
import { toast } from 'react-toastify';

const initialState = {
  stories: [],
  hasMoreFeed: true,
  loading: false,
  isLoggedin: false,
  isRegisterd: false,
  isRegisterdBackRedirect: false,
  isOtpVerifiedSuccess: false,
  isResendOtpSuccess: false,
  isForgotpasswordSuccess: false,
  isForgotpasswordSuccessRedirect: false,
  isResetpasswordSuccess: false,
  isLogoutSuccess: false,
  userDetail: {},
  searchUserDetail: {},
  firstName: null,
  lastName: null,
  profilePhoto: null,
  userTrandingList: [],
  userTrandingFeedList: [],
  userFeedList: [],
  countryList: [],
  stateList: [],
  userDashboardFeedList: [],
  hashTagFeedList: [],
  isEditProfileSuccess: false,
  isChangePasswordSuccess: false,
  isOtpUserVerifiedSuccess: false,
  local: localStorage.getItem('local') || 'en',
  isLoggedOut: false,
  authToken: '',
  isFollowed: false,
  isUnfollowed: false,
  followerList: [],
  followingList: [],
  isBlocked: false,
  isUnblocked: false,
  blockList: [],
  isEditBankingSuccess: false,
  user_packages: {},
  username: '',
  isProfileUpdated: false,
};
export default (state: UserData = initialState, action: ActionTypes) => {
  switch (action.type) {
    case CHANGE_LOCAL:
      localStorage.setItem('local', action.payload.toString());
      return {
        ...state,
        local: action.payload.toString(),
      };
    case STORIES.GET_STORIES_INITLIZATION:
      return { ...state };
    case STORIES.GET_STORIES_SUCCESS:
      return { ...state, stories: action.payload.data };
    case STORIES.GET_STORIES_ERROR:
      return { ...state, stories: [] };
    case STORIES.END_LIVE_STREAM:
      return {
        ...state,
      };
    case LOGIN.LOGIN_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case LOGIN.LOGIN_SUCCESS:
      const { token } = action.payload.data;
      setToken('isLoggedOut', false);
      setToken('authToken', token);
      setToken('userId', action.payload.data.user.id);

      return {
        ...state,
        // ...action.payload,
        isLoggedin: true,
        isLoggedOut: false,
        authToken: token,
        // userDetail: action.payload,
        loading: false,
      };
    case LOGIN.LOGIN_ERORR:
      return {
        ...state,
        ...action.payload,
        isLoggedin: false,
        loading: false,
      };
    case LOGIN.CHAT_LOGIN_SUCCESS:
      const response = action.payload;
      setToken('isLoggedOut', false);
      setToken('jwtToken', response?.token);
      setToken('chatUserId', action.payload.user_id);
      return {
        ...state,
        // ...action.payload,
        isLoggedin: true,
        isLoggedOut: false,
        authToken: token,
        userDetail: action.payload,
        // hasMoreFeed: action.payload.length > 0, active
        loading: false,
      };
    case LOGIN.CHAT_LOGIN_ERROR:
      return {
        ...state,
        ...action.payload,
        isLoggedin: false,
        loading: false,
      };
    case REGISTER.REGISTER_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case REGISTER.REGISTER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isRegisterd: true,
        isRegisterdBackRedirect: true,
        isForgotpasswordSuccess: false,
        isForgotpasswordSuccessRedirect: false,
        loading: false,
      };
    case REGISTER.REGISTER_ERORR:
      return {
        ...state,
        ...action.payload,
        isRegisterd: false,
        isRegisterdBackRedirect: false,
        loading: false,
      };
    case REGISTER.REGISTER_ERORR_BACK_ACTION:
      return {
        ...state,
        ...action.payload,
        isRegisterdBackRedirect: false,
      };
    case OTP_VERIFICATION.OTP_VERIFICATION_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case OTP_VERIFICATION.OTP_VERIFICATION_SUCCESS:
      // setToken("authToken",action.payload.token)
      if (action.payload.data) {
        setToken('userId', action.payload.data.id);
        return {
          ...state,
          // ...action.payload,
          isOtpVerifiedSuccess: true,
          isResetpasswordSuccess: false,
          userDetail: action.payload.data.user,
          loading: false,
        };
      } else {
        return {
          ...state,
          // ...action.payload,
          isOtpVerifiedSuccess: false,
          loading: false,
        };
      }

    case OTP_VERIFICATION.OTP_VARIFICATION_FALSE_FORGATE_PASSWORD:
      return {
        isOtpVerifiedSuccess: false,
        isForgotpasswordSuccess: false,
        isForgotpasswordSuccessRedirect: false,
        laading: false,
      };
    case OTP_VERIFICATION.OTP_VERIFICATION_ERORR:
      return {
        ...state,
        ...action.payload,
        isOtpVerifiedSuccess: false,
        loading: false,
      };

    case OTP_VERIFICATION_USER.OTP_VERIFICATION_USER_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case OTP_VERIFICATION_USER.OTP_VERIFICATION_USER_SUCCESS:
      if (action.payload.data) {
        return {
          ...state,
          ...action.payload,
          isOtpUserVerifiedSuccess: true,
          userDetail: action.payload.data.user,
          loading: false,
        };
      } else {
        return {
          isOtpUserVerifiedSuccess: false,
          loading: false,
        };
      }
    case OTP_VERIFICATION_USER.OTP_VERIFICATION_USER_ERORR:
      return {
        ...state,
        ...action.payload,
        isOtpUserVerifiedSuccess: false,
        loading: false,
      };

    case RESEND_OTP.RESEND_OTP_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case RESEND_OTP.RESEND_OTP_SUCCESS:
      setToken('authToken', action.payload.token);
      return {
        ...state,
        ...action.payload,
        isResendOtpSuccess: true,
        loading: false,
      };
    case RESEND_OTP.RESEND_OTP_ERORR:
      return {
        ...state,
        ...action.payload,
        isResendOtpSuccess: false,
        loading: false,
      };
    case RESET_PASSWORD.RESET_PASSWORD_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case RESET_PASSWORD.RESET_PASSWORD_SUCCESS:
      // setToken("authToken",action.payload.token)
      if (action.payload) {
        return {
          ...state,
          ...action.payload,
          isResetpasswordSuccess: true,
          loading: false,
        };
      } else {
        return {
          ...state,
          ...action.payload,
          isResetpasswordSuccess: false,
          loading: false,
        };
      }
    case RESET_PASSWORD.RESET_PASSWORD_ERORR:
      return {
        ...state,
        ...action.payload,
        isResetpasswordSuccess: false,
        loading: false,
      };
    case USER_DETAIL.USER_DETAIL_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case USER_DETAIL.USER_DETAIL_SUCCESS:
      return {
        ...state,
        ...action.payload,
        userDetail: action.payload.data.user_details,
        // userDetail: action.payload.data,
        loading: false,
      };
    case USER_DETAIL.USER_DETAIL_ERORR:
      return {
        ...state,
        ...action.payload,
        isResendOtpSuccess: false,
        loading: false,
      };
    case SEARCH_USER_DETAIL.SEARCH_USER_DETAIL_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case SEARCH_USER_DETAIL.SEARCH_USER_DETAIL_SUCCESS:
      return {
        ...state,
        ...action.payload,
        searchUserDetail: action.payload.data.user_details,
        // userDetail: action.payload.data,
        loading: false,
      };
    case SEARCH_USER_DETAIL.SEARCH_USER_DETAIL_ERORR:
      return {
        ...state,
        ...action.payload,
        isResendOtpSuccess: false,
        loading: false,
      };
    case USER_PROFILE.USER_PROFILE_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case USER_PROFILE.USER_PROFILE_SUCCESS:
      const { first_name, profile_photo, last_name, username } =
        action.payload.data;
      return {
        ...state,
        ...action.payload,
        firstName: first_name,
        lastName: last_name,
        profilePhoto: profile_photo,
        username: username,
        loading: false,
      };
    case USER_PROFILE.USER_PROFILE_ERORR:
      return {
        ...state,
        ...action.payload,
        isResendOtpSuccess: false,
        loading: false,
      };
    case FORGOT_PASSWORD.FORGOT_PASSWORD_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case FORGOT_PASSWORD.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isForgotpasswordSuccess: true,
        isForgotpasswordSuccessRedirect: true,
        isOtpUserVerifiedSuccess: false,
        isOtpVerifiedSuccess: false,
        loading: false,
      };
    case FORGOT_PASSWORD.FORGOT_PASSWORD_ERORR:
      return {
        ...state,
        ...action.payload,
        isForgotpasswordSuccess: false,
        isForgotpasswordSuccessRedirect: false,
        loading: false,
      };
    case FORGOT_PASSWORD.FORGOT_PASSWORD_BACK_ACTION:
      return {
        ...state,
        ...action.payload,
        isForgotpasswordSuccessRedirect: false,
      };
    case LOGOUT.LOGOUT_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case LOGOUT.LOGOUT_SUCCESS:
      setToken('authToken', '');
      setToken('userId', '');
      setToken('isLoggedOut', true);
      setToken('jwtToken', '');
      // setToken("isLoggedIn",false)
      return {
        ...state,
        // ...action.payload,
        isLogoutSuccess: true,
        isLoggedOut: true,
        isLoggedin: false,
        // userDetail:action.payload,
        loading: false,
        stories: [],
        hasMoreFeed: true,
        isRegisterd: false,
        isRegisterdBackRedirect: false,
        isOtpVerifiedSuccess: false,
        isResendOtpSuccess: false,
        isForgotpasswordSuccess: false,
        isForgotpasswordSuccessRedirect: false,
        isResetpasswordSuccess: false,
        userDetail: {},
        searchUserDetail: {},
        firstName: null,
        profilePhoto: null,
        userTrandingList: [],
        userTrandingFeedList: [],
        userFeedList: [],
        countryList: [],
        stateList: [],
        userDashboardFeedList: [],
        isEditProfileSuccess: false,
        isChangePasswordSuccess: false,
        isOtpUserVerifiedSuccess: false,
        local: localStorage.getItem('local') || 'en',
        authToken: '',
        isFollowed: false,
        isUnfollowed: false,
        followerList: [],
        followingList: [],
        isBlocked: false,
        isUnblocked: false,
        blockList: [],
        isEditBankingSuccess: false,
        user_packages: {},
      };
    case LOGOUT.LOGOUT_ERORR:
      return {
        ...state,
        ...action.payload,
        // isForgotpasswordSuccess: false,
        loading: false,
      };
    case USER_TRANDING.USER_TRANDING_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case USER_TRANDING.USER_TRANDING_SUCCESS:
      return {
        ...state,
        // ...action.payload,

        userTrandingList: action.payload,
        loading: false,
      };
    case USER_TRANDING.USER_TRANDING_ERORR:
      return {
        ...state,
        ...action.payload,
        userTrandingList: [],
        loading: false,
      };
    case USER_TRANDING_FEEDS.USER_TRANDING_FEEDS_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case USER_TRANDING_FEEDS.USER_TRANDING_FEEDS_SUCCESS:
      return {
        ...state,
        userTrandingFeedList:
          action.payload.page == 1
            ? [...action.payload.res.data]
            : [...state.userTrandingFeedList, ...action.payload.res.data],
        loading: false,
      };
    case USER_TRANDING_FEEDS.USER_TRANDING_FEEDS_ERORR:
      return {
        ...state,
        ...action.payload,
        userTrandingFeedList: [],
        loading: false,
      };
    case GET_COUNTRY.GET_COUNTRY_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case GET_COUNTRY.GET_COUNTRY_SUCCESS:
      return {
        ...state,
        ...action.payload,
        countryList: action.payload,
        loading: false,
      };
    case GET_COUNTRY.GET_COUNTRY_ERORR:
      return {
        ...state,
        ...action.payload,
        isResendOtpSuccess: false,
        loading: false,
      };
    case GET_STATE.GET_STATE_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case GET_STATE.GET_STATE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        stateList: action.payload,
        loading: false,
      };
    case GET_STATE.GET_STATE_ERORR:
      return {
        ...state,
        ...action.payload,
        isResendOtpSuccess: false,
        loading: false,
      };

    case EDIT_PROFILE.EDIT_PROFILE_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
        isProfileUpdated: false
      };
    case EDIT_PROFILE.EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isEditProfileSuccess: true,
        loading: false,
        isProfileUpdated: true
      };
    case EDIT_PROFILE.EDIT_PROFILE_ERORR:
      return {
        ...state,
        ...action.payload,
        isEditProfileSuccess: false,
        loading: false,
        isProfileUpdated: false
      };
    case CHANGE_PASSWORD.CHANGE_PASSWORD_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case CHANGE_PASSWORD.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isChangePasswordSuccess: true,
        loading: false,
      };
    case CHANGE_PASSWORD.CHANGE_PASSWORD_ERORR:
      return {
        ...state,
        ...action.payload,
        isChangePasswordSuccess: false,
        loading: false,
      };
    case USER_FEEDS.USER_FEEDS_INITLIZATION:
      return {
        ...state,
        ...action.payload,

        loading: true,
      };
    case USER_FEEDS.USER_FEEDS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        userFeedList: [...state.userFeedList, ...action.payload.res.data.posts],
        user_packages:
          action.payload.res.data.user_packages || action.payload.page === 1
            ? action.payload.res.data.user_packages
            : state.user_packages,
        hasMoreFeed: action.payload.res.data.posts.length ? true : false,
        loading: false,
      };
    case USER_FEEDS.USER_FEEDS_INIT_CLEAR:
      return {
        ...state,
        ...action.payload,
        userFeedList: [],
        searchUserDetail: {},
        loading: false,
      };
    case USER_FEEDS.ADD_POST:
      return {
        ...state,
        userDashboardFeedList: [action.payload, ...state.userDashboardFeedList],
        userDetail: {
          ...state.userDetail,
          posts_count: state?.userDetail?.posts_count + 1,
        },
      };

    case USER_FEEDS.DELETE_POST:
      const deleteFeedData = state.userDashboardFeedList?.filter(
        (elem: any) => action.payload != elem.id
      );
      const deleteFeedDataProfile = state.userFeedList?.filter(
        (elem: any) => action.payload != elem.id
      );
      const deleteHashTagData = state.hashTagFeedList?.filter(
        (elem: any) => action.payload !== elem.id
      );
      return {
        ...state,
        userDashboardFeedList: deleteFeedData,
        hashTagFeedList: deleteHashTagData
          ? deleteHashTagData
          : state.hashTagFeedList,
        userFeedList: deleteFeedDataProfile
          ? deleteFeedDataProfile
          : state.userFeedList,
        searchUserDetail: {
          ...state?.searchUserDetail,
          posts_count: state?.searchUserDetail?.posts_count - 1,
        },
        userDetail: {
          ...state?.userDetail,
          posts_count: state?.userDetail?.posts_count - 1,
        },
      };
    case USER_FEEDS.FEED_LIKE:
      const updatedFeeds = state.userDashboardFeedList.map((feed: any) =>
        feed.id === action.payload
          ? {
              ...feed,
              is_like: !feed.is_like,
              post_like_counter: Math.max(
                0,
                feed.is_like
                  ? feed.post_like_counter - 1
                  : feed.post_like_counter + 1
              ),
            }
          : { ...feed }
      );
      const updatedHashtagList = state?.hashTagFeedList.map((feed: any) =>
        feed.id === action.payload
          ? {
              ...feed,
              is_like: !feed.is_like,
              post_like_counter: Math.max(
                0,
                feed.is_like
                  ? feed.post_like_counter - 1
                  : feed.post_like_counter + 1
              ),
            }
          : { ...feed }
      );

      return {
        ...state,
        userDashboardFeedList: updatedFeeds,
        hashTagFeedList: updatedHashtagList,
        loading: false,
      };

    case USER_FEEDS.FEED_LIKE_MY_PROFILE:
      const updatedFeedsAtMyProfile = state.userFeedList.map((feed: any) =>
        feed.id === action.payload
          ? {
              ...feed,
              is_like: !feed.is_like,
              post_like_counter: Math.max(
                0,
                feed.is_like
                  ? feed.post_like_counter - 1
                  : feed.post_like_counter + 1
              ),
            }
          : { ...feed }
      );

      return {
        ...state,
        userFeedList: updatedFeedsAtMyProfile,
        loading: false,
      };

    case USER_FEEDS.UNLOCK_POST:
      const unlockedFeeds = state.userDashboardFeedList.map((feed: any) =>
        feed.id === action.payload
          ? {
              ...feed,
              is_lock: !feed.is_lock,
            }
          : { ...feed }
      );
      const unlockedFeedsUserFeed = state.userFeedList.map((feed: any) =>
        feed.id === action.payload
          ? {
              ...feed,
              is_lock: !feed.is_lock,
            }
          : { ...feed }
      );

      return {
        ...state,
        userDashboardFeedList: unlockedFeeds,
        userFeedList: unlockedFeedsUserFeed,
        loading: false,
      };
    case USER_FEEDS.POST_HIDE:
      const userProfileHiddenPost = state.userFeedList?.filter(
        (elem: any) => elem.id !== action.payload
      );
      const hiddenFeeds = state.userDashboardFeedList.filter(
        (feed: any) => feed.id !== action.payload
      );
      const hiddenFavouriteFeeds = state.userDashboardFeedList.filter(
        (feed: any) => feed.id !== action.payload
      );
      const hiddenHashtagPost = state.hashTagFeedList.filter(
        (feed: any) => feed.id !== action.payload
      );
      return {
        ...state,
        userDashboardFeedList: hiddenFeeds,
        userFeedList: userProfileHiddenPost
          ? userProfileHiddenPost
          : state.userFeedList,
        hashTagFeedList: hiddenHashtagPost
          ? hiddenHashtagPost
          : state.hashTagFeedList,
        loading: false,
      };
    case USER_FEEDS.FEED_LIKE_ERROR:
      return {
        ...state,
      };
    case USER_FEEDS.USER_FEEDS_ERORR:
      return {
        ...state,
        ...action.payload,
        userFeedList: [],
        loading: false,
      };

    case USER_DASHBOARD_FEEDS.USER_DASHBOARD_FEEDS_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };

    case USER_DASHBOARD_FEEDS.CLEAR_USER_DASHBOARD_FEED:
      return {
        ...state,
        userDashboardFeedList: [],
        loading: false,
      };

    case USER_DASHBOARD_FEEDS.USER_DASHBOARD_FEEDS_SUCCESS:
      return {
        ...state,
        hasMoreFeed: action.payload.res.data.length > 0,
        userDashboardFeedList:
          action.payload.page <= 1
            ? [...action.payload.res.data]
            : [...state.userDashboardFeedList, ...action.payload.res.data],
        loading: false,
      };
    case USER_DASHBOARD_FEEDS.USER_DASHBOARD_FEEDS_ERORR:
      return {
        ...state,
        ...action.payload,
        userDashboardFeedList: [],
        loading: false,
      };

    case USER_DASHBOARD_FEEDS.INCREASE_COMMENT_COUNT:
      const updatedPost = state.userDashboardFeedList.map((post: any) =>
        post.id !== action.payload.id
          ? post
          : { ...post, post_comment_counter: action.payload.count }
      );
      return {
        ...state,
        userDashboardFeedList: [...updatedPost],
      };

    case USER_DASHBOARD_FEEDS.FAVORITE_POST_ADD:
      const updatedfeed = state.userDashboardFeedList?.map((post: any) =>
        post.id !== action.payload ? post : { ...post, is_favourite: true }
      );
      const updatedHashtagfeed = state.hashTagFeedList?.map((post: any) =>
        post.id !== action.payload ? post : { ...post, is_favourite: true }
      );
      return {
        ...state,
        userDashboardFeedList: [...updatedfeed],
        hashTagFeedList: [...updatedHashtagfeed],
      };
    case USER_DASHBOARD_FEEDS.FAVORITE_POST_REMOVE:
      const updatedFeed = state.userDashboardFeedList.map((post: any) =>
        post.id !== action.payload ? post : { ...post, is_favourite: false }
      );
      const updatedHashtagFeed = state.hashTagFeedList.map((post: any) =>
        post.id !== action.payload ? post : { ...post, is_favourite: false }
      );
      return {
        ...state,
        userDashboardFeedList: [...updatedFeed],
        hashTagFeedList: [...updatedHashtagFeed],
      };

    case FOLLOW_USER.FOLLOW_USER_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        isFollowed: true,
        loading: true,
      };
    case FOLLOW_USER.FOLLOW_USER_SUCCESS:
      const updatedUserDetailForFollow = { ...state.userDetail };
      updatedUserDetailForFollow.is_following = true;
      updatedUserDetailForFollow.following_count += 1;
      const updatedSearchUserDetailForFollow = { ...state.searchUserDetail };
      const newUserFeedListDataFoolow = state?.userFeedList?.map(
        (elem: any) => {
          if (elem.user_id === action.payload) {
            return { ...elem, user: { ...elem.user, is_following: true } };
          }
          return elem;
        }
      );
      const newDashboardUserFeedListDataFollow =
        state?.userDashboardFeedList?.map((elem: any) => {
          if (elem.user_id === action.payload) {
            return { ...elem, user: { ...elem.user, is_following: true } };
          }
          return elem;
        });
      const suggestionsUserFollow = state.userTrandingList.map((elem: any) => {
        if (elem.id === action.payload) {
          return { ...elem, is_following: true };
        }
        return elem;
      });
      return {
        ...state,
        isFollowed: true,
        searchUserDetail: {
          ...state.searchUserDetail,
          is_following: true,
          followers_count: state.searchUserDetail.followers_count + 1,
        },
        userFeedList: newUserFeedListDataFoolow,
        userDetail: updatedUserDetailForFollow,
        userTrandingList: suggestionsUserFollow,
        userDashboardFeedList: newDashboardUserFeedListDataFollow,
        loading: false,
      };

    case FOLLOW_USER.FOLLOW_USER_SUCCESS_FOR_SUGGESTION:
      const updateduserTrandingListForFollow = [...state.userTrandingList];
      const updatedUserDetailForFollow12 = { ...state.userDetail };
      updatedUserDetailForFollow12.is_following = true;
      updatedUserDetailForFollow12.following_count += 1;
      updateduserTrandingListForFollow.forEach((trendItem: any) => {
        if (trendItem.id === action.payload) {
          trendItem.is_following = true;
        }
      });
      return {
        ...state,
        userDetail: updatedUserDetailForFollow12,
        userTrandingList: updateduserTrandingListForFollow,
        loading: false,
      };

    case UNFOLLOW_USER.UNFOLLOW_USER_SUCCESS_FOR_SUGGESTION:
      const updateduserTrandingListForUnFollow = [...state.userTrandingList];
      const updatedUserDetailForUnFollow12 = { ...state.userDetail };
      updatedUserDetailForUnFollow12.is_following = false;
      updatedUserDetailForUnFollow12.following_count -= 1;
      updateduserTrandingListForUnFollow.forEach((trendItem: any) => {
        if (trendItem.id === action.payload) {
          trendItem.is_following = false;
        }
      });
      return {
        ...state,
        userDetail: updatedUserDetailForUnFollow12,
        userTrandingList: updateduserTrandingListForUnFollow,
        loading: false,
      };

    case FOLLOW_USER.FOLLOW_USER_ERORR:
      return {
        ...state,
        ...action.payload,
        isFollowed: false,
        loading: false,
        // searchUserList:[]
      };
    case UNFOLLOW_USER.UNFOLLOW_USER_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        isUnfollowed: true,
        loading: true,
      };
    case UNFOLLOW_USER.UNFOLLOW_USER_SUCCESS:
      const updatedUserDetailForUnFollow = { ...state.userDetail };
      const newUserFeedListDataUnfoolow = state?.userFeedList?.map(
        (elem: any) => {
          if (elem.user_id === action.payload) {
            return { ...elem, user: { ...elem.user, is_following: false } };
          }
          return elem;
        }
      );
      updatedUserDetailForUnFollow.is_following = false;
      updatedUserDetailForUnFollow.following_count -= 1;
      const suggestionsUserUnfollow = state.userTrandingList.map(
        (elem: any) => {
          if (elem.id === action.payload) {
            return { ...elem, is_following: false };
          }
          return elem;
        }
      );
      const newDashboardUserFeedListDataUnFollow =
        state?.userDashboardFeedList?.map((elem: any) => {
          if (elem.user_id === action.payload) {
            return { ...elem, user: { ...elem.user, is_following: false } };
          }
          return elem;
        });
      const newHashtagFeedListUnfollow = state?.hashTagFeedList?.map(
        (elem: any) => {
          if (elem.user_id === action.payload) {
            return { ...elem, user: { ...elem.user, is_following: false } };
          }
          return elem;
        }
      );
      return {
        ...state,
        userDetail: updatedUserDetailForUnFollow,
        userTrandingList: suggestionsUserUnfollow,
        searchUserDetail: { ...state.searchUserDetail, is_following: false },
        userFeedList: newUserFeedListDataUnfoolow,
        userDashboardFeedList: newDashboardUserFeedListDataUnFollow,
        hashTagFeedList: newHashtagFeedListUnfollow,
        isUnfollowed: true,
        loading: false,
      };
    case UNFOLLOW_USER.UNFOLLOW_USER_ERORR:
      return {
        ...state,
        ...action.payload,
        isUnfollowed: false,
        loading: false,
        // searchUserList:[]
      };

    case GET_FOLLOWERS.GET_FOLLOWER_LIST_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case GET_FOLLOWERS.GET_FOLLOWER_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        followerList: action.payload,
        loading: false,
      };
    case GET_FOLLOWERS.GET_FOLLOWER_LIST_ERROR:
      return {
        ...state,
        ...action.payload,
        followerList: [],
        loading: false,
      };

    case FOLLOWING.GET_FOLLOWING_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case FOLLOWING.GET_FOLLOWING_SUCCESS:
      return {
        ...state,
        ...action.payload,
        followingList: action.payload,
        loading: false,
      };
    case FOLLOWING.GET_FOLLOWING_ERROR:
      return {
        ...state,
        ...action.payload,
        followingList: [],
        loading: false,
      };

    case FOLLOW_USER.FOLLOW_USER_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        isFollowed: true,
        loading: true,
      };
    // case FOLLOW_USER.FOLLOW_USER_SUCCESS:
    //   const updatedUserDetailForUnFollow1 = { ...state.userDetail };
    //   updatedUserDetailForUnFollow1.is_following = true;
    //   updatedUserDetailForUnFollow1.following_count += 1;
    //   return {
    //     ...state,
    //     userDetail: updatedUserDetailForUnFollow,
    //     isFollowed: true,
    //     loading: false,
    //   };
    case FOLLOW_USER.FOLLOW_USER_ERORR:
      return {
        ...state,
        ...action.payload,
        isFollowed: false,
        loading: false,
        // searchUserList:[]
      };
    case UNBLOCK_USER.UNBLOCK_USER_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        isUnblocked: true,
        loading: true,
      };
    case UNBLOCK_USER.UNBLOCK_USER_SUCCESS:
      return {
        ...state,
        isUnblocked: true,
        loading: false,
      };
    case UNBLOCK_USER.UNBLOCK_USER_ERORR:
      return {
        ...state,
        ...action.payload,
        isUnblocked: false,
        loading: false,
        // searchUserList:[]
      };

    case GET_BLOCK.GET_BLOCK_LIST_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case GET_BLOCK.GET_BLOCK_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        blockList: action.payload,
        loading: false,
      };
    case GET_BLOCK.GET_BLOCK_LIST_ERROR:
      return {
        ...state,
        ...action.payload,
        blockList: [],
        loading: false,
      };

    case EDIT_BANKING.EDIT_BANKING_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case EDIT_BANKING.EDIT_BANKING_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isEditBankingSuccess: true,
        loading: false,
      };
    case EDIT_BANKING.EDIT_BANKING_ERORR:
      return {
        ...state,
        ...action.payload,
        isEditBankingSuccess: false,
        loading: false,
      };
    case SUBSCRIPTION.SUBCRIPTION_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case SUBSCRIPTION.SUBSCRIPTION_SUCCESS:
      const newUserDashboardFeedListData = state?.userDashboardFeedList?.map(
        (elem: any) => {
          if (
            elem.user_id === action.payload.package_user_id &&
            elem.payment_type === 1
          ) {
            return { ...elem, is_lock: false, payment_type: 0 };
          }
          return elem;
        }
      );
      const newUserFeedListData = state.userFeedList?.map((elem: any) => {
        if (
          elem.user_id === action.payload.package_user_id &&
          elem.payment_type === 1
        ) {
          return { ...elem, is_lock: false, payment_type: 0 };
        }
        return elem;
      });
      const updateUserDetial = { ...state.userDetail };
      updateUserDetial.is_subscribe = true;
      return {
        ...state,
        ...action.payload.res,
        userDetail: updateUserDetial,
        userDashboardFeedList: newUserDashboardFeedListData
          ? newUserDashboardFeedListData
          : state?.userDashboardFeedList,
        userFeedList: newUserFeedListData
          ? newUserFeedListData
          : state.userFeedList,
        loading: false,
      };
    case SUBSCRIPTION.MY_SUBSCRIBER_ERORR:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case UNFOLLOW_USER.UNFOLLOW_USER_USER_PROFILE:
      const newUserProfileUnFollow = state?.userFeedList?.map((elem: any) => {
        if (elem.user_id == action.payload) {
          return { ...elem, user: { ...elem.user, is_following: false } };
        }
        return elem;
      });
      return {
        ...state,
        userFeedList: newUserProfileUnFollow,
        searchUserDetail: {
          ...state.searchUserDetail,
          is_following: false,
          followers_count: state.searchUserDetail.followers_count - 1,
        },
        loading: false,
      };
    case FOLLOW_USER.FOLLOW_USER_PROFILE:
      const newUserProfileFollow = state?.userFeedList?.map((elem: any) => {
        if (elem.user_id == action.payload) {
          return { ...elem, user: { ...elem.user, is_following: true } };
        }
        return elem;
      });
      const newHashtagFeedList = state?.hashTagFeedList?.map((elem: any) => {
        if (elem.user_id == action.payload) {
          return { ...elem, user: { ...elem.user, is_following: true } };
        }
        return elem;
      });
      return {
        ...state,
        userFeedList: newUserProfileFollow,
        hashTagFeedList: newHashtagFeedList,
        loading: false,
      };
    case HASH_TAGS.GET_HASH_TAGS_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case HASH_TAGS.GET_HASH_TAGS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        hashTagFeedList:
          action.payload.value.page == 1
            ? [...action.payload.data.data]
            : [...state.hashTagFeedList, ...action.payload.data.data],
        loading: false,
      };
    case HASH_TAGS.GET_HASH_TAGS_ERROR:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case POST_VOAT.POST_VOAT_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case POST_VOAT.POST_VOAT_SUCCESS:
      if (action.payload.data.success) {
        const newPostData = state?.userDashboardFeedList?.map((elem: any) => {
          if (elem.id === action.payload.data.data.id) {
            return {
              ...elem,
              voted: true,
              poll_options: action.payload.data.data.poll_options,
            };
          }
          return elem;
        });
        const newUserPostData = state?.userFeedList?.map((elem: any) => {
          if (elem.id === action.payload.data.data.id) {
            return {
              ...elem,
              voted: true,
              poll_options: action.payload.data.data.poll_options,
            };
          }
          return elem;
        });
        return {
          ...state,
          loading: false,
          userDashboardFeedList: newPostData,
          userFeedList: newUserPostData ? newUserPostData : state.userFeedList,
        };
      } else {
        return {
          ...state,
          loading: false,
        };
      }
    case GET_BLOCK.GET_BLOCK_LIST_ERROR:
      return {
        ...state,
        ...action.payload,
        hashTagFeedList: [],
        loading: false,
      };
    default:
      return state;
  }
};
