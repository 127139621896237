const REGISTER = {
  REGISTER_INITLIZATION: "REGISTER_INITLIZATION",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_ERORR: "REGISTER_ERORR",
  REGISTER_ERORR_BACK_ACTION: "REGISTER_ERORR_BACK_ACTION",
};

const LOGIN = {
  LOGIN_INITLIZATION: "LOGIN_INITLIZATION",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERORR: "LOGIN_ERORR",
  CHAT_LOGIN_SUCCESS: "CHAT_LOGIN_SUCCESS",
  CHAT_LOGIN_ERROR: "CHAT_LOGIN_ERROR",
};

const OTP_VERIFICATION = {
  OTP_VERIFICATION_INITLIZATION: "OTP_VERIFICATION_INITLIZATION",
  OTP_VERIFICATION_SUCCESS: "OTP_VERIFICATION_SUCCESS",
  OTP_VERIFICATION_ERORR: "OTP_VERIFICATION_ERORR",
  OTP_VARIFICATION_FALSE_FORGATE_PASSWORD:
    "OTP_VARIFICATION_FALSE_FORGATE_PASSWORD",
};

const OTP_VERIFICATION_USER = {
  OTP_VERIFICATION_USER_INITLIZATION: "OTP_VERIFICATION_USER_INITLIZATION",
  OTP_VERIFICATION_USER_SUCCESS: "OTP_VERIFICATION_USER_SUCCESS",
  OTP_VERIFICATION_USER_ERORR: "OTP_VERIFICATION_USER_ERORR",
};

const RESEND_OTP = {
  RESEND_OTP_INITLIZATION: "RESEND_OTP_INITLIZATION",
  RESEND_OTP_SUCCESS: "RESEND_OTP_SUCCESS",
  RESEND_OTP_ERORR: "RESEND_OTP_ERORR",
};
const FORGOT_PASSWORD = {
  FORGOT_PASSWORD_INITLIZATION: "FORGOT_PASSWORD_INITLIZATION",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_ERORR: "FORGOT_PASSWORD_ERORR",
  FORGOT_PASSWORD_BACK_ACTION: "FORGOT_PASSWORD_BACK_ACTION",
};
const RESET_PASSWORD = {
  RESET_PASSWORD_INITLIZATION: "RESET_PASSWORD_INITLIZATION",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERORR: "RESET_PASSWORD_ERORR",
};
const USER_DETAIL = {
  USER_DETAIL_INITLIZATION: "USER_DETAIL_INITLIZATION",
  USER_DETAIL_SUCCESS: "USER_DETAIL_SUCCESS",
  USER_DETAIL_ERORR: "USER_DETAIL_ERORR",
};

const SEARCH_USER_DETAIL = {
  SEARCH_USER_DETAIL_INITLIZATION: "SEARCH_USER_DETAIL_INITLIZATION",
  SEARCH_USER_DETAIL_SUCCESS: "SEARCH_USER_DETAIL_SUCCESS",
  SEARCH_USER_DETAIL_ERORR: "SEARCH_USER_DETAIL_ERORR",
};

const USER_PROFILE = {
  USER_PROFILE_INITLIZATION: "USER_PROFILE_INITLIZATION",
  USER_PROFILE_SUCCESS: "USER_PROFILE_SUCCESS_INIT",
  USER_PROFILE_ERORR: "USER_PROFILE_ERORR_INIT",
};
const LOGOUT = {
  LOGOUT_INITLIZATION: "LOGOUT_INITLIZATION",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_ERORR: "LOGOUT_ERORR",
};
const USER_TRANDING = {
  USER_TRANDING_INITLIZATION: "USER_TRANDING_INITLIZATION",
  USER_TRANDING_SUCCESS: "USER_TRANDING_SUCCESS",
  USER_TRANDING_ERORR: "USER_TRANDING_ERORR",
};
const USER_TRANDING_FEEDS = {
  USER_TRANDING_FEEDS_INITLIZATION: "USER_TRANDING_FEEDS_INITLIZATION",
  USER_TRANDING_FEEDS_SUCCESS: "USER_TRANDING_FEEDS_SUCCESS",
  USER_TRANDING_FEEDS_ERORR: "USER_TRANDING_FEEDS_ERORR",
};
const GET_COUNTRY = {
  GET_COUNTRY_INITLIZATION: "GET_COUNTRY_INITLIZATION",
  GET_COUNTRY_SUCCESS: "GET_COUNTRY_SUCCESS",
  GET_COUNTRY_ERORR: "GET_COUNTRY_ERORR",
};
const GET_STATE = {
  GET_STATE_INITLIZATION: "GET_STATE_INITLIZATION",
  GET_STATE_SUCCESS: "GET_STATE_SUCCESS",
  GET_STATE_ERORR: "GET_STATE_ERORR",
};
const EDIT_PROFILE = {
  EDIT_PROFILE_INITLIZATION: "EDIT_PROFILE_INITLIZATION",
  EDIT_PROFILE_SUCCESS: "EDIT_PROFILE_SUCCESS",
  EDIT_PROFILE_ERORR: "EDIT_PROFILE_ERORR",
};
const CHANGE_PASSWORD = {
  CHANGE_PASSWORD_INITLIZATION: "CHANGE_PASSWORD_INITLIZATION",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_ERORR: "CHANGE_PASSWORD_ERORR",
};
const GET_VERIFICATION_STATUS = {
  GET_VERIFICATION_STATUS_INITLIZATION: "GET_VERIFICATION_STATUS_INITLIZATION",
  GET_VERIFICATION_STATUS_SUCCESS: "GET_VERIFICATION_STATUS_SUCCESS",
  GET_VERIFICATION_STATUS_ERORR: "GET_VERIFICATION_STATUS_ERORR",
};
const DOCUMENT_VERIFICATION = {
  DOCUMENT_VERIFICATION_INITLIZATION: "DOCUMENT_VERIFICATION_INITLIZATION",
  DOCUMENT_VERIFICATION_SUCCESS: "DOCUMENT_VERIFICATION_SUCCESS",
  DOCUMENT_VERIFICATION_ERORR: "DOCUMENT_VERIFICATION_ERORR",
};
const USER_SUBSCRIPTION = {
  USER_SUBSCRIPTION_INITLIZATION: "USER_SUBSCRIPTION_INITLIZATION",
  USER_SUBSCRIPTION_SUCCESS: "USER_SUBSCRIPTION_SUCCESS",
  USER_SUBSCRIPTION_ERORR: "USER_SUBSCRIPTION_ERORR",
};
const USER_FEEDS = {
  USER_FEEDS_INITLIZATION: "USER_FEEDS_INITLIZATION",
  USER_FEEDS_SUCCESS: "USER_FEEDS_SUCCESS",
  USER_FEEDS_ERORR: "USER_FEEDS_ERORR",
  FEED_LIKE: "FEED_LIKE",
  FEED_LIKE_MY_PROFILE: "FEED_LIKE_MY_PROFILE",
  FEED_LIKE_FAVOURITE: "FEED_LIKE_FAVOURITE",
  FEED_LIKE_SEARCH_PROFILE: "FEED_LIKE_SEARCH_PROFILE",
  FEED_LIKE_ERROR: "FEED_LIKE_ERROR",
  UNLOCK_POST: "UNLOCK_POST",
  UNLOCK_COMMENT_POST: "UNLOCK_COMMENT_POST",
  POST_HIDE: "POST_HIDE",
  POST_HIDE_FAVOURITE: "POST_HIDE_FAVOURITE",
  ADD_POST: "ADD_POST",
  DELETE_POST: "DELETE_POST",
  USER_FEEDS_INIT_CLEAR: "USER_FEEDS_INIT_CLEAR",
};
const POST_BY_ID = {
  POST_BY_ID_INITLIZATION: "POST_BY_ID_INITLIZATION",
  POST_BY_ID_SUCCESS: "POST_BY_ID_SUCCESS",
  POST_BY_ID_ERORR: "POST_BY_ID_ERORR",
  CLEAR_POST_DATA: "CLEAR_POST_DATA",
};
const GET_COMMENTS = {
  GET_COMMENTS_INITLIZATION: "GET_COMMENTS_INITLIZATION",
  GET_COMMENTS_SUCCESS: "GET_COMMENTS_SUCCESS",
  GET_COMMENTS_ERORR: "GET_COMMENTS_ERORR",
};

const CREATE_TEXT_POST = {
  CREATE_TEXT_POST_INITLIZATION: "CREATE_TEXT_POST_INITLIZATION",
  CREATE_TEXT_POST_SUCCESS: "CREATE_TEXT_POST_SUCCESS",
  CREATE_TEXT_POST_ERORR: "CREATE_TEXT_POST_ERORR",
};
const GET_TAG_LIST = {
  GET_TAG_LIST_INITLIZATION: "GET_TAG_LIST_INITLIZATION",
  GET_TAG_LIST_SUCCESS: "GET_TAG_LIST_SUCCESS",
  GET_TAG_LIST_ERORR: "GET_TAG_LIST_ERORR",
};

const USER_DASHBOARD_FEEDS = {
  USER_DASHBOARD_FEEDS_INITLIZATION: "USER_DASHBOARD_FEEDS_INITLIZATION",
  USER_DASHBOARD_FEEDS_SUCCESS: "USER_DASHBOARD_FEEDS_SUCCESS",
  USER_DASHBOARD_FEEDS_ERORR: "USER_DASHBOARD_FEEDS_ERORR",
  CLEAR_USER_DASHBOARD_FEED: "CLEAR_USER_DASHBOARD_FEED",

  INCREASE_COMMENT_COUNT: "INCREASE_COMMENT_COUNT",
  FAVORITE_POST_ADD: "FAVORITE_POST_ADD",
  FAVORITE_POST_REMOVE: "FAVORITE_POST_REMOVE",

  HIDE_POST_ADD: "HIDE_POST_ADD",
  HIDE_POST_REMOVE: "HIDE_POST_REMOVE",
};
const CREATE_COMMENT = {
  CREATE_COMMENT_INITLIZATION: "CREATE_COMMENT_INITLIZATION",
  CREATE_COMMENT_SUCCESS: "CREATE_COMMENT_SUCCESS",
  CREATE_COMMENT_ERORR: "CREATE_COMMENT_ERORR",
  INCREASE_FAVOURITE_COMMENT_COUNT: "INCREASE_FAVOURITE_COMMENT_COUNT",
};
const ADD_LIKE = {
  ADD_LIKE_INITLIZATION: "ADD_LIKE_INITLIZATION",
  ADD_LIKE_SUCCESS: "ADD_LIKE_SUCCESS",
  ADD_LIKE_ERORR: "ADD_LIKE_ERORR",
};
const FOLLOW_USER = {
  FOLLOW_USER_INITLIZATION: "FOLLOW_USER_INITLIZATION",
  FOLLOW_USER_SUCCESS: "FOLLOW_USER_SUCCESS",
  FOLLOW_USER_ERORR: "FOLLOW_USER_ERORR",
  FOLLOW_USER_SUCCESS_FOR_SUGGESTION: "FOLLOW_USER_SUCCESS_FOR_SUGGESTION",
  FOLLOW_USER_FAVORITE:"FOLLOW_USER_FAVORITE",
  FOLLOW_USER_PROFILE:"FOLLOW_USER_PROFILE"
};

const UNFOLLOW_USER = {
  UNFOLLOW_USER_INITLIZATION: "UNFOLLOW_USER_INITLIZATION",
  UNFOLLOW_USER_SUCCESS: "UNFOLLOW_USER_SUCCESS",
  UNFOLLOW_USER_ERORR: "UNFOLLOW_USER_ERORR",
  UNFOLLOW_USER_SUCCESS_FOR_SUGGESTION: "UNFOLLOW_USER_SUCCESS_FOR_SUGGESTION",
  UNFOLLOW_USER_FAVORITE:"UNFOLLOW_USER_FAVORITE",
  UNFOLLOW_USER_USER_PROFILE:"UNFOLLOW_USER_USER_PROFILE"
};

const HIDE_POST = {
  HIDE_POST_INITLIZATION: "HIDE_POST_INITLIZATION",
  HIDE_POST_SUCCESS: "HIDE_POST_SUCCESS",
  HIDE_POST_ERORR: "HIDE_POST_ERORR",
};
const SHARE_POST = {
  SHARE_POST_INITLIZATION: "SHARE_POST_INITLIZATION",
  SHARE_POST_SUCCESS: "SHARE_POST_SUCCESS",
  SHARE_POST_ERORR: "SHARE_POST_ERORR",
};
const ADD_TO_FAVOURITE = {
  ADD_TO_FAVOURITE_INITLIZATION: "ADD_TO_FAVOURITE_INITLIZATION",
  ADD_TO_FAVOURITE_SUCCESS: "ADD_TO_FAVOURITE_SUCCESS",
  ADD_TO_FAVOURITE_ERORR: "ADD_TO_FAVOURITE_ERORR",
};
const REMOVE_FROM_FAVOURITE = {
  REMOVE_FROM_FAVOURITE_INITLIZATION: "REMOVE_FROM_FAVOURITE_INITLIZATION",
  REMOVE_FROM_FAVOURITE_SUCCESS: "REMOVE_FROM_FAVOURITE_SUCCESS",
  REMOVE_FROM_FAVOURITE_ERORR: "REMOVE_FROM_FAVOURITE_ERORR",
};
const FAVOURITE_LIST = {
  FAVOURITE_LIST_INITLIZATION: "FAVOURITE_LIST_INITLIZATION",
  FAVOURITE_LIST_SUCCESS: "FAVOURITE_LIST_SUCCESS",
  FAVOURITE_LIST_ERORR: "FAVOURITE_LIST_ERORR",
};

const CHANGE_LOCAL = "CHANGE_LOCAL";

//Hint: Chat Constants
const CHAT = {
  SOCKET_CONNECTION_INITLIZATION: "SOCKET_CONNECTION_INITLIZATION",
  SOCKET_CONNECTION_SUCCESS: "SOCKET_CONNECTION_SUCCESS",
  SOCKET_CONNECTION_ERROR: "SOCKET_CONNECTION_ERORR",
  FETCH_CHAT_USERLIST_INITLIZATION: "FETCH_CHAT_USERLIST_INITLIZATION",
  USER_LIST_SUCCESS: "USER_LIST_SUCCESS",
  ADD_NEW_USER_SUCCESS: "ADD_NEW_USER_SUCCESS",
  USER_LIST_ERROR: "USER_LIST_ERROR",
  ROOM_DETAIL_SUCCESS: "ROOM_DETAIL_SUCCESS",
  ROOM_DETAIL_ERROR: "ROOM_DETAIL_ERROR",
  ROOM_DETAIL_INITLIZATION: "ROOM_DETAIL_INITLIZATION",
  FETCH_CHAT_HISTORY_INITLIZATION: "FETCH_CHAT_HISTORY_INITLIZATION",
  ROOM_CHAT_HISTORY_SUCCESS: "ROOM_CHAT_HISTORY_SUCCESS",
  ROOM_CHAT_HISTORY_ERROR: "ROOM_CHAT_HISTORY_ERROR",
  SEND_MESSAGE_INITLIZATION: "SEND_MESSAGE_INITLIZATION",
  SEND_MESSAGE_SUCCESS: "SEND_MESSAGE_SUCCESS",
  SEND_MESSAGE_ERROR: "SEND_MESSAGE_ERROR",
  UPLOAD_FILE_INITLIZATION: "UPLOAD_FILE_INITLIZATION",
  UPLOAD_FILE_ERROR: "UPLOAD_FILE_ERROR",
  UPLOAD_FILE_SUCCESS: "UPLOAD_FILE_SUCCESS",
  ATTACH_TO_MESSAGE: "ATTACH_TO_MESSAGE",
  SEND_PACKAGE_INITLIZATION: "SEND_PACKAGE_INITLIZATION",
  SEND_PACKAGE_SUCCESS: "SEND_PACKAGE_SUCCESS",
  SEND_PACKAGE_ERROR: "SEND_PACKAGE_ERROR",
  SEND_PAID_PACKAGE_INITLIZATION: "SEND_PAID_PACKAGE_INITLIZATION",
  SEND_PAID_PACKAGE_SUCCESS: "SEND_PAID_PACKAGE_SUCCESS",
  SEND_PAID_PACKAGE_ERROR: "SEND_PAID_PACKAGE_ERROR",
  UNLOCK_PAID_PACKAGE_INITLIZATION: "UNLOCK_PAID_PACKAGE_INITLIZATION",
  UNLOCK_PAID_PACKAGE_SUCCESS: "UNLOCK_PAID_PACKAGE_SUCCESS",
  UNLOCK_PAID_PACKAGE_ERROR: "UNLOCK_PAID_PACKAGE_ERROR",
  FETCH_NEW_MESSAGE_INITLIZATION: "FETCH_NEW_MESSAGE_INITLIZATION",
  FETCH_NEW_MESSAGE_SUCCESS: "FETCH_NEW_MESSAGE_SUCCESS",
  FETCH_NEW_MESSAGE_ERROR: "FETCH_NEW_MESSAGE_ERROR",
  STICKER_INIT: "STICKER_INIT",
  STICKER_LIST_SUCCESS: 'STICKER_LIST_SUCCESS',
  STICKER_LIST_ERROR:"STICKER_LIST_ERROR",
  POST_STICKER_INIT: "POST_STICKER_INIT",
  POST_STICKER_LIST_SUCCESS: 'POST_STICKER_LIST_SUCCESS',
  POST_STICKER_LIST_ERROR:"POST_STICKER_LIST_ERROR",
};
//Hint: Paid Package Constants
const GET_PAID_PACKAGE = {
  GET_PAID_PACKAGE_INITLIZATION: "GET_PAID_PACKAGE_INITLIZATION",
  GET_PAID_PACKAGE_SUCCESS: "GET_PAID_PACKAGE_SUCCESS",
  GET_PAID_PACKAGE_ERROR: "GET_PAID_PACKAGE_ERROR",
};

//Hint: Create Paid Package Constant
const CREATE_PAID_PACKAGE = {
  CREATE_PAID_PACKAGE_INITLIZATION: "CREATE_PAID_PACKAGE_INITLIZATION",
  CREATE_PAID_PACKAGE_SUCCESS: "CREATE_PAID_PACKAGE_SUCCESS",
  CREATE_PAID_PACKAGE_ERORR: "CREATE_PAID_PACKAGE_ERORR",
};
//Hint: Edit Paid Package Constant
const EDIT_PAID_PACKAGE = {
  EDIT_PAID_PACKAGE_INITLIZATION: "EDIT_PAID_PACKAGE_INITLIZATION",
  EDIT_PAID_PACKAGE_SUCCESS: "EDIT_PAID_PACKAGE_SUCCESS",
  EDIT_PAID_PACKAGE_ERORR: "EDIT_PAID_PACKAGE_ERORR",
};
//Hint: Get Paid Package Detail Constant
const PAID_PACKAGE_BY_ID = {
  PAID_PACKAGE_BY_ID_INITLIZATION: "PAID_PACKAGE_BY_ID_INITLIZATION",
  PAID_PACKAGE_BY_ID_SUCCESS: "PAID_PACKAGE_BY_ID_SUCCESS",
  PAID_PACKAGE_BY_ID_ERORR: "PAID_PACKAGE_BY_ID_ERORR",
};
//Hint: Create Paid Package Constant
const CREATE_MEDIA_PACKAGE = {
  CREATE_MEDIA_PACKAGE_INITLIZATION: "CREATE_MEDIA_PACKAGE_INITLIZATION",
  CREATE_MEDIA_PACKAGE_SUCCESS: "CREATE_MEDIA_PACKAGE_SUCCESS",
  CREATE_MEDIA_PACKAGE_ERORR: "CREATE_MEDIA_PACKAGE_ERORR",
};
//Hint: Get Media Content Detail Constant
const GET_MEDIA_PACKAGE_BY_ID = {
  MEDIA_PACKAGE_BY_ID_INITLIZATION: "MEDIA_PACKAGE_BY_ID_INITLIZATION",
  MEDIA_PACKAGE_BY_ID_SUCCESS: "MEDIA_PACKAGE_BY_ID_SUCCESS",
  MEDIA_PACKAGE_BY_ID_ERORR: "MEDIA_PACKAGE_BY_ID_ERORR",
};
//Hint: Get Follower List
const GET_FOLLOWERS = {
  GET_FOLLOWER_LIST_INITLIZATION: "GET_FOLLOWER_LIST_INITLIZATION",
  GET_FOLLOWER_LIST_SUCCESS: "GET_FOLLOWER_LIST_SUCCESS",
  GET_FOLLOWER_LIST_ERROR: "GET_FOLLOWER_LIST_ERROR",
};

//Hint: Media Remove from Package
const REMOVE_MEDIA_FROM_PACKAGE = {
  REMOVE_FROM_MEDIA_INITLIZATION: "REMOVE_FROM_MEDIA_INITLIZATION",
  REMOVE_FROM_MEDIA_SUCCESS: "REMOVE_FROM_MEDIA_SUCCESS",
  REMOVE_FROM_MEDIA_ERORR: "REMOVE_FROM_MEDIA_ERORR",
};
//Hint:Remove Package
const REMOVE_PACKAGE = {
  REMOVE_PACKAGE_INITLIZATION: "REMOVE_PACKAGE_INITLIZATION",
  REMOVE_PACKAGE_SUCCESS: "REMOVE_PACKAGE_SUCCESS",
  REMOVE_PACKAGE_ERORR: "REMOVE_PACKAGE_ERORR",
};

const BLOCK_USER = {
  BLOCK_USER_INITLIZATION: "BLOCK_USER_INITLIZATION",
  BLOCK_USER_SUCCESS: "BLOCK_USER_SUCCESS",
  BLOCK_USER_ERORR: "BLOCK_USER_ERORR",
};

const UNBLOCK_USER = {
  UNBLOCK_USER_INITLIZATION: "UNBLOCK_USER_INITLIZATION",
  UNBLOCK_USER_SUCCESS: "UNBLOCK_USER_SUCCESS",
  UNBLOCK_USER_ERORR: "UNBLOCK_USER_ERORR",
};

const GET_BLOCK = {
  GET_BLOCK_LIST_INITLIZATION: "GET_BLOCK_LIST_INITLIZATION",
  GET_BLOCK_LIST_SUCCESS: "GET_BLOCK_LIST_SUCCESS",
  GET_BLOCK_LIST_ERROR: "GET_BLOCK_LIST_ERROR",
};

const GET_ENV_VARIABLES = {
  GET_ENV_VARIABLES_INITLIZATION: "GET_ENV_VARIABLES_INITLIZATION",
  GET_ENV_VARIABLES_SUCCESS: "GET_ENV_VARIABLES_SUCCESS",
  GET_ENV_VARIABLES_ERROR: "GET_ENV_VARIABLES_ERROR",
};

//Notification
const NOTIFICATION = {
  NOTIFICATION_INITLIZATION: "NOTIFICATION_INITLIZATION",
  NOTIFICATION_SUCCESS: "NOTIFICATION_SUCCESS",
  NOTIFICATION_ERORR: "NOTIFICATION_ERORR",
};

//Wallet
const WALLET = {
  WALLET_INITLIZATION: "WALLET_INITLIZATION",
  WALLET_SUCCESS: "WALLET_SUCCESS",
  WALLET_ERORR: "WALLET_ERORR",
};

//My SubScribers
const MY_SUBSCRIBER = {
  MY_SUBSCRIBER_INITLIZATION: "MY_SUBSCRIBER_INITLIZATION",
  MY_SUBSCRIBER_SUCCESS: "MY_SUBSCRIBER_SUCCESS",
  MY_SUBSCRIBER_ERORR: "MY_SUBSCRIBER_ERORR",
};

// Subscriptions
const SUBSCRIPTION = {
  SUBCRIPTION_INITLIZATION: "SUBCRIPTION_INITLIZATION",
  SUBSCRIPTION_SUCCESS: "SUBSCRIPTION_SUCCESS",
  MY_SUBSCRIBER_ERORR: "MY_SUBSCRIBER_ERORR",
};

//Payout
const PAYOUT = {
  ADD_PAYOUT_INITLIZATION: "ADD_PAYOUT_INITLIZATION",
  ADD_PAYOUT_SUCCESS: "ADD_PAYOUT_SUCCESS",
  ADD_PAYOUT_ERORR: "ADD_PAYOUT_ERORR",
  PAYOUT_HISTORY_INITLIZATION: "PAYOUT_HISTORY_INITLIZATION",
  PAYOUT_HISTORY_SUCCESS: "PAYOUT_HISTORY_SUCCESS",
  PAYOUT_HISTORY_ERORR: "PAYOUT_HISTORY_ERORR",
  IN_APP_PAYMENT_INITLIZATION: "IN_APP_PAYMENT_INITLIZATION",
  IN_APP_PAYMENT_SUCCESS: "IN_APP_PAYMENT_SUCCESS",
  IN_APP_PAYMENT_ERORR: "IN_APP_PAYMENT_ERORR",
};

const EDIT_BANKING = {
  EDIT_BANKING_INITLIZATION: "EDIT_BANKING_INITLIZATION",
  EDIT_BANKING_SUCCESS: "EDIT_BANKING_SUCCESS",
  EDIT_BANKING_ERORR: "EDIT_BANKING_ERORR",
};

const PLAN = {
  PACKAGE_LIST_INITLIZATION: "PACKAGE_LIST_INITLIZATION",
  PACKAGE_LIST_SUCCESS: "PACKAGE_LIST_SUCCESS",
  PACKAGE_LIST_ERORR: "PACKAGE_LIST_ERORR",
};
const EDIT_PLAN = {
  EDIT_PLAN_INITLIZATION: "EDIT_PLAN_INITLIZATION",
  EDIT_PLAN_SUCCESS: "EDIT_PLAN_SUCCESS",
  EDIT_PLAN_ERORR: "EDIT_PLAN_ERORR",
};

const STORIES = {
  GET_STORIES_INITLIZATION: "GET_STORIES_INITLIZATION",
  GET_STORIES_SUCCESS: "GET_STORIES_SUCCESS",
  GET_STORIES_ERROR: "GET_STORIES_ERROR",
  END_LIVE_STREAM: "END_LIVE_STREAM",
};

const FOLLOWING = {
  GET_FOLLOWING_INITLIZATION: "GET_FOLLOWING_INITLIZATION",
  GET_FOLLOWING_SUCCESS: "GET_FOLLOWING_SUCCESS",
  GET_FOLLOWING_ERROR: "GET_FOLLOWING_ERROR",
};

const GO_LIVE = {
  START_LIVE_STREAMING: "START_LIVE_STREAMING",
  STOP_LIVE_STREAMING: "STOP_LIVE_STREAMING",
  JOIN_LIVE_STREAM: "JOIN_LIVE_STREAM",
  EXIT_LIVE_STREAM: "EXIT_LIVE_STREAM",
  SET_SUBSCRIBER_TOKEN: "SET_SUBSCRIBER_TOKEN",
  SET_HOST_TOKEN: "SET_HOST_TOKEN",
  SET_TITLE_DISCRIPTION: "SET_TITLE_DISCRIPTION",
};

const HASH_TAGS = {
  GET_HASH_TAGS_INITLIZATION: "GET_HASH_TAGS_INITLIZATION",
  GET_HASH_TAGS_SUCCESS: "GET_HASH_TAGS_SUCCESS",
  GET_HASH_TAGS_ERROR: "GET_HASH_TAGS_ERROR",
}
const POST_VOAT = {
  POST_VOAT_INITLIZATION: "POST_VOAT_INITLIZATION",
  POST_VOAT_SUCCESS: "POST_VOAT_SUCCESS",
  POST_VOAT_ERROR: "POST_VOAT_ERROR",
}
export {
  REGISTER,
  LOGIN,
  FORGOT_PASSWORD,
  OTP_VERIFICATION,
  RESEND_OTP,
  RESET_PASSWORD,
  USER_DETAIL,
  LOGOUT,
  USER_TRANDING,
  USER_TRANDING_FEEDS,
  GET_COUNTRY,
  GET_STATE,
  EDIT_PROFILE,
  CHANGE_PASSWORD,
  GET_VERIFICATION_STATUS,
  DOCUMENT_VERIFICATION,
  USER_SUBSCRIPTION,
  CHANGE_LOCAL,
  USER_FEEDS,
  POST_BY_ID,
  GET_COMMENTS,
  CREATE_TEXT_POST,
  GET_TAG_LIST,
  CHAT,
  USER_DASHBOARD_FEEDS,
  OTP_VERIFICATION_USER,
  GET_PAID_PACKAGE,
  CREATE_PAID_PACKAGE,
  EDIT_PAID_PACKAGE,
  PAID_PACKAGE_BY_ID,
  CREATE_MEDIA_PACKAGE,
  GET_MEDIA_PACKAGE_BY_ID,
  CREATE_COMMENT,
  ADD_LIKE,
  FOLLOW_USER,
  HIDE_POST,
  SHARE_POST,
  ADD_TO_FAVOURITE,
  REMOVE_FROM_FAVOURITE,
  UNFOLLOW_USER,
  FAVOURITE_LIST,
  GET_FOLLOWERS,
  REMOVE_MEDIA_FROM_PACKAGE,
  GET_BLOCK,
  UNBLOCK_USER,
  BLOCK_USER,
  GET_ENV_VARIABLES,
  NOTIFICATION,
  WALLET,
  MY_SUBSCRIBER,
  PAYOUT,
  EDIT_BANKING,
  PLAN,
  EDIT_PLAN,
  STORIES,
  REMOVE_PACKAGE,
  USER_PROFILE,
  SUBSCRIPTION,
  FOLLOWING,
  GO_LIVE,
  SEARCH_USER_DETAIL,
  HASH_TAGS,
  POST_VOAT
};
