import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import { AppDispatch } from '../redux';
import { resetPassword } from '../redux/action';
import { Store } from '../redux/Actions';
import icon from '../assets/images/icons/ico_eye.svg';
import { ResetPasswordSchema } from '../validationScrema/user';
import { Link } from 'react-router-dom';

export default function ResetPassword() {
  const isResetpasswordSuccess: boolean = useSelector(
    (state: Store) => state.userDataReducer.isResetpasswordSuccess
  );
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const formik = useFormik({
    initialValues: {
      new_password: '',
      confirm_new_password: '',
      // acceptTerms: false,
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: (values) => {
      dispatch(resetPassword(values));
    },
  });
  useEffect(() => {
    if (isResetpasswordSuccess) {
      navigate('/login');
    }
  }, [isResetpasswordSuccess]);
  const { errors, touched } = formik;
  return (
    <section className="login-section forgot-psw">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="login-box d-flex align-items-center justify-content-center flex-column">
              <div className="d-flex align-items-center justify-content-center">
                <div className="login-right ms-0">
                  <div className="login-form">
                    <h2 className="login-title">Create New Password</h2>
                    <form onSubmit={formik.handleSubmit}>
                      <div
                        className={`form-group ${
                          errors.new_password ? 'error-msg' : ''
                        }`}
                      >
                        <div className="input-group">
                          <input
                            type={showPassword ? 'text' : 'password'}
                            name="new_password"
                            className="form-control border-end-0 pe-0"
                            placeholder="New Password"
                            aria-label="Password"
                            aria-describedby="psw"
                            value={formik.values.new_password}
                            onChange={formik.handleChange}
                          />

                          {/* <span className="input-group-text" id="psw"> */}
                          <img
                            src={icon}
                            className="img-fluid"
                            alt="icon"
                            style={{ zIndex: 11 }}
                            onClick={() => setShowPassword(!showPassword)}
                          />
                          {/* </span> */}
                        </div>
                        {errors.new_password && touched.new_password && (
                          <div className="text-danger">
                            {errors.new_password}
                          </div>
                        )}
                      </div>
                      <div
                        className={`form-group ${
                          errors.confirm_new_password ? 'error-msg' : ''
                        }`}
                      >
                        <div className="input-group">
                          <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            name="confirm_new_password"
                            className="form-control border-end-0 pe-0"
                            placeholder="Confirm Password"
                            aria-label="Password"
                            aria-describedby="confirm-psw"
                            value={formik.values.confirm_new_password}
                            onChange={formik.handleChange}
                          />
                          {/* <span className="input-group-text" id="confirm-psw"> */}
                          <img
                            src={icon}
                            className="img-fluid"
                            alt="icon"
                            style={{ zIndex: 11 }}
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                          />
                          {/* </span> */}
                        </div>
                        {errors.confirm_new_password &&
                          touched.confirm_new_password && (
                            <div className="text-danger">
                              {errors.confirm_new_password}
                            </div>
                          )}
                      </div>
                      <button type="submit" className="btn btn-login w-100">
                        Reset
                      </button>
                      <p className="mb-2 signup-link text-center">
                        <Link to="/login">Login Page</Link>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}
