import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux";
import { userFeed } from "../../redux/action";
import {
  ceateComment,
  clearPostData,
  getCommentByPostId,
  getPostById,
} from "../../redux/action/postActions";
import { S3_BASE_URL } from "../../service/aws/config";
import Profile from "../../assets/images/imgs/user-round.svg";
import { POST_TYPE } from "../../constants/App-constant";
import Send from "../../assets/images/icons/send.svg";
import moment from "moment";
import AudioPlayer from "../AudioPlayer";
import Slider from "react-slick";
import { v4 as uuid } from "uuid";

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
};

export default function TeaserModel({
  showTeaser,
  setShowTeaser,
  postId,
}: any) {
  const { postData, commentData, isCommentCreated, loading }: any = useSelector(
    (state: Store) => state.postDataReducer
  );
  const dispatch = useDispatch<AppDispatch>();
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (showTeaser) {
      dispatch(getPostById(postId));
      dispatch(getCommentByPostId(postId));
    } else {
      dispatch(clearPostData());
    }
  }, [showTeaser]);
  useEffect(() => {}, [postData]);
  useEffect(() => {
    setComment("");
    dispatch(getCommentByPostId(postId));
  }, [isCommentCreated]);

  const [commentId, setCommentId] = useState<any>({});

  return (
    <Modal
      show={showTeaser}
      onHide={() => setShowTeaser(false)}
      centered
      className="custom-modal comment-modal teaser-modal"
    >
      <Modal.Body>
        {/* <div className="main-comment-box">
          <div className="left-section cmt-img-wrapper"> */}
          
            {postData?.post_type === POST_TYPE.POST_TYPE_VIDEO && (
            
          
            <video
              src={ S3_BASE_URL + postData?.video_teaser}
              width="100%"
              height="100%"
              autoPlay={false}
              controls={true}
            />
          
            )
            
            }

            {postData?.post_type === POST_TYPE.POST_TYPE_AUDIO && (
              <div className="audio-post-modal-container">
                <div className="audio-thumb">
                  <img
                    src={S3_BASE_URL + postData?.thumb_image}
                    className="img-fluid"
                    alt="bgimg"
                  />
                </div>
                <div className="audio-sound">
                  <div className="sound-control">
                    <AudioPlayer
                      track={S3_BASE_URL + postData?.post_type_link}
                    />
                  </div>
                </div>
              </div>
            )}
          {/* </div>
        </div> */}
      </Modal.Body>
    </Modal>
  );
}
