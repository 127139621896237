import { ThunkAction } from 'redux-thunk';
import { GO_LIVE } from '../constants/action-types';
import { Store } from '../Actions';
import { Action } from 'redux';

export const startLiveStreaming = (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
  dispatch({
    type: GO_LIVE.START_LIVE_STREAMING,
  });
};

export const stopLiveStreaming = (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
  dispatch({
    type: GO_LIVE.START_LIVE_STREAMING,
  });
};

export const joinLiveStreaming =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GO_LIVE.JOIN_LIVE_STREAM,
      payload: res,
    });
  };

export const exitLiveStreaming = (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
  dispatch({
    type: GO_LIVE.EXIT_LIVE_STREAM,
    payload: {},
  });
};

export const setHostToken =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GO_LIVE.SET_HOST_TOKEN,
      payload: res,
    });
  };

export const setSubscriberToken =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch: any) => {
    dispatch({
      type: GO_LIVE.SET_SUBSCRIBER_TOKEN,
      payload: res,
    });
  };
