/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { documentVerify, getVerificationStatus, login } from '../redux/action';
import { useFormik } from 'formik';
import { Store } from '../redux/Actions';
import { AppDispatch } from '../redux';
import { useNavigate } from 'react-router-dom';
import VerifiedIcon from '../assets/images/icons/status_icon.svg';
import { documentList } from '../utils/constant';
import { dumentVerificationSchema } from '../validationScrema/settings';
import { uploadFileAmazonS3 } from '../service/index.';
import { S3_BASE_URL } from '../service/aws/config';
import { MEDIA_SIZE, VERIFY_STATUS } from '../constants/App-constant';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Loader from './Loader';
import { toast } from 'react-toastify';

export default function VerifyProfile() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loader1, setLoader1] = useState<boolean>(false);
  const verificationStatus: any = useSelector(
    (state: Store) => state.settingsDataReducer.verificationStatus
  );
  const loading: any = useSelector(
    (state: Store) => state.settingsDataReducer.loading
  );
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getVerificationStatus());
  }, [dispatch]);
  const inputRef: any = useRef(null);
  const inputRef2: any = useRef(null);

  const formik = useFormik({
    initialValues: {
      image: '',
      user_document_type: 0,
      selfie_image: '',
    },
    validationSchema: dumentVerificationSchema,
    onSubmit: async (values) => {
      if (values?.image && values?.selfie_image) {
        let imageData: any;
        let selfie_imageData: any;

        await uploadFileAmazonS3(
          new File([values?.selfie_image], 'selfie_image')
        ).then((data) => {
          selfie_imageData = data;
        });
        if (imageData && selfie_imageData) {
          values = {
            ...values,
            image: imageData?.Location,
            selfie_image: selfie_imageData?.Location,
          };
        }
      }
      dispatch(documentVerify(values));
    },
  });
  const { errors, touched, setFieldValue } = formik;
  const onImageChange = async (event: any) => {
    const imgFile: any = event.target.files[0];
    setLoader1(true);
    if (imgFile?.size / 1024 < MEDIA_SIZE.IMAGE_SIZE ) {
      if (event.target.files && event.target.files[0]) {
        if (event.target.name === 'image') {
          await uploadFileAmazonS3(event.target.files[0]).then((data) => {
            setFieldValue('image', data.Key);
            setLoader1(false);
          });
        } else {
          await uploadFileAmazonS3(event.target.files[0]).then((data) => {
            setFieldValue('selfie_image', data.Key);
            setLoader1(false);
          });
        }
      }
    } else {
      setLoader1(false);
      toast.error(
        `Please upload less the ${MEDIA_SIZE.IMAGE_SIZE / 1024} MB Image File`
      );
    }
  };

  return (
    <>
      {(loading || loader1) && <Loader />}
      <h2 className="accordion-header d-lg-none" id="headingThree">
        <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#verify"
          aria-expanded="false"
          aria-controls="verify"
        >
          {t('verifyprofile.verifyprofile')}
        </button>
      </h2>
      <div
        id="verify"
        className="accordion-collapse collapse d-lg-block"
        aria-labelledby="headingThree"
        data-bs-parent="#myTabContent"
      >
        <div className="accordion-body">
          <div className="box-container">
            <h2 className="setting-title">
              {t('verifyprofile.verifyprofile')}
            </h2>
            <div className="verification-content">
              <h3>{t('verifyprofile.profile verification status')}</h3>
              <div className="status d-flex align-items-center justify-content-between">
                <div className="status-title">
                  <img src={VerifiedIcon} className="img-fluid" />
                  <span>{t('verifyprofile.status')}</span>
                </div>
                <button className="btn btn-applied">
                  {verificationStatus?.status ===
                    VERIFY_STATUS.VERIFY_STATUS_PENDING &&
                    t('verifyprofile.pending')}
                  {verificationStatus?.status ===
                    VERIFY_STATUS.VERIFY_STATUS_SUCCESS &&
                    t('verifyprofile.verified')}
                  {verificationStatus?.status ===
                    VERIFY_STATUS.VERIFY_STATUS_FAILED &&
                    t('verifyprofile.reject')}
                  {verificationStatus?.status ===
                    VERIFY_STATUS.VERIFY_STATUS_NOT_APPLIED &&
                    t('verifyprofile.not applied')}
                </button>
              </div>
              {verificationStatus.hand_written_comment && (
                <h5 className="varificsation-reject-message">
                  {verificationStatus.hand_written_comment !== 'Not Applied' &&
                  verificationStatus.status !== 4
                    ? ` Rejection Reason : ${verificationStatus.hand_written_comment}`
                    : ''}
                </h5>
              )}
            </div>
            {verificationStatus?.status ===
            1 ? null : verificationStatus?.status === 0 ? null : (
              <form onSubmit={formik.handleSubmit}>
                <div className="verification-content profile-verification">
                  <h3>{t('verifyprofile.apply for profile verification')}</h3>
                  <div className="document-verification">
                    <h4>{t('verifyprofile.your government issued id')}</h4>
                    <div
                      className="select-document"
                      style={{
                        backgroundImage: `url(${
                          formik.values.image &&
                          S3_BASE_URL + formik.values.image
                          // : BackImage
                        })`,
                      }}
                    >
                      <input
                        style={{ display: 'none' }}
                        ref={inputRef}
                        type="file"
                        name="image"
                        onChange={onImageChange}
                      />

                      <button
                        className="btn btn-select"
                        type="button"
                        onClick={() => inputRef?.current?.click()}
                      >
                        {formik.values.image
                          ? t('verifyprofile.change')
                          : t('verifyprofile.select')}
                      </button>
                      {/* <button className="btn btn-select">Select</button> */}
                    </div>
                    {errors.image && touched.image && (
                      <div className="text-danger mt-2">{errors.image}</div>
                    )}
                    <div className="upload-document">
                      <p>
                        {t(
                          'verifyprofile.Select type of document you’ve uploaded'
                        )}
                      </p>
                      <div>
                        {documentList.map((data: any) => {
                          return (
                            <div className="form-check" key={data.id}>
                              <label
                                className="form-check-label"
                                htmlFor={`goveId-${data.id}`}
                              >
                                {t(`verifyprofile.${data.value}`)}
                              </label>
                              <input
                                className=""
                                type="radio"
                                name="user_document_type"
                                value={data.id}
                                onChange={formik.handleChange}
                                id={`goveId-${data.id}`}
                                // checked
                              />
                            </div>
                          );
                        })}
                        {errors.user_document_type &&
                          touched.user_document_type && (
                            <div className="text-danger mt-2">
                              {errors.user_document_type}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="verification-content profile-verification">
                  <div className="document-verification">
                    <h4>
                      {t(
                        'verifyprofile.Your selfie with your ID and handwritten note'
                      )}
                    </h4>
                    <ul>
                      <li>
                        <p className="mb-0">
                          {t('verifyprofile.selfie with id role 1')}
                        </p>
                      </li>
                      <li>
                        <p className="mb-0">
                          {t('verifyprofile.selfie with id role 2')}
                        </p>
                      </li>
                      <li>
                        <p className="mb-0">
                          {t('verifyprofile.selfie with id role 3')}
                        </p>
                      </li>
                    </ul>

                    <div
                      className="select-document"
                      style={{
                        backgroundImage: `url(${
                          formik.values.selfie_image &&
                          S3_BASE_URL + formik.values.selfie_image
                          // : BackImage
                        })`,
                      }}
                    >
                      <input
                        style={{ display: 'none' }}
                        ref={inputRef2}
                        type="file"
                        name="selfie_image"
                        onChange={onImageChange}
                      />

                      <button
                        className="btn btn-select"
                        type="button"
                        onClick={() => inputRef2?.current?.click()}
                      >
                        {formik.values.selfie_image
                          ? t('verifyprofile.change')
                          : t('verifyprofile.select')}
                      </button>
                      {/* <button className="btn btn-select">Select</button> */}
                    </div>
                    {errors.selfie_image && touched.selfie_image && (
                      <div className="text-danger mt-2">
                        {errors.selfie_image}
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center verification-btn">
                  <Button className="btn btn-login" type="submit">
                    {t('verifyprofile.apply')}
                  </Button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
