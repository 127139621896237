import PostFeed from "../components/PostFeed";
import { AppDispatch } from "../redux";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { userDashboardFeed, userDetail, userFeed } from "../redux/action";
import { getToken } from "../utils/utility";
import { useNavigate } from "react-router-dom";
import CreatePost from "../components/CreatePost";
import { Store } from "../redux/Actions";
import { favouriteList } from "../redux/action/postActions";
export default function Favourite() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [feedList, setFeedList] = useState<any>([]);
  const isLoggedIn = getToken("authToken") ? true : false;
  const { isAddedToUnfavourite, isAddedTofavourite }: any = useSelector((state: Store) => state.postDataReducer);
  const userFavouriteList: any = useSelector((state: Store) => state.postDataReducer?.favouriteList);
  const loading: any = useSelector((state: Store) => state.postDataReducer.loading);
  const [play, setPlay] = useState(false);

  if (isLoggedIn === false) {
    navigate("/login");
  }

  // useEffect(() => {
  //   const userId = getToken("userId");
  //   dispatch(userProfileData(Number(userId)));
  // }, []);
  useEffect(() => {
    if(page == 1){
      window.scrollTo(0, 0);
    }
  }, [page]);
  useEffect(() => {
    dispatch(favouriteList(page));
    // setFeedList(userFavouriteList);
    // feedUpdate()
  }, [page, isAddedToUnfavourite, isAddedTofavourite]);
  // useEffect(() => {
  //   // if(feedList.length<=0){
  //   // setFeedList(userFeedListData)
  //   setFeedList(userFavouriteList);
  //   // }
  // }, [isAddedToUnfavourite, isAddedTofavourite]);

  return (
    <>
      <div className='center-box'>
        <PostFeed setPage={setPage} page={page} feedList={userFavouriteList} userFeedListData={userFavouriteList} fromPage={"favourite"}  onClick={() => console.log("")}/>
      </div>
    </>
  );
}
