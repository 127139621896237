import React from 'react';
import { getToken } from '../utils/utility';
import TickIcon from '../assets/images/icons/right-blue.svg';
import { useDispatch } from 'react-redux';
import { translate } from '../redux/action';
import { AppDispatch } from '../redux';
import { useTranslation } from 'react-i18next';

export default function Language() {
  const { t, i18n } = useTranslation();
  const local = localStorage.getItem('local') || 'en';
  const dispatch = useDispatch<AppDispatch>();
  const changeLanguage = (lang: any) => {
    i18n.changeLanguage(lang);
    dispatch(translate(lang));
  };

  return (
    <>
      <h2 className="accordion-header d-lg-none" id="headingThree">
        <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#language"
          aria-expanded="false"
          aria-controls="language"
        >
          Language
        </button>
      </h2>
      <div
        id="language"
        className="accordion-collapse collapse d-lg-block"
        aria-labelledby="headingThree"
        data-bs-parent="#myTabContent"
      >
        <div className="accordion-body">
          <div className="box-container">
            {/* <!-- <h2 className="setting-title">Language</h2> --> */}
            <div className="language">
              <ul className="ps-0">
                <li
                  className="language-item px-0"
                  onClick={() => changeLanguage('en')}
                >
                  <a
                    className={
                      local === 'en' ? `language-link active` : 'language-link'
                    }
                  >
                    <p className="mb-0">
                      <span className="d-block">English</span>
                      <span>Quick brown fox jumps over the lazy dog.</span>
                    </p>
                    <img src={TickIcon} className="img-fluid check-sign" />
                  </a>
                </li>
                <li
                  className="language-item px-0"
                  onClick={() => changeLanguage('es')}
                >
                  <a
                    className={
                      local === 'es' ? `language-link active` : 'language-link'
                    }
                  >
                    <p className="mb-0">
                      <span className="d-block">Spanish</span>
                      <span>
                        El zorro marrón rápido salta sobre el perro perezoso.
                      </span>
                    </p>
                    <img src={TickIcon} className="img-fluid check-sign" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
