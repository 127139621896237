import React from "react";
import AsyncSelect from "react-select/async";

interface Props {
  isMulti: boolean;
  onSearch: (value: string, callback: any) => void;
  onChange?: (value: any) => void;
  value?: any;
  placeholder: string;
}

const SearchableSelect: React.FC<Props> = ({
  isMulti,
  onChange,
  onSearch,
  value,
  placeholder,
}) => {
  const loadOptions = (key: any, callback: any) => {
    onSearch(key, (data: any) => {
      callback(data);
    });
  };
  return (
    <AsyncSelect
      isMulti={isMulti || false}
      cacheOptions
      loadOptions={loadOptions}
      defaultOptions
      value={value}
      options={[]}
      closeMenuOnScroll
      closeMenuOnSelect
      placeholder={placeholder}
      hideSelectedOptions
      onChange={onChange}
      noOptionsMessage={() => 'Loading...'}
      components={{
        ClearIndicator: () => <></>,
        DropdownIndicator: () => <></>,
        IndicatorSeparator: () => <></>,
      }}
      styles={{
        container: (provided) => ({ ...provided, width: "100%" }),
        control: (provided) => ({
          ...provided,
          boxShadow: "none",
          cursor: "pointer",
          borderColor: "transparent",
        }),
      }}
    />
  );
};

export default SearchableSelect;

