import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Logo from '../assets/images/imgs/logo.svg';
import SearchIcon from '../assets/images/icons/ico_search.svg';
import LiveIcon from '../assets/images/icons/ico_live.svg';
import WalletIcon from '../assets/images/icons/ico_wallet.svg';
import Profile from '../assets/images/imgs/profile-user.svg';
import MenuIcon from '../assets/images/icons/ico_menu.svg';
import HomeIcon from '../assets/images/icons/ico_home.svg';
import HomeBlue from '../assets/images/icons/ico_home_blue.svg';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux';
import {
  logout,
  startLiveStreaming,
  userDetail,
  userProfileData,
} from '../redux/action';
import SearchInput from './SearchInput';
import { Store, UserData } from '../redux/Actions';
import { S3_BASE_URL } from '../service/aws/config';
import { getToken } from '../utils/utility';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { createImageFromInitials, getRandomColor } from '../utils/createImage';
import { StartLiveStreaming } from './models/StartLiveStreaming';
import GoLiveOld from './models/GoLiveOld';
import { JoinStreaming } from './JoinStreaming';
import { ReactComponent as ChatBlueIcon } from '../assets/images/icons/ico_chat_blue.svg';
import { ReactComponent as ChatBlueIconInActive } from '../assets/images/icons/ico_chat_in_active.svg';
import { ReactComponent as MapBlueIcon } from '../assets/images/icons/ico_map_blue.svg';
import { ReactComponent as MapBlueIconInActive } from '../assets/images/icons/ico_map_blue_In_active.svg';
import { ReactComponent as NotificationBlueIcon } from '../assets/images/icons/ico_notification_blue.svg';
import { ReactComponent as NotificationBlueIconInActive } from '../assets/images/icons/ico_notification_blue_in_active.svg';
import { ReactComponent as BookmarkBlueIcon } from '../assets/images/icons/ico_bookmark_blue.svg';
import { ReactComponent as BookmarkBlueIconInActive } from '../assets/images/icons/ico_bookmark_in_active.svg';
import { ReactComponent as SettingsBlueIcon } from '../assets/images/icons/ico_setting_blue.svg';
import { ReactComponent as SettingsBlueIconInActive } from '../assets/images/icons/ico_setting_in_active.svg';

export default function Header() {
  const ref: any = useRef();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const firstName: any = useSelector(
    (state: Store) => state.userDataReducer.firstName
  );
  const lastName: any = useSelector(
    (state: Store) => state.userDataReducer.lastName
  );
  const profilePhoto: any = useSelector(
    (state: Store) => state.userDataReducer.profilePhoto
  );
  const isLiveStreaming: boolean = useSelector(
    (state: Store) => state.goLiveReducer.isLiveStreaming
  );
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showGoLiveModel, setShowGoLiveModel] = useState(false);
  const [activeId, setActiveId] = useState<string>('1');
  const [profileDropDown, setProfileDropDown] = useState(false);
  // useEffect(() => {
  //   const handleClickOutside = (event: any) => {
  //     setProfileDropDown(false);
  //   };
  //   document.addEventListener('click', handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside, true);
  //   };
  // }, [profileDropDown]);
  const location: any = useLocation();
  useEffect(() => {
    if (location.pathname === '/home') {
      setActiveId('1');
    } else if (location.pathname === '/messages') {
      setActiveId('2');
    } else if (location.pathname === '/explore') {
      setActiveId('3');
    } else if (location.pathname === '/notification') {
      setActiveId('4');
    } else if (location.pathname === '/favourite') {
      setActiveId('5');
    } else if (location.pathname === '/settings/edit-profile') {
      setActiveId('6');
    } else {
      setActiveId('1');
    }
  }, []);

  const showLiveStreamingPopup = () => {
    setIsPopupOpen(true);
    // dispatch(startLiveStreaming())
  };
  const userId = getToken('userId');
  useEffect(() => {
    // const userId = getToken("userId");
    dispatch(userProfileData(Number(userId)));
  }, []);
  const handleLogoutUser = async () => {
    dispatch(logout());
    setProfileDropDown(false);
    localStorage.clear();
    sessionStorage.clear();
    navigate('/login');
    window?.location?.reload();
  };
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (profileDropDown && ref.current && !ref.current.contains(e.target)) {
        setProfileDropDown(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [profileDropDown]);
  return (
    <header className="header">
      <nav className="navbar navbar-expand-md">
        <div className="container-md">
          <a className="navbar-brand p-0" href="#">
            <img src={Logo} className="img-fluid" />
          </a>
          <div className="center-part-content d-flex align-items-center">
            <div className="search-container">
              <div className="input-group search-box d-none d-md-flex">
                <img src={SearchIcon} className="img-fluid" alt="search" />
                <div className="header-search-icon-width">
                  <SearchInput />
                </div>
              </div>
              <div className="search-mobile d-block d-md-none">
                <div className="search-dropdown">
                  <button
                    className="btn btn-dropdown dropdown-toggle p-0"
                    type="button"
                    id="searchButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="user-icon">
                      <img
                        alt="search"
                        src={SearchIcon}
                        className="img-fluid"
                        width="18px"
                      />
                    </div>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="searchButton1">
                    <li>
                      <div className="input-group search-box-mobile">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={`${t('home.search something')}`}
                          aria-label="Search something"
                          aria-describedby="button-addon2"
                        />
                        <button
                          className="btn btn-search"
                          type="button"
                          id="button-addon2"
                        >
                          Search
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="live-btn" onClick={() => showLiveStreamingPopup()}>
              <button className="btn btn-live">
                <img alt="live" src={LiveIcon} className="img-fluid" />
                <span>{t('home.go live')}</span>
              </button>
            </div>
            <div className="wallet-icon">
              <button className="btn btn-wallet ml-2">
                <Link to={'/messages'}>
                  <ChatBlueIcon />
                </Link>
              </button>
            </div>

            {/* <!-- mobile view menu --> */}
            <div className="menu-dropdown d-block d-md-none">
              <button
                className="btn btn-dropdown dropdown-toggle p-0"
                type="button"
                id="menu-dropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="user-icon">
                  <img alt="menu" src={MenuIcon} className="img-fluid" />
                </div>
              </button>
              <ul className="dropdown-menu" aria-labelledby="menu-dropdown">
                <li
                  className="nav-item"
                  id="1"
                  onClick={(e) => {
                    setActiveId(e.currentTarget.id);
                  }}
                >
                  <Link
                    className={`nav-link  ${activeId === '1' && 'active'}`}
                    to={'/home'}
                    id="6"
                  >
                    <img
                      src={HomeIcon}
                      alt="home"
                      className="img-fluid img-icon"
                    />
                    <img
                      src={HomeBlue}
                      alt="home"
                      className="img-fluid img-icon-active"
                    />
                    <span>Home Feed</span>
                  </Link>
                </li>
                <li
                  className="nav-item"
                  id="2"
                  onClick={(e) => {
                    setActiveId(e.currentTarget.id);
                  }}
                >
                  <Link
                    className={`nav-link  ${activeId === '2' && 'active'}`}
                    to={'/messages'}
                    id="6"
                  >
                    {activeId === '2' ? (
                      <ChatBlueIcon />
                    ) : (
                      <ChatBlueIconInActive />
                    )}
                    <span>Messages</span>
                  </Link>
                </li>
                <li
                  className="nav-item"
                  id="3"
                  onClick={(e) => {
                    setActiveId(e.currentTarget.id);
                  }}
                >
                  <Link
                    className={`nav-link  ${activeId === '3' && 'active'}`}
                    to={'/explore'}
                    id="3"
                  >
                    {activeId === '3' ? (
                      <MapBlueIcon />
                    ) : (
                      <MapBlueIconInActive />
                    )}
                    <span>Explore</span>
                  </Link>
                </li>
                <li
                  className="nav-item"
                  id="4"
                  onClick={(e) => {
                    setActiveId(e.currentTarget.id);
                  }}
                >
                  <Link
                    className={`nav-link  ${activeId === '4' && 'active'}`}
                    to={'/notification'}
                    id="4"
                  >
                    {activeId === '4' ? (
                      <NotificationBlueIcon />
                    ) : (
                      <NotificationBlueIconInActive />
                    )}
                    <span>Notifications</span>
                  </Link>
                </li>
                <li
                  className="nav-item"
                  id="5"
                  onClick={(e) => {
                    setActiveId(e.currentTarget.id);
                  }}
                >
                  <Link
                    className={`nav-link  ${activeId === '5' && 'active'}`}
                    to={'/favourite'}
                    id="5"
                  >
                    {activeId === '5' ? (
                      <BookmarkBlueIcon />
                    ) : (
                      <BookmarkBlueIconInActive />
                    )}
                    <span>Favorites</span>
                  </Link>
                </li>
                <li
                  className="nav-item"
                  id="6"
                  onClick={(e) => {
                    setActiveId(e.currentTarget.id);
                  }}
                >
                  <Link
                    className={`nav-link  ${activeId === '6' && 'active'}`}
                    to={'/settings/edit-profile'}
                    id="6"
                  >
                    {activeId === '6' ? (
                      <SettingsBlueIcon />
                    ) : (
                      <SettingsBlueIconInActive />
                    )}
                    <span>Settings</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="right-part-content d-none d-md-block">
            <div className="user-details">
              <div className="user-dropdown">
                <button
                  onClick={() => setProfileDropDown(!profileDropDown)}
                  className="btn btn-dropdown dropdown-toggle"
                  type="button"
                >
                  <div className="user-icon">
                    <img
                      // src={profilePhoto ? S3_BASE_URL + profilePhoto : Profile}
                      src={
                        profilePhoto
                          ? S3_BASE_URL + profilePhoto
                          : createImageFromInitials(
                              500,
                              firstName + ' ' + lastName,
                              getRandomColor()
                            )
                      }
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <span className="user-name">
                    {firstName}
                    {/* {userDetail?.last_name} */}
                  </span>
                </button>
                {profileDropDown && (
                  // <div >
                  <ul className="profile-plogout-togen" ref={ref}>
                    <li onClick={() => setProfileDropDown(false)}>
                      <Link
                        to="/my-profile"
                        className="dropdown-item"
                        style={{ fontSize: '17px', paddingRight: '85px' }}
                      >
                        Profile
                      </Link>
                    </li>
                    <li>
                      <div
                        style={{ fontSize: '17px', paddingRight: '85px' }}
                        className="dropdown-item"
                        onClick={handleLogoutUser}
                      >
                        Logout
                      </div>
                    </li>
                  </ul>
                  // </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
      <StartLiveStreaming
        show={isPopupOpen}
        onHide={() => setIsPopupOpen(false)}
      />
      {/* <GoLiveOld
        showPostComment={showGoLiveModel}
        setShowPostComment={setShowGoLiveModel}
      /> */}
    </header>
  );
}
