import React from "react";
import ReactDOM from "react-dom";
import { loadStripe, Stripe, StripeElements } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentForm";

const stripePromise = loadStripe(
  "pk_test_51MwNEJLNYcEIq7mickqYPPpoJODd190w1OK700Emf3ehSEpZSnAZbUgBqujnQ03u8tVlJjPgREDvW9zqVfuoUcT300gsvLDIyO"
);

interface CheckOutFormDetails{
  // amountAddedByUser: number;
  packageId :number;
}
const CheckoutForm :React.FC<CheckOutFormDetails> = ({packageId})  => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm packageId={packageId}/>
    </Elements>
  );
};

// const App = () => (
//   <Elements stripe={stripePromise}>
//     <CheckoutForm />
//   </Elements>
// );

export default CheckoutForm;
