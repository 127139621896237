import React from "react";
import user from "../assets/images/imgs/user1.png";
import coins from "../assets/images/imgs/coins.svg";
import { useTranslation } from "react-i18next";
import Avatar from "react-avatar";

const UnlockContent = ({
  unlockcontent,
  unlockRef,
  amount,
  unlockPackage,
  currentPaidMedaiOrPackagedata,
}: any) => {
  const { author } = currentPaidMedaiOrPackagedata
  const { t } = useTranslation()
  return (
    <div
      ref={unlockRef}
      className={` post-modal file-upload unlock-popup unlock-content-popup ${unlockcontent ? "show-post-modal" : ""
        }`}
    >
      <h4 className="position-relative">
        <p>Unlock content</p>
        <i className="las la-times position-absolute" style={{ right: "4px" }}></i>
      </h4>

      <div className="uer-details">
        <Avatar
          round={true}
          src={''}
          alt="profile picture"
          size="50"
          name={author?.firstName + " " + author?.lastName}
          maxInitials={2}
        />
        <h6>{author?.firstName + " " + author?.lastName}</h6>
      </div>
      <div className="coins">
        <h6>
          <img src={coins} alt="coin" /> {amount}
        </h6>
      </div>
      <div className="modal-footer justify-content-center border-0 pt-0">
        <button
          type="button"
          className="btn btn-login px-4 mt-0"
          // data-bs-dismiss="modal"
          onClick={unlockPackage}
        >
          {t("home.unlock")}
        </button>
      </div>
    </div>
  );
};
export default UnlockContent;

