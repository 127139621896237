import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { apiCall, METHOD } from '../../service/baseApiCall';
import { Store } from '../Actions';
import { WALLET_URL } from '../ApiEndPoints';
import { WALLET } from '../constants/action-types';

export const wallet = (page:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
  dispatch(walletInit(page));

};

export const walletInit = (page:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
  dispatch({
    type: WALLET.WALLET_INITLIZATION
  });
  apiCall(
    WALLET_URL,
    {page},
    (res: any) => {
      dispatch(walletSuccess(res,page))},
    (err:any) =>{
      dispatch(walletError(err))},
    METHOD.POST,
    {}
  );
};

export const walletSuccess = (res:any,page:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
    dispatch({
      type:WALLET.WALLET_SUCCESS,
      payload:{res,page}
    });
  };

export const walletError = (err:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
    dispatch({
      type:WALLET.WALLET_ERORR
    });
  };