import React from "react";
import { Form, Button } from "react-bootstrap";
import leftArrow from "../assets/images/icons/left-arrow.svg";

const CreateNewPackage = ({
  handleCreate,
  submitPackage,
  packageName,
  packagePrice,
  setPackageName,
  setPackagePrice,
  t,
  errors,
}) => {
  const onChangeHandler = (event) => {
    const value = event.target.value;
    if (!/^\d+$/.test(value) && value.length) return;
    setPackagePrice(value);
  };
  return (
    <div className="create-package">
      <div className="heading-text">
        <img src={leftArrow} alt="leftArrow" onClick={handleCreate} />
        <h6>{t("paidpackage.createnewpackage")}</h6>
      </div>
      <div className="create-package-box">
        <form onSubmit={submitPackage}>
          <div className="create-package-box">
            <Form.Group className="mb-2" controlId="formBasicPassword">
              <Form.Label>{t("paidpackage.packagename")}</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="packageName"
                value={packageName}
                onChange={(e) => setPackageName(e.target.value)}
              />
              {errors.packageName && (
                <div className="text-danger edit-form-error">{errors.packageName}</div>
              )}
            </Form.Group>
            <Form.Group className="mb-2" controlId="formBasicPassword">
              <Form.Label>{t("paidpackage.packageprice")}</Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                name="media_package_price"
                value={packagePrice}
                onChange={onChangeHandler}
              />
              {errors.packagePrice && (
                <div className="text-danger edit-form-error">{errors.packagePrice}</div>
              )}
            </Form.Group>

            <Button type="submit" onClick={(e) => submitPackage(e)}>
              {t("paidpackage.next")}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default CreateNewPackage;
