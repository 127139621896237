/* eslint-disable import/no-anonymous-default-export */
import {
  DOCUMENT_VERIFICATION,
  GET_VERIFICATION_STATUS,
  USER_SUBSCRIPTION,
  GET_PAID_PACKAGE,
  CREATE_PAID_PACKAGE,
  PAID_PACKAGE_BY_ID,
  EDIT_PAID_PACKAGE,
  CREATE_MEDIA_PACKAGE,
  GET_MEDIA_PACKAGE_BY_ID,
  REMOVE_MEDIA_FROM_PACKAGE,
  GET_ENV_VARIABLES,
} from '../constants/action-types';
import { SettingsData } from '../Actions';
import { ActionTypes } from '../action/userActions';

const initialState = {
  loading: false,
  isLoggedin: false,
  verificationStatus: {},
  isDocumetVerificationSuccess: false,
  userSubscriptionList: [],
  paidPackageList: [],
  isPackageCreatedSuccess: false,
  isPackageEditedSuccess: false,
  packageData: {},
  isMediaCreatedSuccess: false,
  mediaContentData: [],
  envVariables: {},
  isDeleted: false,
  IsImageUpload: false,
};
export default (state: SettingsData = initialState, action: ActionTypes) => {
  switch (action.type) {
    case GET_VERIFICATION_STATUS.GET_VERIFICATION_STATUS_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case GET_VERIFICATION_STATUS.GET_VERIFICATION_STATUS_SUCCESS:
      return {
        ...state,
        // ...action.payload,
        verificationStatus: action.payload,
        loading: false,
      };
    case GET_VERIFICATION_STATUS.GET_VERIFICATION_STATUS_ERORR:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case DOCUMENT_VERIFICATION.DOCUMENT_VERIFICATION_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case DOCUMENT_VERIFICATION.DOCUMENT_VERIFICATION_SUCCESS:
      return {
        ...state,
        // ...action.payload,
        isDocumetVerificationSuccess: true,
        verificationStatus: {
          ...state.verificationStatus,
          status: 0,
          hand_written_comment: null,
        },
        loading: false,
      };
    case DOCUMENT_VERIFICATION.DOCUMENT_VERIFICATION_ERORR:
      return {
        ...state,
        ...action.payload,
        isDocumetVerificationSuccess: false,
        loading: false,
      };
    case USER_SUBSCRIPTION.USER_SUBSCRIPTION_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case USER_SUBSCRIPTION.USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        // ...action.payload,

        userSubscriptionList: action.payload,
        loading: false,
      };
    case USER_SUBSCRIPTION.USER_SUBSCRIPTION_ERORR:
      return {
        ...state,
        ...action.payload,
        // userSubscriptionList:false,
        loading: false,
      };
    case GET_PAID_PACKAGE.GET_PAID_PACKAGE_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case GET_PAID_PACKAGE.GET_PAID_PACKAGE_SUCCESS:
      return {
        ...state,
        // ...action.payload,
        paidPackageList: action.payload,
        loading: false,
      };
    case GET_PAID_PACKAGE.GET_PAID_PACKAGE_ERROR:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case CREATE_PAID_PACKAGE.CREATE_PAID_PACKAGE_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
        isPackageCreatedSuccess: false
      };
    case CREATE_PAID_PACKAGE.CREATE_PAID_PACKAGE_SUCCESS:
      return {
        ...state,
        // ...action.payload,
        isPackageCreatedSuccess: true,
        loading: false,
      };
    case CREATE_PAID_PACKAGE.CREATE_PAID_PACKAGE_ERORR:
      return {
        ...state,
        ...action.payload,
        isPackageCreatedSuccess: false,
        loading: false,
      };

    case PAID_PACKAGE_BY_ID.PAID_PACKAGE_BY_ID_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case PAID_PACKAGE_BY_ID.PAID_PACKAGE_BY_ID_SUCCESS:
      return {
        ...state,
        // ...action.payload,
        packageData: action.payload,
        loading: false,
      };
    case PAID_PACKAGE_BY_ID.PAID_PACKAGE_BY_ID_ERORR:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };

    case EDIT_PAID_PACKAGE.EDIT_PAID_PACKAGE_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
        isPackageEditedSuccess: false
      };
    case EDIT_PAID_PACKAGE.EDIT_PAID_PACKAGE_SUCCESS:
      return {
        ...state,
        // ...action.payload,
        isPackageEditedSuccess: true,
        loading: false,
      };
    case EDIT_PAID_PACKAGE.EDIT_PAID_PACKAGE_ERORR:
      return {
        ...state,
        ...action.payload,
        isPackageEditedSuccess: false,
        loading: false,
      };
    case CREATE_MEDIA_PACKAGE.CREATE_MEDIA_PACKAGE_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
        IsImageUpload: false,
      };
    case CREATE_MEDIA_PACKAGE.CREATE_MEDIA_PACKAGE_SUCCESS:
      return {
        ...state,
        // ...action.payload,
        isMediaCreatedSuccess: true,
        loading: false,
        IsImageUpload: true,
      };
    case CREATE_MEDIA_PACKAGE.CREATE_MEDIA_PACKAGE_ERORR:
      return {
        ...state,
        ...action.payload,
        isMediaCreatedSuccess: false,
        loading: false,
        IsImageUpload: false,
      };
    case GET_MEDIA_PACKAGE_BY_ID.MEDIA_PACKAGE_BY_ID_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case GET_MEDIA_PACKAGE_BY_ID.MEDIA_PACKAGE_BY_ID_SUCCESS:
      return {
        ...state,
        ...action.payload,
        mediaContentData: action.payload,
        loading: false,
      };
    case GET_MEDIA_PACKAGE_BY_ID.MEDIA_PACKAGE_BY_ID_ERORR:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case REMOVE_MEDIA_FROM_PACKAGE.REMOVE_FROM_MEDIA_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        isDeleted: false,
        loading: true,
      };
    case REMOVE_MEDIA_FROM_PACKAGE.REMOVE_FROM_MEDIA_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false,
      };
    case REMOVE_MEDIA_FROM_PACKAGE.REMOVE_FROM_MEDIA_ERORR:
      return {
        ...state,
        ...action.payload,
        isDeleted: false,
        loading: false,
        // searchUserList:[]
      };
    case GET_ENV_VARIABLES.GET_ENV_VARIABLES_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case GET_ENV_VARIABLES.GET_ENV_VARIABLES_SUCCESS:
      return {
        ...state,
        // ...action.payload,
        envVariables: action.payload,
        loading: false,
      };
    case GET_ENV_VARIABLES.GET_ENV_VARIABLES_ERROR:
      return {
        ...state,
        ...action.payload,
        envVariables: {},
        loading: false,
      };
    default:
      return state;
  }
};
