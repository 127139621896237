export const LOGIN_URL = "/api/login";
export const REGISTER_URL = "/api/register";
export const OTP_VERIFICATION_URL = "/api/verify-otp";
export const RESEND_OTP_URL = "/api/resend-otp-verify-user";
export const RESET_PASSWORD_URL = "/api/reset-password";
export const FORGET_PASSWORD_URL = "/api/forgot-password";
export const USER_DETAIL_URL = "/api/user/user-byid";
export const LOGOUT_URL = "/api/logout";
export const OTP_USER_VERIFICATION_URL = "/api/verify-user";
export const AMAZON_UPLOAD_URL = "/api/upload";

export const USER_TRANDING_FEEDS_URL = "/api/feeds/trending?page=1";
export const USER_FEEDS_URL = "/api/feeds/user";
export const USER_TRANDING_URL = "/api/user/trending?page=1";
export const GET_CONTRY_URL = "/api/settings/country";
export const GET_STATE_URL = "/api/settings/state";
export const EDIT_PROFILE_URL = "/api/user/edit-profile";
export const CHANGE_PASSWORD_URL = "/api/user/change-password";
export const GET_VERIFICATION_STATUS_URL = "/api/user/verification-status";
export const DOCUMENT_VERIFICATION_URL = "/api/user/upload-document";
export const USER_SUBSCRIPTION_URL = "/api/user/my-subscription-new";
export const RENEW_SUBSCRIPTION_URL = "/api/user/renew-subscription";
export const CANCEL_SUSCRIPTION_URL = "/api/user/user-unsubscribe";
export const USER_DASHBOARD_FEEDS_URL = "/api/feeds";

// post
export const POST_BY_ID_URL = "/api/post/";
export const GET_COMMENTS_URL = "/api/post/comments";
export const CREATE_POST_URL = "/api/post/create";
export const GET_TAG_LIST_URL = "/api/user/autocomplete-user";
export const GET_PEOPLE_LIST_URL = "/api/user/autocomplete-user";
export const CREATE_COMMENT_URL = "/api/post/comment";
export const ADD_LIKE_URL = "/api/post/like";
export const HIDE_POST_URL = "/api/post/hide";
export const UNHIDE_POST_URL = "/api/post/unhide";
export const ADD_TO_FAVOURITE_URL = "/api/post/favourite";
export const REMOVE_FAVOURITE_FAVOURITE_URL = "/api/post/unfavourite";
export const FAVOURITE_LIST_URL = "/api/post/favouritelist";
export const REPORT_POST_URL = "/api/post/report";
export const GIVE_VOTE_URL = "/api/post/vote-poll";

//Paid Package
export const GET_MEDIA_PACKAGE_URL = "/api/media/content-id";
export const CREATE_PACKAGE_URL = "/api/media/create";
export const GET_PACKAGE_LIST_URL = "/api/media";
export const DELETE_PACKAGE_URL = "/api/media/delete";
export const EDIT_PAID_PACKAGE_URL = "/api/media/edit";
export const GET_PACKAGE_URL = "/api/media/media-id";
export const CREATE_MEDIA_PACKAGE_URL = "/api/media/content-create";
export const GET_MEDIA_CONTENT_URL = "/api/media/content-id";
export const DELETE_MEDIA_CONTENT_URL = "/api/media/content-delete";

//Chat
export const CHAT_LOGIN_URL = "/auth/login";
export const CREATE_CHAT_USER_URL = "/api/user/create-chat-user";
export const GET_SOCKET_CONNECTION_URL = "/";
export const GET_MESSAGE_LIST_URL = "/messages/list";
export const GET_ROOM_LIST_URL = "/rooms/list";
export const GET_ROOM_DETAIL_URL = "/rooms/room";
export const GET_ROOM_CHAT_HISTORY_URL = "/messages/list";
export const SEND_MESSAGE_URL = "/messages/send/new/message";
export const SEND_PACKAGE_URL = "/messages/package";
export const SEND_PACKAGE_TO_MULTIPLE_USER = "/messages/package";
export const UNLOCK_PACKAGE_URL = "/api/user/update-wallet";
export const UPLOAD_FILE_URL = "/files/upload";
export const GET_CHAT_HISTORY = "/";
export const COIN_TRANSFER = "api/user/coin-transfer";
export const ADD_NEW_USER_CONVERSTION = "/rooms/conversation";
export const GET_STICKERS = "/api/stickers";
export const POST_STICKERS = "/api/stickers/send-sticker";

//Users
export const GET_FOLLOWER_LIST_URL = "api/user/followers";

// follow
export const FOLLOW_URL = "/api/user/follow";
export const UNFOLLOW_URL = "/api/user/unfollow";

// Block
export const BLOCK_LIST_URL = "/api/user/block-list";
export const BLOCK_URL = "/api/user/block";
export const UNBLOCK_URL = "/api/user/unblock";

//Settings
export const GET_ENV_VARIABLE = "/api/settings";

//Notification
export const NOTIFICATION_URL = "/api/settings/notification";

//Wattel
export const WALLET_URL = "/api/user/transaction-list";

//My-subscribers
export const MY_SUBSCRIBERS_URL = "/api/user/my-subscribers";

//Plan
export const PLAN_LIST_URL = "/api/user/user-package";
export const EDIT_PLAN_URL = "/api/user/create-package";

//PAYOUT
export const PAYOUT_HISTORY_URL = "/api/user/withdraw-list";
export const ADD_PAYOUT_URL = "/api/user/user-withdraw";
export const IN_APP_PAYMENT_URL = "/api/payment/inapp-payment";

//Banking
export const EDIT_BANKING_URL = "/api/user/bank";

// Stories
export const GET_STORIES_URL = "/api/stories";
export const ADD_STORIES_URL = "/api/stories/create";
export const DELETE_STORIES_URL = "/api/stories/delete";

export const SEARCH_TAGS = "/api/feeds/search-tags";

export const POST_DONATE = "/api/post/donate";
export const LIVE_USER_DONATE = "/api/post/user-donate";
export const UNLOCK_POST = "/api/post/pay";
export const REPORT_POST = "/api/post/report";
export const HIDE_POST = "/api/post/hide";
export const DELETE_POST = "/api/post/delete";
export const JOIN_LIVE_STREAM = "/api/livestreams/join-livestream";

//Subscription
export const SUBSCRIPTION_URL = "api/user/user-subscription";
// Following
export const USER_FOLLOWING = "/api/user/followings";

/* Live Streaming */
export const CREATE_LIVE_STREMING_URL = "/api/livestreams/create";
export const CREATE_TOKEN_URL = "/api/agora/tokens";
export const NODE_CREATE_TOKEN = "http://34.198.19.70:8080/rte/";
export const DELETE_LIVE_STREAM = "/api/livestreams/delete";

// Hash Tags
export const GET_HASH_TAGS = "/api/feeds/posts-by-tag";
