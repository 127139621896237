import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { apiCall, METHOD } from '../../service/baseApiCall';
import { Store } from '../Actions';
import { PAYOUT_HISTORY_URL,ADD_PAYOUT_URL, IN_APP_PAYMENT_URL } from '../ApiEndPoints';
import { PAYOUT } from '../constants/action-types';
import { toast } from "react-toastify";

export const payoutHistory = (values:Object): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
  dispatch(payoutHistoryInit(values));
};

export const payoutHistoryInit = (values:Object): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
  dispatch({
    type: PAYOUT.PAYOUT_HISTORY_INITLIZATION
  });
  apiCall(
    PAYOUT_HISTORY_URL,
    {page:1},
    (res: any) => {
      dispatch(payoutHistorySuccess(res))},
    (err:any) =>{
      dispatch(payoutHistoryError(err))},
    METHOD.POST,
    {}
  );
};

export const payoutHistorySuccess = (res:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
  dispatch({
    type:PAYOUT.PAYOUT_HISTORY_SUCCESS,
    payload:res.data
  });
   
  };

export const payoutHistoryError = (err:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
    dispatch({
      type:PAYOUT.PAYOUT_HISTORY_ERORR
    });
  };

  //Hint: Add New Payout
export const AddPayout = (values:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
  dispatch(AddPayoutInit(values));
};

export const AddPayoutInit = (values:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
  dispatch({
    type: PAYOUT.ADD_PAYOUT_INITLIZATION
  });
  apiCall(
    ADD_PAYOUT_URL,
    values,
    (res: any) => {
    
      dispatch(AddPayoutSuccess(res))},
    (err:any) =>{
   
      dispatch(AddPayoutError(err))},
    METHOD.POST,
    {}
  );
};

export const AddPayoutSuccess = (res:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
  
  dispatch({
    type:PAYOUT.ADD_PAYOUT_SUCCESS,
    payload:res.data
  });
  // 
  if(res.success){
    toast.success(res.message)
  }else{
  toast.error(res.message)
  }
};

export const AddPayoutError = (err:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
  dispatch({
    type:PAYOUT.ADD_PAYOUT_ERORR
  });
  toast.error(err.message)
};


  //In app Pyament 
  export const InAppPayment = (values:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
    
    dispatch(InAppPaymentInit(values));
  };
  
  export const InAppPaymentInit = (values:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
    dispatch({
      type: PAYOUT.IN_APP_PAYMENT_INITLIZATION
    });
    apiCall(
      IN_APP_PAYMENT_URL,
      values,
      (res: any) => {
      
        dispatch(InAppPaymentSuccess(res))},
      (err:any) =>{
     
        dispatch(InAppPaymentError(err))},
      METHOD.POST,
      {}
    );
  };
  
  export const InAppPaymentSuccess = (res:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
    
    dispatch({
      type:PAYOUT.IN_APP_PAYMENT_SUCCESS,
      payload:res.data
    });
    // 
    toast.success(res.message)
  };
  
  export const InAppPaymentError = (err:any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch:any) => {
    dispatch({
      type:PAYOUT.IN_APP_PAYMENT_ERORR
    });
    toast.error(err.message)
  };