/* eslint-disable import/no-anonymous-default-export */
import { WALLET } from '../constants/action-types';
import { WalletData } from '../Actions';
import { ActionTypes } from '../action/userActions';

const initialState = {
  loading: false,
  postData: {
    transactions: [],
    user_packages: [],
    wallet_balance: '',
  },
  hasMoreTransaction: false,
};
export default (state: WalletData = initialState, action: ActionTypes) => {
  switch (action.type) {
    case WALLET.WALLET_INITLIZATION:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case WALLET.WALLET_SUCCESS:
      return {
        ...state,
        postData:
          action.payload.page === 1
            ? { ...action.payload.res.data }
            : {
                ...state.postData,
                transactions: [
                  ...state.postData?.transactions,
                  ...action.payload.res.data.transactions,
                ],
              },
        hasMoreTransaction:
          action.payload.res.data.transactions.length > 0 ? true : false,
        loading: false,
      };
    case WALLET.WALLET_ERORR:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
