import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { apiCall, METHOD } from '../../service/baseApiCall';
import { Store } from '../Actions';
import { NOTIFICATION_URL } from '../ApiEndPoints';
import { NOTIFICATION } from '../constants/action-types';

export const notification = (page: Object): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
  dispatch(notificationInit(page));
};

export const notificationInit = (page: Object): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
  dispatch({
    type: NOTIFICATION.NOTIFICATION_INITLIZATION
  });
  apiCall(
    NOTIFICATION_URL,
    page,
    (res: any) => {
      dispatch(notificationSuccess(res))
    },
    (err: any) => {
      dispatch(notificationError(err))
    },
    METHOD.POST,
    {}
  );
};

export const notificationSuccess = (res: any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
  dispatch({
    type: NOTIFICATION.NOTIFICATION_SUCCESS,
    payload: res.data
  });
};

export const notificationError = (err: any): ThunkAction<void, Store, unknown, Action<string>> => (dispatch: any) => {
  dispatch({
    type: NOTIFICATION.NOTIFICATION_ERORR
  });
};