import React, { useEffect, useState } from 'react';
import ChangePassword from '../components/ChangePassword';

import EditProfile from '../components/EditProfile';
import Language from '../components/Language';
import Subscription from '../components/Subscriptions';
import VerifyProfile from '../components/VerifyProfile';
import PaidPackage from '../components/PaidPackage';
import MyWallet from './MyWallet';
import Subscriber from '../components/MySubscriber';
import Plans from '../components/MyPlans';
import { useTranslation } from 'react-i18next';
// import BlackList from "../components/Blacklist";
// import Referral from "../components/Referral";
import Payout from '../components/Payout';
import Banking from '../components/Banking';
import Blacklist from '../components/Blacklist';
import { getToken } from '../utils/utility';

import { useDispatch } from 'react-redux';
import { userDetail, userProfileData } from '../redux/action';
import { AppDispatch } from '../redux';
import Referral from '../components/Referral';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
export default function Settings() {

  const [currentPage, setCurrentPage] = useState<string>('');

  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();
  const location: any = useLocation();

  useEffect(() => {
    const userId = getToken('userId');
    dispatch(userDetail(Number(userId)));
    dispatch(userProfileData(Number(userId)));
    window.scrollTo(0, 0);
    setCurrentPage(location?.pathname)
  }, [currentPage, location?.pathname]);

  const getTabContent = (tabName: string) => {
    switch (tabName) {
      case "/settings/edit-profile":
        return <div
          // className="tab-pane fade show active accordion-item"
          id="editProfile"
          role="tabpanel"
          aria-labelledby="editProfile-tab"
          tabIndex={0}
        >
          <EditProfile currentPage={currentPage} />
        </div>
      case "/settings/blacklist":
        return <div
          // className="tab-pane fade accordion-item"
          id="blacklist-tab-pane"
          role="tabpanel"
          aria-labelledby="blacklist-tab"
          tabIndex={0}
        >
          <Blacklist />
        </div>
      case "/settings/verify-profile":
        return <div
          // className="tab-pane fade accordion-item"
          id="verify-tab-pane"
          role="tabpanel"
          aria-labelledby="verify-tab"
          tabIndex={0}
        >
          <VerifyProfile />
        </div>
      case "/settings/change-password":
        return <div
          // className="tab-pane fade accordion-item"
          id="changepsw-tab-pane"
          role="tabpanel"
          aria-labelledby="changepsw-tab"
          tabIndex={0}
        >
          <ChangePassword />
        </div>
      case "/settings/subscription":
        return <div
          // className="tab-pane fade accordion-item"
          id="subscriptions-tab-pane"
          role="tabpanel"
          aria-labelledby="subscriptions-tab"
          tabIndex={0}
        >
          <Subscription />
        </div>
      case "/settings/mysubscribers":
        return <div
          // className="tab-pane fade accordion-item"
          id="mysubscribers-tab-pane"
          role="tabpanel"
          aria-labelledby="mysubscribers-tab"
          tabIndex={0}
        >
          <Subscriber />
        </div>
      case "/settings/wallet":
        return <div
          // className="tab-pane fade accordion-item"
          id="mywallet-tab-pane"
          role="tabpanel"
          aria-labelledby="wallet-tab"
          tabIndex={0}
        >
          <MyWallet />
        </div>
      case "/settings/plans":
        return <div
          // className="tab-pane fade accordion-item"
          id="plans-tab-pane"
          role="tabpanel"
          aria-labelledby="plans-tab"
          tabIndex={0}
        >
          <Plans currentPage={currentPage} />
        </div>
      case "/settings/packages":
        return <div
          // className="tab-pane fade accordion-item"
          id="package-tab-pane"
          role="tabpanel"
          aria-labelledby="package-tab"
          tabIndex={0}
        >
          <PaidPackage currentPage={currentPage} />
        </div>
      case "/settings/payout":
        return <div
          // className="tab-pane fade accordion-item"
          id="payout-tab-pane"
          role="tabpanel"
          aria-labelledby="payout-tab"
          tabIndex={0}
        >
          <Payout />
        </div>
      case "/settings/banking":
        return <div
          // className="tab-pane fade accordion-item"
          id="banking-tab-pane"
          role="tabpanel"
          aria-labelledby="banking-tab"
          tabIndex={0}
        >
          <Banking currentPage={currentPage} />
        </div>
      case "/settings/language":
        return <div
          // className="tab-pane fade accordion-item"
          id="language-tab-pane"
          role="tabpanel"
          aria-labelledby="language-tab"
          tabIndex={0}
        >
          <Language />
        </div>
      case "/settings/referral":
        return <div
          // className="tab-pane fade accordion-item"
          id="referral-tab-pane"
          role="tabpanel"
          aria-labelledby="referral-tab"
          tabIndex={0}
        >
          <Referral />
        </div>
      default:
        return null
    }
  }

  return (
    <div className="setting-container">
      <div className="setting-grid-box box-container settings-main">
        <div className="setting-tabs">
          <ul
            className="nav nav-tabs d-none d-lg-block"
            id="myTab"
            role="tablist"
            onClick={(event: any) => { if (event.target.id !== currentPage) setCurrentPage(event.target.id) }}>
            <li className={`nav-item ${currentPage === "/settings/edit-profile" ? "setting-subtab-nav-link" : ""}`} role="presentation">
              <Link
                to={"/settings/edit-profile"}
              >
                <button
                  className="nav-link"
                  id="editProfile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#editProfile"
                  type="button"
                  role="tab"
                  aria-controls="editProfile"
                  aria-selected={currentPage === '/settings/edit-profile'}
                >
                  {t ? t('settings.edit profile') : "Edit Profile"}
                </button>
              </Link>
            </li>
            <li className={`nav-item ${currentPage === "/settings/blacklist" ? "setting-subtab-nav-link" : ""}`} role="presentation">
              <Link
                to={"/settings/blacklist"}
              >
                <button
                  className="nav-link"
                  id="blacklist-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#blacklist-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="blacklist-tab-pane"
                  aria-selected={currentPage === '/settings/blacklist'}
                >
                  {t('settings.blacklist')}
                </button>
              </Link>
            </li>
            <li className={`nav-item ${currentPage === "/settings/verify-profile" ? "setting-subtab-nav-link" : ""}`} role="presentation">
              <Link
                to={"/settings/verify-profile"}
              >
                <button
                  className="nav-link"
                  id="verify-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#verify-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="verify-tab-pane"
                  aria-selected="false"
                >
                  {t('settings.verify profile')}
                </button>
              </Link>
            </li>
            <li className={`nav-item ${currentPage === "/settings/change-password" ? "setting-subtab-nav-link" : ""}`} role="presentation">
              <Link
                to={"/settings/change-password"}
              >
                <button
                  className="nav-link"
                  id="changepsw-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#changepsw-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="changepsw-tab-pane"
                  aria-selected="true"
                >
                  {t('settings.change password')}
                </button>
              </Link>
            </li>
            <li className={`nav-item ${currentPage === "/settings/subscription" ? "setting-subtab-nav-link" : ""}`} role="presentation">
              <Link
                to={"/settings/subscription"}
              >
                <button
                  className="nav-link"
                  id="subscriptions-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#subscriptions-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="subscriptions-tab-pane"
                  aria-selected="false"
                >
                  {t('settings.subscriptions')}
                </button>
              </Link>
            </li>
            <li className={`nav-item ${currentPage === "/settings/mysubscribers" ? "setting-subtab-nav-link" : ""}`} role="presentation">
              <Link
                to={"/settings/mysubscribers"}
              >
                <button
                  className="nav-link"
                  id="mysubscribers-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#mysubscribers-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="mysubscribers-tab-pane"
                  aria-selected="false"
                >
                  {t('settings.my subscribers')}
                </button>
              </Link>
            </li>
            <li className={`nav-item ${currentPage === "/settings/wallet" ? "setting-subtab-nav-link" : ""}`} role="presentation">
              <Link
                to={"/settings/wallet"}
              >
                <button
                  className="nav-link"
                  id="mywallet-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#mywallet-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="mywallet-tab-pane"
                  aria-selected="true"
                >
                  {t('settings.my wallet')}
                </button>
              </Link>
            </li>
            <li className={`nav-item ${currentPage === "/settings/plans" ? "setting-subtab-nav-link" : ""}`} role="presentation">
              <Link
                to={"/settings/plans"}
              >
                <button
                  className="nav-link"
                  id="plans-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#plans-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="plans-tab-pane"
                  aria-selected="false"
                >
                  {t('settings.my plans')}
                </button>
              </Link>
            </li>
            <li className={`nav-item ${currentPage === "/settings/packages" ? "setting-subtab-nav-link" : ""}`} role="presentation">
              <Link
                to={"/settings/packages"}
              >
                <button
                  className="nav-link"
                  id="package-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#package-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="package-tab-pane"
                  aria-selected="false"
                >
                  {t('settings.my paid media packages')}
                </button>
              </Link>
            </li>
            <li className={`nav-item ${currentPage === "/settings/payout" ? "setting-subtab-nav-link" : ""}`} role="presentation">
              <Link
                to={"/settings/payout"}
              >
                <button
                  className="nav-link"
                  id="payout-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#payout-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="payout-tab-pane"
                  aria-selected="true"
                >
                  {t('settings.payout')}
                </button>
              </Link>
            </li>
            <li className={`nav-item ${currentPage === "/settings/banking" ? "setting-subtab-nav-link" : ""}`} role="presentation">
              <Link
                to={"/settings/banking"}
              >
                <button
                  className="nav-link"
                  id="banking-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#banking-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="banking-tab-pane"
                  aria-selected="false"
                >
                  {t('settings.banking')}
                </button>
              </Link>
            </li>
            <li className={`nav-item ${currentPage === "/settings/language" ? "setting-subtab-nav-link" : ""}`} role="presentation">
              <Link
                to={"/settings/language"}
              >
                <button
                  className="nav-link"
                  id="language-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#language-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="language-tab-pane"
                  aria-selected="false"
                >
                  {t('settings.language')}
                </button>
              </Link>
            </li>
            <li className={`nav-item ${currentPage === "/settings/referral" ? "setting-subtab-nav-link" : ""}`} role="presentation">
              <Link
                to={"/settings/referral"}
              >
                <button
                  className="nav-link"
                  id="referral-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#referral-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="referral-tab-pane"
                  aria-selected="false"
                >
                  {t('settings.my referral code')}
                </button>
              </Link>
            </li>
          </ul>
        </div>
        <div className="settings-tab-content">
          <div className="tab-content accordion" id="myTabContent">
            {getTabContent(currentPage)}
          </div>
        </div>
      </div>
    </div>
  );
}
