import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../redux/Actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux';
import { S3_BASE_URL } from '../../service/aws/config';
import { FiX } from 'react-icons/fi';
import user from '../../assets/images/imgs/user1.png';
import coins from '../../assets/images/imgs/coins.svg';
import { METHOD, apiCall } from '../../service/baseApiCall';
import { toast } from 'react-toastify';
import { POST_DONATE, UNLOCK_POST } from '../../redux/ApiEndPoints';
import { USER_FEEDS } from '../../redux/constants/action-types';
import {
  createImageFromInitials,
  getRandomColor,
} from '../../utils/createImage';
import verifyIcon from '../../assets/images/icons/verifyIcon.svg';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';

export default function UnlocakContentModel({
  showPostComment,
  setShowPostComment,
  post,
}: any) {
  const dispatch = useDispatch<AppDispatch>();
  const {t}=useTranslation()
  const userDetail: any = useSelector(
    (state: Store) => state.userDataReducer.userDetail
  );

  const unlockPost = async () => {
    try {
      await apiCall(
        UNLOCK_POST,
        { price: post?.payment_amount, post_id: post?.id },
        (data: any) => {
          setShowPostComment(false);
          dispatch({
            type: USER_FEEDS.UNLOCK_POST,
            payload: data?.data?.post_id,
          });
          dispatch({
            type: USER_FEEDS.UNLOCK_COMMENT_POST,
            payload: data?.data?.post_id,
          });
          if (data.success == false) {
            toast.error('payment Failed');
          } else {
            toast.success('Content Unlocked');
          }
        },
        () => {
          setShowPostComment(false);

          toast.success('payment Failed');
        },
        METHOD.POST
      );
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  //  useEffect(()=>{dispatch(userFeed())},[])

  return (
    <div
      className={
        showPostComment
          ? 'modal fade custom-modal comment-modal photo-modal show'
          : 'modal fade custom-modal comment-modal photo-modal'
      }
      style={{ display: showPostComment && 'block' }}
      id="unlockModal"
      tabIndex={-1}
      aria-labelledby="commentModalLabel"
    >
      <div className="modal-dialog  modal-content-sm modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content border-0">
          <div
            className="modal-body unlock-pot-modal-body"
            style={{ position: 'relative' }}
          >
            <button
              type="button"
              className="unlock-post-modal-close-btn"
              data-bs-dismiss="modal"
              onClick={() => setShowPostComment(false)}
            >
              <FiX size={40} />
            </button>

            <div
              className="uer-details"
              style={{
                borderRadius: '50%',
                // height: "55px",
                // boxSizing: "content-box",
              }}
            >
              <Avatar
                className="avtar"
                round={true}
                src={ post?.user?.profile_photo
                  ? S3_BASE_URL + post?.user?.profile_photo
                  : createImageFromInitials(
                      500,
                      post?.user?.first_name,
                      getRandomColor()
                    )}
                alt="profile"
                size="105"
                name={post?.user?.first_name + ' ' + post?.user?.last_name}
                maxInitials={2}
              />
              {/* <img
                src={
                  post?.user?.profile_photo
                    ? S3_BASE_URL + post?.user?.profile_photo
                    : createImageFromInitials(
                        500,
                        post?.user?.first_name,
                        getRandomColor()
                      )
                }
                alt="user"
              /> */}
              <h6>
                {post?.user?.first_name} {post?.user?.last_name}
                <span>
                  {' '}
                  <img
                    className="img-fluid"
                    style={{
                      width: '20px',
                      height: '21px',
                      paddingLeft: '2px',
                      paddingBottom: '3px',
                    }}
                    src={verifyIcon}
                    alt="verify"
                  />
                </span>
              </h6>
              <p className="unlock-username">@{post?.user?.username}</p>
            </div>
            <div className="coins">
              <h6>
                <img src={coins} alt="coin" height={35} width={35} />{' '}
                {post?.payment_amount}
              </h6>
            </div>
            <div className="d-flex justify-content-center border-0 pt-0 pb-3">
              <button
                type="button"
                className="unlock-post-btn"
                data-bs-dismiss="modal"
                onClick={unlockPost}
              >
           {t("home.unlock")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
