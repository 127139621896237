import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Img1 from '../assets/images/icons/ico_search.png';
import Img2 from '../assets/images/imgs/user1.svg';
import Img3 from '../assets/images/icons/ico_more.svg';
import Img4 from '../assets/images/icons/upload-media.svg';
import Img5 from '../assets/images/icons/diamond.svg';
import Img6 from '../assets/images/icons/img-upload.svg';
import Img7 from '../assets/images/icons/video.svg';
import { ReactComponent as CheckIcon } from '../assets/images/icons/check-icon.svg';
import { Store, ChatData, SettingsData } from '../redux/Actions';
import { AppDispatch } from '../redux';
import { useDispatch, useSelector } from 'react-redux';
import {
  socketConnection,
  FetchRecentChatUserList,
  SendMessage,
  FetchRoomChatHistory,
  UploadFile,
  SendPackage,
  UnlockPaidPackage,
  ReceiveNewMessageHistory,
  getMediaPackageById,
  getPaidPackageById,
  getPaidPackageList,
  SendPaidPackage,
  userProfileData,
  userDetail,
  AddUserToConversation,
  getPaidPackageByIdSuccess,
  UnlockPaidPackageSuccess,
} from '../redux/action';
import { v4 as uuidv4 } from 'uuid';
import { object } from 'yup';
import moment from 'moment';
import plus from '../assets/images/icons/plus.svg';
import send from '../assets/images/icons/send.svg';
import dollor from '../assets/images/icons/dollar-sign.svg';
import UnlockPopup from './UnlockPackage';
import socket from '../utils/socket';
import { Spinner, Button, Modal } from 'react-bootstrap';
import UnlockMessageDetails from './UnlockMessageDetails';
import { useLocation } from 'react-router-dom';
import bg from '../assets/images/bg/bg.svg';
import UnlockContent from './UnlockContent';
import { getToken } from '../utils/utility';
import Avatar from 'react-avatar';
import { ReactComponent as VerifyIcon } from '../assets/images/icons/verifyIcon.svg';
import { ReactComponent as Coin } from '../assets/images/icons/coin.svg';
import { toast } from 'react-toastify';
import { METHOD, apiCall } from '../service/baseApiCall';
import { COIN_TRANSFER, USER_FEEDS_URL } from '../redux/ApiEndPoints';
import { useTranslation } from 'react-i18next';
import Loader from '../components/Loader';
import { useNavigate } from 'react-router-dom';
import { MEDIA_SIZE } from '../constants/App-constant';
const s3bucketurl = process.env.REACT_APP_S3_BASE_URL;

export default function Message() {
  const { state }: any = useLocation();
  const scrollRef = useRef<any>();
  const chatUseRef = useRef<any>(null);
  const navigate = useNavigate();
  const {
    chatUserList,
    chatHistory,
    socketStatus,
    socketConnect,
    sendMessageStatus,
    sendPackageStatus,
    pictures,
    loading,
    unlockResponse,
    fileUploaded,
  }: ChatData = useSelector((state: Store) => state.chatDataReducer);
  const { mediaContentData, packageData, paidPackageList, loading: myLoadingVariable }: SettingsData =
    useSelector((state: Store) => state.settingsDataReducer);
  const [message, setMessage] = useState('');
  const [coin, setCoin] = useState<{
    value: string;
    error: boolean;
    loading: boolean;
  }>({
    value: '100',
    error: false,
    loading: false,
  });
  const [room, setRoom] = useState('');
  const [currentUser, setCurrentUser] = useState<{
    username: string;
    _id: string;
    profilePicture: string;
    firstName: string;
    lastName: string;
    user_verified: boolean;
  }>({
    username: '',
    _id: '',
    profilePicture: '',
    firstName: '',
    lastName: '',
    user_verified: false,
  });
  const [packageUser, setPackageUser] = useState<{
    username: string;
    _id: string;
    profilePicture: string;
  }>({
    username: '',
    _id: '',
    profilePicture: '',
  });
  const [receiver, setReceiver] = useState<{
    username: string;
    _id: string;
    profilePicture: string;
  }>({
    username: '',
    _id: '',
    profilePicture: '',
  });
  const [recentUserList, setRecentUserList] = useState<any>([]);
  const [fileUpload, setFileUpload] = useState<boolean>(false);
  const [paidPopup, setPaidPopup] = useState<boolean>(false);
  const [coinPopup, setCoinPopup] = useState<boolean>(false);
  const [packageTitle, setPackageTitle] = useState('');
  const [packageId, setPackageId] = useState('');
  const [userList, setUserList] = useState<[string]>(['']);
  const fileInput = useRef<any>();
  const { t } = useTranslation();
  const token: String = getToken('jwtToken') || '';
  const chatUserId: String = getToken('chatUserId') || '';
  const dispatch = useDispatch<AppDispatch>();
  const [unlockPopup, setUnlockPopup] = useState(false);
  const [amount, setAmount] = useState<number>(0);
  const [toUserId, setToUserId] = useState<String>('');
  const [messageId, setMessageId] = useState<String>('');
  const [skip, setSkip] = useState<number>(0);
  const [limit, setLimit] = useState<number>(200);
  const [spinner, setSpinner] = useState<boolean>(false);
  const [preview, setPreview] = useState<any>('');
  const [videoPreview, setVideoPreview] = useState<any>('');
  const [paidMediaPreview, setPaidMediaPreview] = useState<any>('');
  const [selectedFile, setSelectedFile] = useState<any>('');
  const [unlockPopupDetails, setUnlockPopupDetails] = useState<boolean>(false);
  const [packageDetail, setPackageDetail] = useState<any>({});
  const [paidPackageDetail, setPaidPackageDetail] = useState<any>({});
  const [paidMedia, setPaidMedia] = useState(false);
  const [unlockcontent, setUnLockContent] = useState(false);
  const [messageType, setMessageType] = useState('');
  const location: any = useLocation();
  const [userIdfromSearchPageState, setUserIdfromSearchPageState] =
    useState<string>('');
  const [packageAmount, setPackageAmount] = useState<string>('');
  const [currentPaidContent, setCurrentPaidContent] = useState({});
  const [messImgPopUp, setMessImgPopUp] = useState<any>({ data: {}, isModal: false });
  const userIdfromSearchPage =
    location?.state?.fromPage === 'search-profile'
      ? location.state.node_chate_id
      : 0;
  useEffect(() => {
    dispatch(socketConnection(token));
    const userId = getToken('userId');
    dispatch(userDetail(Number(userId)));
    dispatch(userProfileData(Number(userId)));
    setUserIdfromSearchPageState(userIdfromSearchPage);
  }, []);
  useEffect(() => {
    socket.on('message', (data) => {
      dispatch(ReceiveNewMessageHistory(data?.message));
    });
    return () => {
      socket.off('message');
    };
  }, []);
  useEffect(() => {
    setPaidPackageDetail(packageData);
  }, [packageData]);

  useEffect(() => {
    submitMessage();
  }, [fileUploaded]);

  useEffect(() => {
    if (socketStatus === 'connected') {
      dispatch(FetchRecentChatUserList(token));
      scroll();
    }
  }, [socketStatus]);

  useEffect(() => {
    if (recentUserList.length <= chatUserList.length) {
      setRecentUserList(chatUserList);
    }
  }, [chatUserList.length]);

  useEffect(() => {
    if (userIdfromSearchPageState) {
      let sender: any;
      let receiver: any;
      let receiver_id = '';
      chatUserList.forEach((data: any) => {
        if (!sender) {
          sender = data.members.find(
            (e: any) => e._id !== userIdfromSearchPageState
          );
        }
        if (!receiver) {
          receiver = data.members.find(
            (e: any) => e._id === userIdfromSearchPageState
          );
          receiver_id = data?._id;
        }
      });
      if (sender && receiver) {
        handleRoomDetail(receiver_id, receiver, sender);
      } else {
        dispatch(
          AddUserToConversation({
            user: userIdfromSearchPageState,
          })
        );
        navigate(`/messages`, {
          state: {
            fromPage: 'search-profile',
          },
        });
      }
    } else if (chatUserList?.length) {
      let sender;
      let receiver;
      let id = '';
      const initialChat: any = chatUserList[0];
      sender = initialChat.members.find((e: any) => e._id !== chatUserId) || {};
      receiver =
        initialChat.members.find((e: any) => e._id === chatUserId) || {};
      if (initialChat._id === chatUserId) {
        id = initialChat._id;
      }
      handleRoomDetail(initialChat._id, sender, receiver);
    }
  }, [chatUserList.length, userIdfromSearchPageState]);

  useEffect(() => {
    scroll();
  }, [chatHistory]);

  useEffect(() => {
    if (unlockResponse && room) {
      const data: {
        room: string;
        limit: number;
        skip: number;
      } = {
        room: room,
        limit,
        skip,
      };
      dispatch(FetchRoomChatHistory(data));
      // setUnlockPopup(!unlockPopup);
      // setUnLockContent(!unlockcontent)
      scroll();
      dispatch(UnlockPaidPackageSuccess({ res: { message: false } }))
    }
  }, [unlockResponse, room]);

  const scroll = () => {
    scrollRef?.current?.scrollIntoView({
      behaviour: 'smooth',
      block: 'end',
    });
  };

  const scrollRecentChat = () => {
    chatUseRef?.current?.scrollIntoView({
      behaviour: 'smooth',
      block: 'end',
    });
  };

  useEffect(() => {
    scrollRecentChat()
  }, [chatUseRef?.current])

  const handleRoomDetail = (id: string, author: any, receiver: any) => {
    if (recentUserList.length <= chatUserList.length) {
      setRoom(id);
      setCurrentUser(author);
      setReceiver(receiver);
      const data: {
        room: string;
        limit: number;
        skip: number;
      } = {
        room: id,
        limit,
        skip,
      };
      dispatch(FetchRoomChatHistory(data));
      scroll();
      setFileUpload(false);
      setPaidPopup(false);
      scrollRecentChat();
    }
  };
  const submitMessage = async () => {
    let files: Array<{}> = [];
    if ((!message || message === '') && pictures.length === 0) {
      return;
    }
    if (message) {
      files = [];
    } else {
      files = [{ file: pictures[0]?.file?.id }];
    }
    const data: {
      content: String;
      room: String;
      message_type: String;
      pictures: Array<{}>;
      coins?: string;
      isLock?: boolean;
      isPaid?: boolean;
      media_url?: any;
    } = {
      content: message || '',
      room,
      message_type: messageType || 'text',
      pictures: messageType === 'PAID_MEDIA' ? [] : files,
      coins: messageType === 'PAID_MEDIA' ? packageAmount : '',
      isLock: messageType === 'PAID_MEDIA' ? true : false,
      isPaid: messageType === 'PAID_MEDIA' ? true : false,
      media_url:
        messageType === 'PAID_MEDIA' ? pictures[0]?.file?.shield?.location : '',
    };
    await dispatch(SendMessage(data));
    // if (!loading) {
    setMessage('');
    const roomData: {
      room: string;
      limit: number;
      skip: number;
    } = {
      room,
      limit,
      skip,
    };
    // dispatch(FetchRoomChatHistory(roomData));

    // }
    scroll();
    setPackageAmount('');
  };
  const handlePressEnterValue = (e: any) => {
    if (e?.keyCode === 13) {
      submitMessage();
    }
  };
  //Send Package
  const submitPackage = async () => {
    // // if ((!packageId || packageId === '') && userList.length === 0 && (!packageTitle || packageTitle ==='')) {
    // //   return;
    // // }
    // const copyUserList = currentUser?._id;
    // userList.push(copyUserList);
    // const data: {
    //   packageId: String;
    //   packageTitle: String;
    //   userList: Array<String>;
    // } = { packageId, packageTitle, userList };
    // await dispatch(SendPackage(data));
    // if (sendPackageStatus === 'ok') {
    //   dispatch(FetchRoomChatHistory(room));
    // }
    const id: any = uuidv4();
    const data = new FormData();
    data.append('file', selectedFile, selectedFile.name);
    data.append('id', id);
    dispatch(UploadFile(data));
    setPaidPopup(!paidPopup);
    setFileUpload(false);
    // handleFile();
    setPaidMediaPreview('');
    setSelectedFile('');
    scroll();
    setPaidMedia(false)
  };

  const handleCoinPopup = () => {
    setCoinPopup(!coinPopup);
  };
  //Send Coins
  const SubmitCoin = async () => {
    if (coin.value === '' || +coin.value === 0) {
      setCoin({
        ...coin,
        error: true,
      });
      return;
    }
    const data: {
      content: String;
      room: String;
      message_type: String;
      pictures: Array<{}>;
      coins: string;
    } = {
      content: coin.value,
      room,
      message_type: 'COIN_TRANSFER',
      pictures: [],
      coins: coin.value,
    };
    try {
      setCoin({
        ...coin,
        loading: true,
      });
      await apiCall(
        COIN_TRANSFER,
        {
          to_user_id: currentUser._id,
          amount: coin.value,
        },
        () => {
          dispatch(SendMessage(data));
          setCoin({
            ...coin,
            loading: false,
            value: '100',
          });
          handleCoinPopup();
        },
        (res: any) => {
          setCoin({
            ...coin,
            loading: false,
            value: '100',
          });
          toast.error('Tranfer Failed');
        },
        METHOD.POST
      );
    } catch (error) {
      toast.error('Something went wrong');
    }
    // await dispatch(FetchRoomChatHistory(data));
  };

  //Upload File
  const uploadFile = async (file: any) => {
    // const id: any = uuidv4();
    // const data = new FormData();
    // data.append('file', file, file.name)
    // data.append('id', id)
    // await dispatch(UploadFile(data))
    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);
    setSelectedFile(file);
    setMessageType('text');

    // submitMessage();
    // handleFile();
  };

  const timeDifference = (time1: string) => {
    const current: any = new Date();
    const previous: any = new Date(time1);
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    const elapsed = current - previous;

    if (elapsed < msPerMinute) {
      return `${Math.round(elapsed / 1000)} sec ago`;
    }
    if (elapsed < msPerHour) {
      return `${Math.round(elapsed / msPerMinute)} min ago`;
    }
    if (elapsed < msPerDay) {
      return `${Math.round(elapsed / msPerHour)} h ago`;
    }
    if (elapsed < msPerMonth) {
      return `${Math.round(elapsed / msPerDay)} d ago`;
    }
    if (elapsed < msPerYear) {
      return `${Math.round(elapsed / msPerMonth)} m ago`;
    }
    return `${Math.round(elapsed / msPerYear)} y ago`;
  };

  const handleFile = () => {
    setFileUpload(!fileUpload);
  };

  const handlePaidPopup = () => {
    setFileUpload(false);
    setPaidPopup(!paidPopup);
  };

  const handleSearch = (value: any) => {
    let filteredUser: any = [];
    chatUserList &&
      chatUserList.filter((elem: any) => {
        let userList: any;
        userList =
          elem?.members.filter(
            (e: any) =>
              e._id !== chatUserId &&
              `${e?.firstName}${e?.lastName}`
                .toLowerCase()
                .includes(value.toLowerCase())
          ) || {};
        if (userList.length > 0) {
          filteredUser.push(elem);
        }
        //  filteredUser = userList && userList.filter((data: any)=> data.username.includes(value))
      });
    setRecentUserList(filteredUser);
  };

  const handleUnlockPopupDetails = async (packageId: any, userType: any) => {
    if (userType === 'receiver') {
      setPackageUser(receiver);
    } else {
      setPackageUser(currentUser);
    }
    // await dispatch(getMediaPackageById(packageId));
    await dispatch(getPaidPackageById(packageId));
    if (unlockPopupDetails)
      dispatch(getPaidPackageByIdSuccess({ res: { data: {} } }));
    setUnlockPopupDetails(!unlockPopupDetails);
    if (userType === 'receiver') { setUnlockPopupDetails(true) }
  };

  const handleUnlockPopup = async (sender: String, data: any) => {
    // paid package pending
    setPackageId(data?.packageId);
    await dispatch(getPaidPackageById(data?.packageId));
    setUnlockPopup(!unlockPopup);

    if (!unlockPopup) {
      setAmount(packageData?.media_package_price);
      setToUserId(sender);
      setMessageId(data?._id);
      setCurrentPaidContent(data);
    } else {
      setToUserId('');
      setMessageId('');
      setAmount(0);
      setCurrentPaidContent({});
    }
  };

  //Unlock Package
  const unlockPackage = async () => {
    const data: {
      amount: number;
      to_user_id: String;
      message_id: String;
      // chat_type: Number;
    } = {
      amount: amount ?? packageData?.media_package_price,
      to_user_id: toUserId,
      message_id: messageId,
      // chat_type: 0,
    };
    // let status = false;
    await dispatch(UnlockPaidPackage(data));
    // setUnlockPopup(!unlockPopup);
    //  setUnLockContent(!unlockcontent)
    // if (status === true) {
    //   dispatch(FetchRoomChatHistory(room));
    // }

    // scroll();
  };
  // Demo
  const handleUnlockContent = async (sender: String, data: any) => {
    // paid media
    setPackageId(data?._id);
    // await dispatch(getPaidPackageById(data?._id));
    setUnLockContent(!unlockcontent);
    if (!unlockPopup) {
      setAmount(data?.coins);
      setToUserId(sender);
      setMessageId(data?._id);
      setCurrentPaidContent(data);
    } else {
      setToUserId('');
      setMessageId('');
      setAmount(0);
      setCurrentPaidContent({});
    }
  };

  // Unlock Content
  const unlockContent = async () => {
    const data: {
      amount: number;
      to_user_id: String;
      message_id: String;
      // chat_type: Number;
    } = {
      amount,
      to_user_id: toUserId,
      message_id: messageId,
      // chat_type: 0,
    };
    dispatch(UnlockPaidPackage(data));
    // setUnLockContent(!unlockcontent);
    // if (status === true) {
    //   dispatch(FetchRoomChatHistory(room));
    // }
  };

  // const handleLoadMessages = async (load: any) => { };

  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.target.id !== 'closable') setFileUpload(false);
          setPreview('');
          setUnlockPopup(false);
          setUnLockContent(false);
          setVideoPreview('');
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const imgRef = useRef<any>();
  useOutsideAlerter(imgRef);

  const useOutsideAlerter1 = (ref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setCoinPopup(false);
          setCoin({
            ...coin,
            value: '100',
          });
          setPaidMedia(false);
        }
      }
      if (coinPopup) {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }
    }, [ref]);
  };

  const coinRef = useRef<any>();
  useOutsideAlerter1(coinRef);

  const useOutsideAlerter3 = (ref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setUnlockPopup(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const unlockRef = useRef<any>();
  useOutsideAlerter3(unlockRef);

  const useOutsideAlerter4 = (ref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setPaidMedia(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const paidRef = useRef<any>();
  useOutsideAlerter4(paidRef);

  const useOutsideAlerter5 = (ref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setPaidPopup(false);
          if (paidPopup) dispatch(getPaidPackageByIdSuccess({ res: { data: {} } }));
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const paidpopupRef = useRef<any>();
  useOutsideAlerter5(paidpopupRef);
  //Hint: Handle Preview
  const handlePreview = async (file: any) => {
    if (file?.size / 1024 < MEDIA_SIZE.IMAGE_SIZE) {
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
      setSelectedFile(file);
      setMessageType('text');
      // const id: any = uuidv4();
      // const data = new FormData();
      // data.append("file", file, file.name);
      // data.append("id", id);
      // await dispatch(UploadFile(data));
    } else {
      toast.error(
        `Please select less then ${MEDIA_SIZE.IMAGE_SIZE / 1024} MB image file`
      );
    }
  };
  const handleVideoPreview = async (file: any) => {
    if (file?.size / 1024 < MEDIA_SIZE.VIDEO_SIZE) {
      const objectUrl = URL.createObjectURL(file);
      setVideoPreview(objectUrl);
      setSelectedFile(file);
      const id: any = uuidv4();
      const data = new FormData();
      data.append('file', file, file.name);
      data.append('id', id);
      setMessageType('CHAT_VIDEO_FILE');
      // await dispatch(UploadFile(data));
    } else {
      toast.error(
        `Please select less then ${MEDIA_SIZE.VIDEO_SIZE / 1024} MB video file`
      );
    }
  };

  const handlePaidPreview = async (file: any) => {
    if (file?.size / 1024 < MEDIA_SIZE.IMAGE_SIZE) {
      const objectUrl = URL.createObjectURL(file);
      setPaidMediaPreview(objectUrl);
      setSelectedFile(file);
      setMessageType('PAID_MEDIA');
      // const id: any = uuidv4();
      // const data = new FormData();
      // data.append('file', file, file.name);
      // data.append('id', id);
      // // await dispatch(UploadFile(data));
    } else {
      toast.error(
        `Please select less then ${MEDIA_SIZE.IMAGE_SIZE / 1024} MB image file`
      );
    }
  };

  const handleSaveFile = async () => {
    const id: any = uuidv4();
    const data = new FormData();
    data.append('file', selectedFile, selectedFile.name);
    data.append('id', id);
    dispatch(UploadFile(data));
    // submitMessage();
    handleFile();
    setPreview('');
  };
  // let formik = useFormik({
  //   enableReinitialize: true,
  //   initialValues: {
  //     media_package_name,
  //     media_package_price,
  //   },
  //   // validationSchema: dumentVerificationSchema,
  //   onSubmit: async (values) => {
  //       dispatch(CreatePaidPackage(values));

  //   },
  // });
  const handleMedia = () => {
    dispatch(getPaidPackageList());
    setPaidMedia(!paidMedia);
  };

  const handleSendPackage = async (id: any, media_package_name: any) => {
    let user_list: any = [];
    user_list.push(currentUser?._id);

    const data: {
      package_id: any;
      user_list: any;
      package_title: any;
      message_type: any;
    } = {
      package_id: id,
      user_list,
      package_title: media_package_name,
      message_type: 'paidPackage',
    };
    dispatch(SendPaidPackage(data));
    setPaidMedia(false)
  };

  const onChangleHandler = (event: any) => {
    let value = event.target.value;
    if (value.length > 5) return;
    let pattern = /^(|[1-9][0-9]*)$/;
    let result = value.match(pattern);
    switch (event.target.name) {
      case 'sendCoin':
        if (result || value === '')
          setCoin({
            ...coin,
            error: false,
            value: event.target.value,
          });
        break;
      case 'paidPackage':
        if (result || value === '') setPackageAmount(value);
        break;
      default:
        return;
    }
  };

  const redirectToUserProfile = (currentUser: any) => {
    apiCall(
      USER_FEEDS_URL,
      { chatuserid: currentUser._id },
      (res: any) => {
        navigate(`/user-profile/${res?.data?.user_details?.id}`);
      },
      (err: any) => {
        toast.error(err.message);
      },
      METHOD.POST,
      {}
    );
  };

  const imagePopUp = (imgData: any) => {
    setMessImgPopUp({ data: imgData, isModal: true })
  }

  return (
    <div className="setting-container">
      <div className="setting-grid-box box-container messages-container">
        <div className="setting-tabs">
          <div className="chat-search">
            <img src={Img1} className="img-fluid" alt="search" />
            <input
              type="text"
              className="form-control border-0"
              onChange={(e) => handleSearch(e.target.value)}
              placeholder={`${t('home.search')}`}
            />
          </div>

          {recentUserList &&
            recentUserList.map((data: any) => {
              const sender =
                data.members.find((e: any) => e._id !== chatUserId) || {};
              const receiver =
                data.members.find((e: any) => e._id === chatUserId) || {};
              return (
                <>
                  {JSON.stringify(sender) === '{}' ? null : (
                    <div
                      key={data._id}
                      className={`post-user post-user-control  d-flex align-items-center ${currentUser?._id === sender._id
                        ? 'post-user-control-active'
                        : ''
                        }`}
                      onClick={() =>
                        handleRoomDetail(data._id, sender, receiver)
                      }
                    >
                      <div className="post-user-logo">
                        <Avatar
                          className="avtar"
                          round={true}
                          src={s3bucketurl + '/' + sender?.profilePicture}
                          alt="profile"
                          size="50"
                          name={sender?.firstName + ' ' + sender?.lastName}
                          maxInitials={2}
                        />

                      </div>
                      <div className="col">
                        <h5 className="post-user-name">
                          <span className="reciever-user-message-profile-username text-start">
                            {sender?.firstName + ' ' + sender?.lastName}
                          </span>
                          <span>{timeDifference(data.lastMessage?.date)}</span>
                        </h5>
                        <p className="text-msg">
                          <b>{data?.lastMessage?.content} </b>
                          {/* <span className="badge">1</span> */}
                        </p>
                      </div>
                    </div>
                  )}
                  <span ref={room === data._id ? chatUseRef : null} />
                </>
              );
            })}
        </div>

        <div className="settings-tab p-0 d-flex">
          <div className="settings-content">
            {!!room ? (
              <div className="modal-dialog message-wrapper modal-dialog-scrollable m-0 mw-100 w-100">
                <div className="modal-content border-0">
                  <div className="modal-header">
                    <div className="post-user  p-0 post-user-control d-flex align-items-center">
                      <div className="post-user-logo message-common-user-header-profile-name-log">
                        {/* <img
                          src={
                            currentUser?.profilePicture
                              ? s3bucketurl + "/" + currentUser?.profilePicture
                              : Img2
                          }
                          className="img-fluid"
                          alt="user"
                        /> */}
                        <Avatar
                          className="avtar"
                          round={true}
                          src={s3bucketurl + '/' + currentUser?.profilePicture}
                          alt="user"
                          size="38"
                          name={
                            currentUser?.firstName + ' ' + currentUser?.lastName
                          }
                          maxInitials={2}
                        />
                      </div>
                      <div
                        className="col"
                        onClick={() => redirectToUserProfile(currentUser)}
                      >
                        <h5 className="post-user-name">
                          {currentUser.firstName + ' ' + currentUser.lastName}
                        </h5>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="dropdown">
                        <button
                          className="btn btn-more"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img
                            src={Img3}
                            className="img-fluid"
                            width="27px"
                            alt=""
                          />
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a className="dropdown-item" href="#">
                            Action
                          </a>
                          <a className="dropdown-item" href="#">
                            Another action
                          </a>
                          <a className="dropdown-item" href="#">
                            Something else here
                          </a>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="btn-close chat-close-control"
                      ></button>
                    </div>
                  </div>
                  <div className="modal-body">
                    {spinner ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      ''
                    )}
                    {chatHistory &&
                      chatHistory.map((data: any) => {
                        const sender = data?.author?._id;
                        let fileType = '';
                        let type = '';
                        if (data?.pictures?.length > 0) {
                          type = 'files';
                        } else if (data.messageType === 'paidPackage') {
                          type = 'paidPackage';
                        } else if (data.messageType === 'PAID_MEDIA') {
                          type = 'paidMedia';
                        } else if (data.messageType === 'COIN_TRANSFER') {
                          type = 'coinTransfer';
                        } else {
                          type = 'text';
                        }
                        if (data.messageType === 'PAID_MEDIA') {
                          type = 'paidMedia';
                        }
                        if (data.messageType === 'CHAT_VIDEO_FILE') {
                          fileType = 'video';
                        }
                        return (
                          <>
                            {sender !== chatUserId ? (
                              <div
                                className={`message-box ${data?.type === 'system'
                                  ? 'd-flex justify-content-center'
                                  : ''
                                  }`}
                              >
                                {type === 'paidPackage' && data.isLock ? (
                                  <div
                                    className="unlock-msg"
                                    onClick={() =>
                                      handleUnlockPopup(sender, data)
                                    }
                                  >
                                    <div className="view-text">
                                      <p>{data?.packageTitle}</p>
                                    </div>
                                    <h6>{t('message.unlock package')}</h6>
                                  </div>
                                ) : type === 'paidPackage' && !data.isLock ? (
                                  <div
                                    className="unlock-msg"
                                    onClick={() =>
                                      handleUnlockPopupDetails(
                                        data?.packageId,
                                        'receiver'
                                      )
                                    }
                                  >
                                    <div className="view-text">
                                      <p>{data?.packageTitle}</p>
                                    </div>
                                    <h6>{t('message.open')}</h6>
                                  </div>
                                ) : type === 'paidMedia' ? (
                                  data.isLock ? (
                                    <div className="unlock-msg-view unlock-msg-view-open">
                                      <div className="ul-img">
                                        <img src={bg} alt="bg" />
                                      </div>
                                      {data?.content}
                                      <div className="unlock-btn">
                                        <i className="las la-unlock-alt"></i>
                                        {/* <Button  onClick={() => setUnLockContent(!unlockcontent)}>Unlock</Button> */}
                                        <Button
                                          onClick={() =>
                                            handleUnlockContent(sender, data)
                                          }
                                        >
                                          {t('home.unlock')}
                                        </Button>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        padding: '15px',
                                        backgroundColor: '#efefef',
                                        height: '215px',
                                        width: '289px',
                                        display: 'flex',
                                        borderRadius: '13px 13px 13px 0px',
                                      }}
                                    >
                                      <div
                                        style={{
                                          backgroundImage: `url(${data?.media?.media_url})`,
                                          backgroundRepeat: 'no-repeat',
                                          backgroundSize: 'cover',
                                          height: '100%',
                                          width: '100%',
                                          cursor: "pointer"
                                        }}
                                        onClick={() => imagePopUp(data?.media?.media_url)}
                                      >
                                      </div>
                                    </div>
                                    // <img
                                    //   src={data?.media?.media_url}
                                    //   alt="Test"
                                    //   style={{
                                    //     marginBottom: -6,
                                    //     width: '50%',
                                    //     minWidth: '200',
                                    //     height: 200,
                                    //     cursor: 'pointer',
                                    //     objectFit: 'cover',
                                    //   }}
                                    // />
                                  )
                                ) : type === 'coinTransfer' ? (
                                  <div className="receive-transfer-coin">
                                    <div className="d-flex align-items-center">
                                      <Coin height={'26px'} className="mr-1" />
                                      <span className="amount">
                                        {data.coins}
                                      </span>
                                      <span className="receive-transfer-coin-footer">
                                        <CheckIcon className="check-icon" />
                                        <span>Received Successfully</span>
                                      </span>
                                    </div>
                                  </div>
                                ) : null}
                                {(type === 'files' || type === 'text') && (
                                  <div
                                    className={`content ${data?.type === 'system'
                                      ? 'new-user-created'
                                      : ''
                                      } `}
                                  >
                                    {type === 'files' ? (
                                      data?.messageType !==
                                        'CHAT_VIDEO_FILE' ? (
                                        <img
                                          src={
                                            data.pictures[0].file
                                              ? data.pictures[0].file
                                                .lightboxImageUrl
                                              : null
                                          }
                                          alt="Test"
                                          style={{
                                            marginBottom: -6,
                                            width: '100%',
                                            minWidth: '200',
                                            height: 200,
                                            cursor: 'pointer',
                                            objectFit: 'cover',
                                          }}
                                          onClick={() => imagePopUp(data?.pictures[0])}
                                        />
                                      ) : (
                                        <video controls width="100%">
                                          <source
                                            src={
                                              data?.pictures[0].file
                                                ? data?.pictures[0]?.file
                                                  ?.littleThumbnailUrl
                                                : null
                                            }
                                            type="video/mp4"
                                          />
                                          Sorry, your browser doesn't support
                                          embedded videos.
                                        </video>
                                      )
                                    ) : (
                                      type === 'text' && data?.content
                                    )}
                                  </div>
                                )}
                                {data?.type !== 'system' && (
                                  <p className="time">
                                    {moment(data?.date).format('h:mm A')}
                                  </p>
                                )}
                              </div>
                            ) : (
                              <div className="message-box message-box-sent">
                                {type === 'paidPackage' ? (
                                  <div
                                    className="unlock-msg"
                                    onClick={() =>
                                      handleUnlockPopupDetails(
                                        data?.packageId,
                                        'sender'
                                      )
                                    }
                                  >
                                    <div className="view-text">
                                      <p>{data?.packageTitle}</p>
                                    </div>
                                    <h6>View Package</h6>
                                  </div>
                                ) : (
                                  <div
                                    className={`content ${data?.type === 'system'
                                      ? 'new-user-created'
                                      : ''
                                      } `}
                                  >
                                    {type === 'files' ? (
                                      data?.messageType ===
                                        'CHAT_VIDEO_FILE' ? (
                                        <video controls width="100%">
                                          <source
                                            src={
                                              data?.pictures[0].file
                                                ? data?.pictures[0]?.file
                                                  ?.littleThumbnailUrl
                                                : null
                                            }
                                            type="video/mp4"
                                          />
                                          Sorry, your browser doesn't support
                                          embedded videos.
                                        </video>
                                      ) : (
                                        <div onClick={() => imagePopUp(data?.pictures[0])}>
                                          <img
                                            src={
                                              data?.pictures[0]?.file
                                                ?.littleThumbnailUrl
                                            }
                                            alt="Test"
                                            style={{
                                              marginBottom: -6,
                                              width: '100%',
                                              minWidth: '200',
                                              height: 200,
                                              cursor: 'pointer',
                                              objectFit: 'cover',
                                            }}
                                          />
                                        </div>
                                      )
                                    ) : type === 'text' ? (
                                      data?.content
                                    ) : type === 'paidMedia' ? (
                                      <img
                                        src={data?.media?.media_url}
                                        alt="Media"
                                        style={{
                                          marginBottom: -6,
                                          width: '100%',
                                          minWidth: '200',
                                          height: 200,
                                          cursor: 'pointer',
                                          objectFit: 'cover',
                                        }}
                                        onClick={() => imagePopUp(data?.media?.media_url)}
                                      />
                                    ) : type === 'coinTransfer' ? (
                                      <div className="send-transfer-coin">
                                        <div className="d-flex align-items-center">
                                          <Coin
                                            height={'26px'}
                                            className="mr-1"
                                          />
                                          <span className="amount">
                                            {data.coins}
                                          </span>
                                          <span className="send-transfer-coin-footer">
                                            <CheckIcon className="check-icon" />
                                            <span>Sent Successfully</span>
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                )}
                                {data?.type !== 'system' && (
                                  <p className="time">
                                    {moment(data?.date).format('h:mm A')}
                                  </p>
                                )}
                              </div>
                            )}
                          </>
                        );
                      })}
                    {/* <span ref={scrollRef}></span> */}

                    {/* <div className="msg-date">
                      <span>Today</span>
                    </div> */}
                    <div ref={scrollRef} />
                    {loading && <Loader />}
                  </div>

                  <div className="modal-footer flex-nowrap">
                    <button type="button" className="btn file-toggle">
                      <img src={plus} onClick={handleFile} alt="" id="closable" />
                    </button>
                    <div className="input-group message-control m-0">
                      <input
                        type="text"
                        className="form-control border-0"
                        value={message}
                        onChange={(e) => {
                          setMessage(e.target.value);
                          setMessageType('text');
                        }}
                        placeholder="Just"
                        onKeyDown={handlePressEnterValue}
                      />
                      <span
                        className="input-group-text border-0 dollor-sign"
                        id="psw"
                      >
                        <img
                          src={dollor}
                          onClick={handleCoinPopup}
                          className="img-fluid"
                          alt="Coin"
                        />
                      </span>
                    </div>
                    <button
                      type="button"
                      className="btn m-0"
                      onClick={submitMessage}
                    >
                      <img src={send} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="select-user">
                <h5>{t('message.no user selected')}</h5>
                <p>{t('message.select a user to start chat')}</p>
              </div>
            )}
            <div
              className={`post-modal ${paidPopup ? 'show-post-modal-paidMedia' : ''
                }`}
              ref={paidpopupRef}
            >
              <div className="">
                <div className="">
                  <div className="">
                    <h5 className="modal-title text-center my-2 font-weight-bold">
                      {t('message.paid post')}
                    </h5>
                    <hr className="m-0 mb-3" />
                  </div>
                  <div className="pb-2 mx-4">
                    <input type="file" hidden id="uploadDoc" />
                    <label
                      className={`uploadDoc ${paidMediaPreview ? 'uploadDoc-paid-preview' : ''
                        }`}
                    >
                      {paidMediaPreview ? (
                        <img src={paidMediaPreview} alt="" />
                      ) : (
                        <>
                          <img
                            src={Img4}
                            alt=""
                            style={{
                              height: '50px',
                            }}
                          />
                          <input
                            type="file"
                            ref={fileInput}
                            accept="image/*"
                            onChange={(e: any) =>
                              handlePaidPreview(e.target.files[0])
                            }
                          />
                          <span>{t('message.upload media')}</span>
                        </>
                      )}
                    </label>
                    {/* <p className="amount-label">Amount</p> */}
                    <div className="d-flex justify-content-center">
                      <input
                        className="paid-media-input mt-1"
                        type="number"
                        onChange={onChangleHandler}
                        placeholder="Price cannot be empty"
                        value={packageAmount}
                        onKeyDown={(e) =>
                          ['e', 'E', '+', '-', '.'].includes(e.key) &&
                          e.preventDefault()
                        }
                        name="paidPackage"
                      />
                    </div>
                  </div>
                  <div className="modal-footer justify-content-center border-0 pt-0">
                    <button
                      type="button"
                      className="btn btn-login px-4"
                      // data-bs-dismiss="modal"
                      onClick={submitPackage}
                      disabled={
                        !packageAmount.length || !paidMediaPreview.length
                      }
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <ul
              className={`file-upload ${fileUpload ? 'file-upload-visible' : ''
                }`}
              ref={imgRef}
            >
              {preview ? (
                <div className="preview-popup">
                  <img src={preview} alt="Preview" />
                  <div className="preview-btn">
                    <Button onClick={handleSaveFile}>
                      {t('message.upload')}
                    </Button>
                  </div>
                </div>
              ) : videoPreview ? (
                <div className="preview-popup">
                  <video controls width="100%">
                    <source src={videoPreview} type="video/mp4" />
                    {t('message.upload error message')}
                  </video>
                  <div className="preview-btn">
                    <Button onClick={handleSaveFile}>
                      {t('message.upload')}
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="row row-cols-1 row-cols-sm-3 justify-content-around file-upload-section">
                  <button className="btn hide-input">
                    <input
                      type="file"
                      ref={fileInput}
                      accept="image/*"
                      onChange={(e: any) => handlePreview(e.target.files[0])}
                      id="uploadimg"
                    />
                    <label htmlFor="uploadimg">
                      <img src={Img6} alt="" />
                    </label>
                  </button>
                  {/* <span onClick={handleSaveFile}>Upload Media</span> */}
                  <button className="btn hide-input">
                    <input
                      type="file"
                      ref={fileInput}
                      accept="video/mp4,video/x-m4v,video/*"
                      onChange={(e: any) =>
                        handleVideoPreview(e.target.files[0])
                      }
                      id="uploadvideo"
                    />
                    <label htmlFor="uploadvideo">
                      {' '}
                      <img src={Img7} alt="" />
                    </label>
                  </button>
                  {/* <span onClick={handleSaveFile}>Upload Media</span> */}
                  <button className="btn paidPostmodal-control">
                    <img src={Img5} alt="" onClick={handlePaidPopup} />
                  </button>
                  <button className="btn paidPostmodal-control">
                    <i className="las la-list" onClick={handleMedia}></i>
                  </button>
                </div>
              )}
            </ul>
            <div
              className={`post-modal ${!!coinPopup ? 'coin-post-modal' : ''}`}
              ref={coinPopup ? coinRef : null}
            >
              <div className="message-coin-container">
                <div className="d-flex justify-content-center py-2">
                  <h5
                    className="font-weight-bold mb-0"
                    style={{
                      color: 'black',
                    }}
                  >
                    {t('message.send coin')}
                  </h5>
                  <button
                    type="button"
                    className="btn-close position-absolute"
                    style={{
                      right: '12px',
                      top: '7px',
                    }}
                    onClick={() => {
                      setCoinPopup(false);
                      setCoin({
                        ...coin,
                        value: '100',
                      });
                    }}
                  ></button>
                </div>
                <hr
                  className="m-0 p-0 weigth-bold"
                  style={{
                    color: 'black',
                  }}
                />
                <div className="px-4 py-2 d-flex justify-content-center flex-column">
                  <Avatar
                    className="m-auto"
                    round={true}
                    src={''}
                    alt="profile"
                    size="45"
                    name={currentUser.firstName + ' ' + currentUser.lastName}
                    maxInitials={2}
                  />
                  <h5 className="sendCoin-profile-title weigth-bold">
                    {`${currentUser.firstName} ${currentUser.lastName}`}
                    {currentUser.user_verified && (
                      <VerifyIcon className="mx-1" height={'18'} width={'18'} />
                    )}
                  </h5>
                  {/* <h5 className="tip-profile-userd-name">
                    <span>@{currentUser.username}</span>
                  </h5> */}
                  <div className="d-flex justify-content-end flex-wrap mt-2 m-auto">
                    <div className="sendCoin-display">
                      <Coin className="mr-1" width={'26px'} height={'26px'} />
                      {coin.value === '' ? 0 : coin.value}
                    </div>
                  </div>
                  <div className="sendCoin-container mt-3">
                    {['100', '200', '500', '1000'].map((tip: string) => {
                      return (
                        <div
                          className={`${tip === coin.value
                            ? 'sendCoin-box-active'
                            : 'sendCoin-box'
                            }`}
                          onClick={() =>
                            setCoin({
                              ...coin,
                              value: tip,
                              error: false,
                            })
                          }
                        >
                          {tip}
                        </div>
                      );
                    })}
                  </div>
                  <div
                    className="mt-2 d-flex justify-content-center flex-column m-auto"
                    onChange={onChangleHandler}
                  >
                    <input
                      type="number"
                      value={coin.value === '' ? '' : coin.value}
                      className="sendCoin-custom-input"
                      placeholder="Custom amount"
                      onKeyDown={(e) =>
                        ['e', 'E', '+', '-', '.'].includes(e.key) &&
                        e.preventDefault()
                      }
                      name="sendCoin"
                    />
                    {coin.error && (
                      <div className="tip-error">
                        {t('message.please enter valid tip')}
                      </div>
                    )}
                    {/* {balanceError.length && (
                      <div className="tip-error">{balanceError}</div>
                    )} */}
                  </div>
                  <div className="m-auto mt-2">
                    <button
                      onClick={SubmitCoin}
                      className="custom-sendCoin-btn btn"
                      disabled={coin.loading}
                    >
                      {t('message.transfer')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`post-modal paid-media-popup ${paidMedia && !myLoadingVariable ? 'show-post-modal' : ''
                }`}
              ref={paidRef}
            >
              <h5 className="modal-title py-2">{t('message.paid media packege')}</h5>
              <div className="paid-box">
                {!!paidPackageList.length && paidPackageList ?
                  paidPackageList.map((data: any) => (
                    <div className="paid-list mt-3">
                      <p>{data?.media_package_name}</p>
                      <Button
                        onClick={() =>
                          handleSendPackage(data.id, data.media_package_name)
                        }
                      >
                        {t('message.send')}
                      </Button>
                    </div>
                  )) : <div className="paid-list my-3 justify-content-center"><p>{t('message.no package found')}</p></div>}
              </div>
            </div>
            <UnlockPopup
              unlockPopup={unlockPopup}
              unlockPackage={unlockPackage}
              unlockRef={unlockRef}
              packageData={packageData}
            />
            <UnlockContent
              unlockcontent={unlockcontent}
              unlockRef={unlockRef}
              unlockPackage={unlockContent}
              packageData={packageData}
              amount={amount}
              currentPaidMedaiOrPackagedata={currentPaidContent}
            />
            <div
              className={`file-upload-backdrop ${fileUpload ||
                paidPopup ||
                unlockPopup ||
                coinPopup ||
                unlockcontent
                ? 'd-block'
                : ''
                }`}
            ></div>
          </div>
        </div>
      </div>
      {unlockPopupDetails && <UnlockMessageDetails show={unlockPopupDetails} s3bucketurl={s3bucketurl} handleClose={handleUnlockPopupDetails} paidPackageDetail={paidPackageDetail} packageUser={packageUser} setUnlockPopupDetails={setUnlockPopupDetails} />}
      {myLoadingVariable && <Loader />}
      {messImgPopUp.isModal &&
        <Modal
          show={messImgPopUp.isModal}
          onHide={() => setMessImgPopUp({ ...messImgPopUp, isModal: false })}
          centered
          size='lg'
          className='mess-img-popUp'
        >
          <button type="button" className="btn-close" aria-label="Close" onClick={() => setMessImgPopUp({ ...messImgPopUp, isModal: false })} style={{ position: 'absolute', zIndex: '10', right: '22px', top: '22px' }} />
          <Modal.Body>
            <img src={messImgPopUp.data?.file?.littleThumbnailUrl ?? messImgPopUp.data} alt='Message Image' style={{ height: '80vh' }} />
          </Modal.Body>
        </Modal>}
    </div >
  );
}
