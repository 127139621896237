import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../redux";
import userimg from "../assets/images/imgs/user2.svg";
import {
  blockUser,
  unblockUser,
  getBlockList,
  userDashboardFeed,
} from "../redux/action";
import { Store } from "../redux/Actions";
import { getToken } from "../utils/utility";
import SearchBlacklistUser from "./SearchBlacklistUser";
import { S3_BASE_URL } from "../service/aws/config";
import { useTranslation } from "react-i18next";
import Avatar from "react-avatar";
import Loader from "./Loader";
import { ReactComponent as VerifyIcon } from "../assets/images/icons/verifyIcon.svg";

const BlackList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchValue, setSearchValue] = useState("");
  const { blockList, loading }: any = useSelector(
    (state: Store) => state.userDataReducer
  );
  const GetBlockList = () => {
    const userId = getToken("userId");
    const data: any = { userId, page: 1 };
    dispatch(getBlockList(data));
  };
  useEffect(() => {
    GetBlockList();
  }, []);
  const handleBlockUnblock = async (type: any, userId: any) => {
    if (type === "block") {
      await dispatch(blockUser(userId));
      await dispatch(userDashboardFeed(1));
      GetBlockList();
    } else {
      await dispatch(unblockUser(userId));
      await dispatch(userDashboardFeed(1));
      GetBlockList();
    }
    setSearchValue("");
  };
  const { t } = useTranslation();
  return (
    <>
      {loading && <Loader />}
      <h2 className="accordion-header d-lg-none" id="headingTwo">
        <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#blacklist"
          aria-expanded="false"
          aria-controls="blacklist"
        >
          Blacklist
        </button>
      </h2>
      <div
        id="blacklist"
        className="accordion-collapse collapse d-lg-block"
        aria-labelledby="headingTwo"
        data-bs-parent="#myTabContent"
      >
        <div className="accordion-body">
          <div className="box-container">
            <h2 className="setting-title"> {t("blacklist.blacklist")}</h2>
            <div className="blacklist-content">
              <SearchBlacklistUser
                handleBlockUnblock={handleBlockUnblock}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
              {/* <input type="text" placeholder="Add user to blacklist" /> */}
              <div className="blacklist-list">
                <ul className="ps-0">
                  {blockList &&
                    blockList.map((data: any) => {
                      return (
                        <li className="blacklist-item px-0" key={data.id}>
                          <div className="blacklist-link d-flex align-items-center justify-content-between">
                            <div className="blacklist-user d-flex align-items-center">
                              {/* <div className="user-logo"> */}
                              {/* <img
                                  src={S3_BASE_URL + data.profile_photo}
                                  alt="profile"
                                  className="img-fluid"
                                /> */}
                              <Avatar
                                className="avtar"
                                round={true}
                                src={S3_BASE_URL + data.profile_photo}
                                alt="profile"
                                size="60"
                                name={data.first_name + " " + data.last_name}
                                maxInitials={2}
                              />
                              {/* </div> */}
                              <span>
                                {data.first_name + " " + data.last_name}{data.user_verified ?<VerifyIcon className="mx-1" width="13" height="13"/>:""}
                              </span>
                            </div>
                            <div className="blacklist-button">
                              <Button
                                className="btn btn-unblock"
                                onClick={() =>
                                  handleBlockUnblock(
                                    data.is_block ? "unblock" : "block",
                                    data.id
                                  )
                                }
                              >
                                {data.is_block ? "Unblock" : "Block"}
                              </Button>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BlackList;
