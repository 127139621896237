import { useEffect, useRef, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux";
import Delete from "../../assets/images/icons/ico_delete.png";
import Upload from "../../assets/images/icons/upload.svg";
import ThumbnailIcon from "../../assets/images/imgs/audio-thumb.svg";
import { S3_BASE_URL } from "../../service/aws/config";
import BackImage from "../../assets/images/bg/bg-img2.svg";
import { Player } from "video-react";
import { useFormik } from "formik";
import ReactPlayer from "react-player";
import { Button, Modal, ModalBody, Spinner } from "react-bootstrap";
import {
  createTextPost,
  getSearchedUserList,
} from "../../redux/action/postActions";
import Select from "react-select";
import CustomOption from "./CustomeOption";
import {
  MEDIA_SIZE,
  PAYMENT_TYPE,
  POST_TYPE,
} from "../../constants/App-constant";
import { uploadFileAmazonS3 } from "../../service/index.";
import {
  createImageFromInitials,
  getRandomColor,
} from "../../utils/createImage";
import videoIcon from "../../assets/images/icons/video-play.svg";
import SearchableSelect from "../SearchableSelect";
import { apiCall, METHOD } from "../../service/baseApiCall";
import { GET_PEOPLE_LIST_URL, SEARCH_TAGS } from "../../redux/ApiEndPoints";
// import { postOptions } from "../../utils/constant";
import { textPostSchema } from "../../validationScrema/user";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/utility";

export default function VideoPost({
  showPostComment,
  setShowPostComment,
}: any) {
  const userDetail: any = useSelector(
    (state: Store) => state.userDataReducer.userDetail
  );
  const { t } = useTranslation();
  const local = localStorage.getItem("local") || "en";
  const postOptions = [
    { label: t("home.free to all"), value: 0 },
    { label: t("home.for subscribers"), value: 1 },
    { label: t("home.paid content"), value: 2 },
  ];
  const dispatch = useDispatch<AppDispatch>();
  const [selectList, setSelectList] = useState<any>([]);
  const [thumbnailName, setThumbnailName] = useState<any>([]);
  const [teaserName, setTeaserName] = useState<any>([]);
  const [tagList, setTagList] = useState<any>([]);
  const [hashtagList, setHashTagList] = useState<any>();
  const [hashtagListData, setHashTagListData] = useState<any>([]);
  const [videoPlay, setVideoPlay] = useState<any>(false);
  const [amountErr, setAmountErr] = useState(false);
  const [textlimitError, setTextlimitError] = useState<boolean>(false);
  const [thumbnailErr, setThumbnailErr] = useState(false);
  const [videoErr, setVideoErr] = useState(false);
  const [videoLoader, setVideoLoader] = useState(false);
  const [videoPostErr, setVideoPostErr] = useState(false);
  const [videoTypeErr, setVideoTypeErr] = useState(false);
  const [thumbnailTypeErr, setThumbnailTypeErr] = useState(false);
  const [teaserTypeErr, setTeaserTypeErr] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [videoSizeError, setVideoSizeError] = useState(false);
  const [videoImageSizeError, setVideoImageSizeError] = useState(false);
  const [videoTreserSizeError, setVideoTreserSizeError] = useState(false);
  const { loading, createTextPostSuccess, searchUserList }: any = useSelector(
    (state: Store) => state.postDataReducer
  );

  const formik = useFormik({
    initialValues: {
      post_type: POST_TYPE.POST_TYPE_VIDEO,
      description: "",
      hash_tags: [],
      tags: [],
      thumbnail: "",
      payment_amount: "",
      video: "",
      video_teaser: "",
      duration: "",
      payment_type: PAYMENT_TYPE.PAYMENT_TYPE_FREE,
      // acceptTerms: false,
    },
    // validationSchema: textPostSchema,
    onSubmit: async (values) => {
      let value = {
        ...values,
        hash_tags: values.hash_tags.map((i: any) => i.value).join(","),
        tags: values.tags.map((i: any) => i.value).join(","),
      };

      if (
        (values.payment_type == PAYMENT_TYPE.PAYMENT_TYPE_PAID &&
          !values.payment_amount) ||
        !values.thumbnail ||
        !values.video
      ) {
        if (
          values.payment_type == PAYMENT_TYPE.PAYMENT_TYPE_PAID &&
          !values.payment_amount
        ) {
          setAmountErr(true);
        }
        if (!values.thumbnail) {
          setThumbnailErr(true);
        }
        if (!values.video) {
          setVideoErr(true);
        }
      } else {
        if (formik.values.video_teaser) {
          await uploadFileAmazonS3(
            formik.values.video_teaser,
            "post/teasers/"
          ).then((data) => {
            value = { ...value, video_teaser: data.Key };
          });
        }
        if (formik.values.thumbnail && formik.values.video) {
          setVideoLoader(true);
          await uploadFileAmazonS3(formik.values.video, "post/videos/").then(
            (data) => {
              value = { ...value, video: data.Key };
            }
          );
          // await uploadFileAmazonS3(formik.values.video_teaser).then((data) => {
          //   value = { ...value, video_teaser: data.Key };
          // });
          await uploadFileAmazonS3(
            formik.values.thumbnail,
            "post/thumbs/"
          ).then((data) => {
            value = { ...value, thumbnail: data.Key };
          });
          dispatch(createTextPost(value));
          setVideoSizeError(false);
          setVideoImageSizeError(false);
          setVideoTreserSizeError(false);
          setShowPostComment(false);
          // formik.values.payment_amount=""
          setVideoLoader(false);
          setVideoSrc("");
          setThumbnailName("");
          setTeaserName("");
          setVideoTypeErr(false);
          //setThumbnailTypeErr(false);
          setTeaserTypeErr(false);
          setThumbnailErr(false);
          setVideoErr(false);
          const userId = getToken("userId");
          dispatch(userDetail(Number(userId)));
          formik.values.description = "";
          formik.resetForm();
        }
      }
    },
  });
  const { errors, touched } = formik;
  useEffect(() => {
    dispatch(getSearchedUserList(formik.values.tags));
  }, [formik.values.tags]);
  useEffect(() => {
    let data = [];
    data =
      searchUserList &&
      searchUserList.length > 0 &&
      searchUserList.map((data: any) => {
        return {
          value: data.id,
          label: data.created_by,
          username: data.username,
          profile_photo: data.profile_photo,
        };
      });
    if (data && data.length > 0) {
      setSelectList(data);
    }
  }, [searchUserList]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // setShowPostComment(false)
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [setShowPostComment]);
  const [videoSrc, setVideoSrc] = useState("");

  const onVideoChange = (event: any) => {
    setVideoSizeError(false);
    const file = event.target.files?.[0];
    if (file?.size / 1024 < MEDIA_SIZE.VIDEO_SIZE) {
      if (!file?.name.match(/\.(mp4)$/)) {
        setVideoTypeErr(true);
        setVideoErr(false);
        return false;
      } else {
        formik.setFieldValue("video", event.target.files[0]);
        var url = URL.createObjectURL(new Blob([file]));
        setVideoSrc(url);
        setVideoTypeErr(false);
      }
    } else {
      setVideoSizeError(true);
    }
  };
  const onThumbnailChange = (event: any) => {
    setVideoImageSizeError(false);
    const file = event.target.files?.[0];
    if (file?.size / 1024 < MEDIA_SIZE.IMAGE_SIZE) {
      if (!file?.name.match(/\.(jpg|jpeg|png)$/)) {
        setThumbnailTypeErr(true);
        setThumbnailErr(false);
        return false;
      } else {
        formik.setFieldValue("thumbnail", event.target.files[0]);
        setThumbnailName(event.target.files[0].name);
        setThumbnailTypeErr(false);
      }
    } else {
      setVideoImageSizeError(true);
    }
  };

  const handleChange = (event: any) => {
    //const allowedTypes = ["application/mp4"];
    const file = event.target.files[0];
    const fileType = file.type.split("/")[0];
    setVideoTreserSizeError(false);
    setVideoImageSizeError(false);

    if (fileType === "audio") {
      setThumbnailTypeErr(true);
      // Clear the input value
      event.target.value = null;
    } else {
      setThumbnailTypeErr(false);

      if (event.target.files && event.target.files[0]) {
        if (event.target.name === "thumbnail") {
          if (file?.size / 1024 < MEDIA_SIZE.IMAGE_SIZE) {
            formik.setFieldValue("thumbnail", event.target.files[0]);
            setThumbnailName(event.target.files[0].name);
          } else {
            setVideoImageSizeError(true);
          }
        } else if (event.target.name === "video_teaser") {
          if (file?.size / 1024 < MEDIA_SIZE.VIDEO_SIZE) {
            formik.setFieldValue("video_teaser", event.target.files[0]);
            setTeaserName(event.target.files[0].name);
          } else {
            setVideoTreserSizeError(true);
          }
        }
        // else if (file.size > mb){
        //   "limit error"
        // }
      }
    }

    //     if (!allowedTypes?.includes(file?.type)){
    // setVideoPostErr(true)
    // return;
    //     }
  };
  const ontagChange = (option: any) => {
    setTagList(option);
    const ids = option.map((i: any) => i.value);
    formik.setFieldValue("tags", ids);
  };
  const handleKeyDown = (evt: any) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      var value = hashtagList.trim();
      if (value) {
        setHashTagListData([...hashtagListData, value]);
      }
      setHashTagList("");
    }
  };

  const handleDelete = (item: any) => {
    const data = hashtagListData.filter((i: any) => i !== item);
    setHashTagListData(data);
    setHashTagList("");
  };
  useEffect(() => {
    formik.setFieldValue("hash_tags", hashtagListData);
  }, [hashtagListData]);

  useEffect(() => {
    setShowPostComment(false);
  }, [createTextPostSuccess]);

  useEffect(() => {
    let vid = document.getElementById("howitworks");
    if (vid) {
      vid.addEventListener("pause", () => {
        setVideoPlay(false);
      });
      vid.addEventListener("play", () => {
        setVideoPlay(true);
      });
    }
  }, []);
  const handleVideo = () => {
    // const video = (document.getElementById("howitworks") as HTMLInputElement | null);
    const video = document.getElementById("howitworks") as any | null;

    if (video != null && !videoPlay) {
      setVideoPlay(true);
      video.play();
    } else {
      setVideoPlay(false);
      video.pause();
    }
  };
  const handlePeopleSearch = async (keyword: any, onSuccess: any) => {
    await apiCall(
      GET_PEOPLE_LIST_URL,
      { search: keyword },
      (res: any) => {
        onSuccess(
          res.data.map((i: any) => ({
            label: `${i?.first_name} ${i?.last_name}`,
            value: i?.id,
          }))
        );
      },
      () => {
        onSuccess([]);
      },
      METHOD.POST
    );
  };

  const handleTagSearch = async (keyword: any, onSuccess: any) => {
    await apiCall(
      SEARCH_TAGS,
      { search: keyword, page: 1 },
      (res: any) => {
        onSuccess(
          res.data.map((i: any) => ({
            label: i?.hash_tag,
            value: i?.hash_tag,
          }))
        );
      },
      () => {
        onSuccess([]);
      },
      METHOD.POST
    );
  };
  const handleVideoDescriptiion = (e: any) => {
    if (formik.values.description.length > 240) {
      setTextlimitError(true);
    } else {
      setTextlimitError(false);
    }
    formik.setFieldValue("description", e.target.value);
  };

  const handleChangePaidContant = (e: any) => {
    if (userDetail?.user_verified) {
      formik.setFieldValue("payment_type", e.target.value);
    } else {
      setErrorModal(true);
    }
  };
  return (
    <Modal
      show={showPostComment}
      onHide={() => {
        setShowPostComment(false);
        setVideoSrc("");
        setVideoErr(false);
        setTextlimitError(false);
        setVideoImageSizeError(false);
        setVideoTreserSizeError(false);
        setVideoTypeErr(false);
        setThumbnailErr(false);
        setVideoSizeError(false);
        formik.resetForm();
      }}
      className="upload-image-popup video-popup"
      centered
    >
      <Modal.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                // onClick={()=>formik.resetForm()}
              ></button> */}

          <div className="main-section m-0">
            <div className="left-section">
              <div className="file-label">
                <div className="file-box">
                  {videoSrc ? (
                    <>
                      <video
                        className="videoInsert"
                        style={{
                          width: "inherit",
                          height: "inherit",
                          objectFit: "cover",
                          borderRadius: "33px",
                        }}
                        src={videoSrc}
                        autoPlay={false}
                        controls={true}
                        muted={!videoPlay}
                        id="howitworks"
                        onClick={handleVideo}
                      />
                      {!videoPlay && (
                        <div className="play-icon" onClick={handleVideo}>
                          <img src={videoIcon} alt="play" />
                        </div>
                      )}
                    </>
                  ) : (
                    <div>
                      <input
                        // required
                        type="file"
                        id="upload"
                        onChange={onVideoChange}
                        name="video"
                        accept=",video/*"
                        hidden
                      />
                      {videoSizeError && (
                        <div className="text-danger text-center">
                          Please select less them {MEDIA_SIZE.VIDEO_SIZE / 1024}
                          MB Video file
                        </div>
                      )}
                      {videoTypeErr && videoErr ? (
                        <div className="text-danger text-center">
                          {t("home.video error")}
                        </div>
                      ) : (
                        <>
                          {videoTypeErr ? (
                            <div
                              style={{ marginTop: "250px" }}
                              className="text-danger text-center mt-5"
                            >
                              {t("home.video type error")}
                            </div>
                          ) : (
                            <>
                              {videoErr && (
                                <div className="text-danger text-center mt-5">
                                  {t("home.video error")}
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                      <div className="btn-group">
                        <label htmlFor="upload" className="btn">
                          <img src={Upload} alt="" />
                        </label>
                      </div>
                    </div>
                  )}
                </div>
                {/* <!-- <label htmlFor="picupload">
                <span>Upload Image from your computer</span>
              </label> --> */}
                {/* <div className="file-box">
                  {videoSrc && (
                    <div className="btn-group-delete">
                      <label htmlFor="upload" className="btn">
                        <img
                          src={Delete}
                          alt=""
                          onClick={() => {
                            setVideoSrc("");
                          }}
                        />
                     
                      </label>
                    </div>
                  )}

                  <button>delete</button>
                   {videoSrc ? ( 
                    <>
                      <video
                        className="videoInsert"
                        style={{width:"inherit",height:"inherit",objectFit: "cover",
                        borderRadius: "33px"}}
                        src={videoSrc}
                        autoPlay={false}
                        // controls={true}
                        muted={!videoPlay}
                        id="howitworks"
                        onClick={handleVideo}
                      />
                      {!videoPlay && (
                        <div className="play-icon" onClick={handleVideo}>
                          <img src={videoIcon} alt="play" />
                        </div>
                      )}
                    </>
                  )} 
     
                   <div className="btn-group">
                    <label htmlFor="upload" className="btn">
                      <img src={Upload} alt="" />
                    </label>
                  </div> 
                   {videoPostErr && (
                    <div className="pt-2 text-danger pl-2">
                     Upload only .mp4 files
                  </div>
                  )}

                  {videoErr && (
                    <div className="pt-2 text-danger pl-2">
                      Please upload video
                    </div>
                  )} 
                </div> */}
                {/* {errors.video && touched.video && (
                  <div className="text-danger mt-2">{errors.video}</div>
                )} */}
              </div>
            </div>
            <div className="right-section">
              <div className="caption-control">
                <div className="post-user post-user-control d-flex align-items-start p-0">
                  <div className="post-user-logo">
                    <img
                      src={
                        userDetail?.profile_photo
                          ? S3_BASE_URL + userDetail?.profile_photo
                          : createImageFromInitials(
                              500,
                              userDetail?.first_name,
                              getRandomColor()
                            )
                      }
                      className="img-fluid"
                      style={{ height: "45px", width: "45px" }}
                      alt="test"
                    />
                  </div>
                  <textarea
                    placeholder={`${t("home.caption")}`}
                    className="form-control p-0 pt-3 border-0"
                    rows={4}
                    name="description"
                    value={formik.values.description}
                    onChange={handleVideoDescriptiion}
                  ></textarea>
                </div>
              </div>
              {textlimitError && (
                <span
                  className="text-danger ml-2"
                  style={{ fontSize: "smaller" }}
                >
                  {t("home.please enter less then 240 word")}
                </span>
              )}
              {/* <div className="radio-price-group radio-price-group-circle">
                <input
                  type="radio"
                  className="btn-check"
                  name="payment_type"
                  id="all"
                  value={PAYMENT_TYPE.PAYMENT_TYPE_FREE}
                  autoComplete="off"
                  // checked
                  onChange={() =>
                    formik.setFieldValue(
                      "payment_type",
                      PAYMENT_TYPE.PAYMENT_TYPE_FREE
                    )
                  }
                />
                <label className="btn btn-secondary" htmlFor="all">
                  Free to all
                </label>

                <input
                  type="radio"
                  className="btn-check"
                  name="payment_type"
                  id="Subscribers"
                  autoComplete="off"
                  onChange={() => {
                    formik.setFieldValue(
                      "payment_type",
                      PAYMENT_TYPE.PAYMENT_TYPE_SUBSCRIBER_ONLY
                    );
                  }}
                />
                <label className="btn btn-secondary" htmlFor="Subscribers">
                  For Subscribers
                </label>

                <input
                  type="radio"
                  className="btn-check"
                  name="payment_type"
                  id="Content"
                  autoComplete="off"
                  onChange={() =>
                    formik.setFieldValue(
                      "payment_type",
                      PAYMENT_TYPE.PAYMENT_TYPE_PAID
                    )
                  }
                />
                <label className="btn btn-secondary" htmlFor="Content">
                  Paid Content
                </label>
                {errors.description && touched.description && (
                  <div className="text-danger mt-2">{errors.description}</div>
                )}
              </div> */}
              <div
                className={`radio-price-group radio-price-group-circle ${
                  local == "es" ? "langauge-validation" : ""
                }`}
              >
                {postOptions.map((item: any) => (
                  <Fragment key={`Item${item.value}`}>
                    <input
                      type="radio"
                      className="btn-check"
                      id={`Content-Item${item.value}`}
                      checked={formik.values.payment_type == String(item.value)}
                      value={item.value}
                      onChange={(e) => handleChangePaidContant(e)}
                    />
                    <label
                      className="btn btn-secondary"
                      htmlFor={`Content-Item${item.value}`}
                    >
                      {item.label}
                    </label>
                  </Fragment>
                ))}
              </div>

              <label className="caption-label">
                <b>{t("home.tag people")}</b> <i>@</i>{" "}
                {/* <input
                    type="text"
                    placeholder="username"
                    className="form-control"
                  /> */}
                {/* <Select
                  className="form-control"
                  onInputChange={(e) => {
                    dispatch(getSearchedUserList(e));
                  }}
                  options={selectList}
                  // formatOptionLabel={formatOptionLabel}
                  components={{ Option: CustomOption }}
                  name="tags"
                  defaultValue={tagList}
                  onChange={ontagChange}
                  isMulti
                /> */}
                <SearchableSelect
                  isMulti
                  placeholder={`${t("home.select")}`}
                  onSearch={handlePeopleSearch}
                  onChange={(val: any) => formik.setFieldValue("tags", val)}
                  value={formik.values.tags}
                />
              </label>
              {errors.tags && touched.tags && (
                <div className="text-danger mt-2">{errors.tags}</div>
              )}
              <label className="caption-label">
                <b>Hashtag</b> <i>#</i>{" "}
                {/* {formik.values.hash_tags.map((item: any) => (
                  <div className="tag-item" key={item.value}>
                    {item.value}
                    <button
                      type="button"
                      className="button"
                      onClick={() => handleDelete(item)}
                    >
                      &times;
                    </button>
                  </div>
                ))} */}
                <SearchableSelect
                  isMulti
                  placeholder={`${t("home.select")}`}
                  onSearch={handleTagSearch}
                  onChange={(val: any) =>
                    formik.setFieldValue("hash_tags", val)
                  }
                  value={formik.values.hash_tags}
                />
                {errors.hash_tags && touched.hash_tags && (
                  <div className="text-danger mt-2">{errors.hash_tags}</div>
                )}
              </label>
              <>
                <label className="caption-label">
                  <input
                    // required
                    type="file"
                    id="thumbupload"
                    name="thumbnail"
                    onChange={onThumbnailChange}
                    onFocus={() => {
                      setThumbnailErr(false);
                    }}
                    hidden
                    onClick={() => {
                      setThumbnailErr(false);
                    }}
                  />
                  <b>{t("home.thumbnail")}</b>
                  <label
                    htmlFor="thumbupload"
                    className="btn btn-login thumb-btn btn-group"
                  >
                    {/* <i>{Upload}</i> */}
                    <img src={Upload} alt="test test" />
                  </label>
                  {thumbnailName}
                  {videoImageSizeError && (
                    <div className="text-danger text-center mb-1">
                      Please Select less then {MEDIA_SIZE.IMAGE_SIZE / 1024} MB
                      Image file
                    </div>
                  )}
                  {thumbnailTypeErr && thumbnailErr ? (
                    <div className="text-danger text-center mb-1">
                      {t("home.thumb error")}
                    </div>
                  ) : (
                    <>
                      {thumbnailTypeErr ? (
                        <div className="text-danger text-center mb-1">
                          {t("home.upload file")}
                        </div>
                      ) : (
                        <>
                          {thumbnailErr && (
                            <div className="text-danger text-center mb-1">
                              {t("home.thumb error")}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {/* <input type="text" placeholder="5" className="form-control" /> */}
                </label>
              </>
              {/* {
                errors.thumbnail && touched.thumbnail && "Thumb"
                // <div className="text-danger mt-2">{errors.thumbnail}</div>
              } */}
              {formik.values.payment_type == PAYMENT_TYPE.PAYMENT_TYPE_PAID && (
                <>
                  <label className="caption-label">
                    <b>{t("home.amount")}</b> <i>$</i>{" "}
                    <input
                      // required
                      type="text"
                      placeholder=""
                      className="form-control"
                      name="payment_amount"
                      defaultValue={formik.values.payment_amount}
                      onChange={formik.handleChange}
                      onFocus={() => setAmountErr(false)}
                    />
                  </label>
                  {amountErr && (
                    <div className="text-danger">
                      {t("home.amount required")}
                    </div>
                  )}
                </>
              )}
              {errors.payment_amount && touched.payment_amount && (
                <div className="text-danger mt-2">{errors.payment_amount}</div>
              )}
              {formik.values.payment_type == PAYMENT_TYPE.PAYMENT_TYPE_PAID && (
                <label className="caption-label border-0 mb-md-3">
                  <input
                    type="file"
                    id="video_teaser"
                    name="video_teaser"
                    onChange={handleChange}
                    hidden
                    accept="video/*"
                  />
                  <b>Teaser</b>
                  <label
                    htmlFor="video_teaser"
                    className="btn btn-login thumb-btn"
                  >
                    <img src={Upload} alt="" />
                  </label>
                  {teaserName}
                  {videoTreserSizeError && (
                    <div className="text-danger text-center">
                      Please Select less then {MEDIA_SIZE.VIDEO_SIZE / 1024} MB
                      Video file
                    </div>
                  )}
                  {teaserTypeErr && (
                    <div className="text-danger text-center">
                      {t("home.video type error")}
                    </div>
                  )}
                </label>
              )}

              {/* {
                errors.video_teaser && touched.video_teaser && "video teaser"
                // <div className="text-danger mt-2">{errors.video_teaser}</div>
              } */}
              <div className="modal-footer justify-content-center border-0 p-0 mt-auto">
                <button
                  type="submit"
                  className="btn btn-login w-100 py-4"
                  data-bs-dismiss="modal"
                  disabled={videoLoader || textlimitError}
                >
                  {videoLoader ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <>{t("home.post")}</>
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal
        show={errorModal}
        onHide={() => {
          setErrorModal(false);
        }}
      >
        <ModalBody>
          <h6> {t("home.varified profile error")}</h6>
          <div style={{ textAlign: "end", marginLeft: "17px" }}>
            <Button onClick={() => setErrorModal(false)}>
              {t("home.close")}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Modal>
  );
}
