export enum VERIFY_STATUS {
  VERIFY_STATUS_NOT_APPLIED = 4,
  VERIFY_STATUS_PENDING = 0,
  VERIFY_STATUS_SUCCESS = 1,
  VERIFY_STATUS_FAILED = 2,
}

export enum POST_TYPE {
  POST_TYPE_IMAGE = 0,
  POST_TYPE_AUDIO = 1,
  POST_TYPE_VIDEO = 2,
  POST_TYPE_TEXT = 3,
  POST_TYPE_POLL = 4,
}

export enum PAYMENT_TYPE {
  PAYMENT_TYPE_FREE = 0,
  PAYMENT_TYPE_SUBSCRIBER_ONLY = 1,
  PAYMENT_TYPE_PAID = 2,
  USER_LATEST_POST = 'USER_LATEST_POST',
}

export enum NOTIFICTION_TYPE {
  NOTIFICATION_TYPE_LIKE_POST = 'like_post',
  NOTIFICATION_TYPE_LIKE_COMMENT = 'like_comment',
  NOTIFICATION_TYPE_POST_COMMENT = 'post_comment',
  NOTIFICATION_TYPE_START_FOLLOW = 'start_follow',
  NOTIFICATION_TYPE_NEW_STORY = 'new_story',
  NOTIFICATION_TYPE_NEW_TIPS = 'new_tips',
  NOTIFICATION_TYPE_RECEIVED_COIN = 'recieved_coins',
  NOTIFICATION_TYPE_UPDATE_WALLET = 'update_wallet',
  NOTIFICATION_TYPE_WALLET_UPDATED = 'wallet_updated',
  NOTIFICATION_TYPE_TRANSFER_COIN = 'transfer_coins',
  NOTIFICATION_TYPE_CHATS = 'chat',
  NOTIFICATION_TYPE_NEW_SUBS = 'new_subscription',
  NOTIFICATION_TYPE_DOC_REJECTED = 'document_rejected',
  NOTIFICATION_TYPE_DOC_APPROVED = 'document_approved',
  NOTIFICATION_TYPE_VERIFICATION_UPDATES = 'VERIFICATION_UPDATES',
  NOTIFICATION_TYPE_NEW_SUBSCRIBER = 'NEW_SUBSCRIBER',
  NOTIFICATION_TYPE_PAYOUT_UPDATES = 'PAYOUT_UPDATES',
  NOTIFICATION_TYPE_TAG_IN_POST = 'TAG_IN_POST',
  NOTIFICATION_TYPE_LIVE_STREAM = 'live_stream',
  NOTIFICATION_TYPE_NEW_LIVESTREAM = 'new_livestream',
}

export enum MEDIA_SIZE {
  IMAGE_SIZE = 30720,
  VIDEO_SIZE = 102400,
  AUDIO_SIZE = 30720,
}
